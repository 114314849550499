import {
  Fonts,
  FooterType,
  HeaderType,
  LayoutDirection,
  LayoutType,
  MenuStyle,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from 'shared/constants/AppEnums';

export const textLight = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
};

let errorColors = ['#FFE7D3', '#FF805D', '#FF4528', '#DB271D', '#930C1A'];
let infoColors = ['#DCF0FF', '#7EB9FF', '#549BFF', '#3D78DB', '#1A3D93'];

let primaryColors = [
  '#D6E4FF',
  '#D6E4FF',
  '#ADC8FF',
  '#84A9FF',
  '#6690FF',
  '#3366FF',
  '#254EDB',
  '#1939B7',
  '#102693',
  '#102693',
];

export const textDark = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(229, 231, 235)',
  disabled: 'rgb(156, 163, 175)',
};

export const backgroundDark = {
  paper: '#2B3137',
  default: '#1F2527',
};

export const backgroundLight = {
  paper: '#FFFFFF',
  default: '#F4F7FE',
};

const cardRadius = ThemeStyleRadius.STANDARD;
export const defaultTheme = {
  theme: {
    spacing: 4,
    cardRadius: cardRadius,
    direction: LayoutDirection.LTR, // ltr, rtl
    palette: {
      mode: ThemeMode.LIGHT,
      background: {
        paper: '#FFFFFF',
        default: '#F4F7FE',
      },
      primary: {
        lighter: primaryColors[0],
        100: primaryColors[1],
        200: primaryColors[2],
        light: primaryColors[3],
        400: primaryColors[4],
        dark: primaryColors[6],
        700: primaryColors[7],
        darker: primaryColors[8],
        900: primaryColors[9],
        main: '#C7262E',
        contrastText: '#fff',
        special: '#0A8FDC',
      },
      secondary: {
        main: '#F04F47',
        lighter: '#F5F6FA',
        mauCongAn: '#C00D0D',
        yellowCongAn: '#FBFA04',
        mauExtension: '#C7262E',
      },
      success: {
        main: '#11C15B',
        light: '#D9F5E5',
      },
      warning: {
        main: '#FF5252',
        light: '#FFECDC',
      },
      error: {
        lighter: errorColors[0],
        light: errorColors[1],
        main: errorColors[2],
        dark: errorColors[3],
        darker: errorColors[4],
        contrastText: '#fff',
      },
      info: {
        lighter: infoColors[0],
        light: infoColors[1],
        main: infoColors[2],
        dark: infoColors[3],
        darker: infoColors[4],
        contrastText: '#fff',
      },
      text: textLight,
      gray: {
        0: '#FFF',
        50: '#fafafa',
        100: '#F5F6FA',
        200: '#edf2f7',
        300: '#E0E0E0',
        400: '#c5c6cb',
        500: '#A8A8A8',
        600: '#666666',
        700: '#4a5568',
        800: '#201e21',
        900: '#1a202c',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
    },
    status: {
      danger: 'orange',
    },
    divider: 'rgba(224, 224, 224, 1)',
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
      h1: {
        fontSize: 22,
        fontWeight: 600,
      },
      h2: {
        fontSize: 20,
        fontWeight: 500,
      },
      h3: {
        fontSize: 18,
        fontWeight: 500,
      },
      h4: {
        fontSize: 16,
        fontWeight: 500,
      },
      h5: {
        fontSize: 14,
        fontWeight: 500,
      },
      h6: {
        fontSize: 12,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 14,
      },
      subtitle2: {
        fontSize: 16,
      },
      body1: {
        fontSize: 14,
      },
      body2: {
        fontSize: 12,
      },
    },
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
          },
        },
      },
      MuiCardLg: {
        styleOverrides: {
          root: {
            // apply theme's border-radius instead of component's default
            borderRadius:
              cardRadius === ThemeStyleRadius.STANDARD
                ? ThemeStyleRadius.STANDARD
                : ThemeStyleRadius.MODERN + 20,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 10px 10px 4px rgba(0, 0, 0, 0.04)',
            '& .MuiCardContent-root:last-of-type': {
              paddingBottom: 16,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
            // boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
            textTransform: 'capitalize',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 9,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: Fonts.REGULAR,
          },
        },
      },
    },
  },
};
export const DarkSidebar = {
  sidebarBgColor: '#313541',
  sidebarTextColor: '#fff',
  sidebarHeaderColor: '#313541',
  sidebarMenuSelectedBgColor: '#F4F7FE',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.DARK,
};
export const LightSidebar = {
  sidebarBgColor: '#fff',
  sidebarTextColor: 'rgba(0, 0, 0, 0.90)',
  sidebarHeaderColor: '#fff',
  sidebarMenuSelectedBgColor: '#dcdfe6',
  sidebarMenuSelectedTextColor: '#C7262E',
  mode: ThemeMode.LIGHT,
};

export const LightSidebarSticky = {
  sidebarBgColor: '#C7262E',
  sidebarTextColor: '#fff',
  sidebarHeaderColor: '#fff',
  sidebarMenuSelectedBgColor: '#8a3f43',
  sidebarMenuSelectedTextColor: '#fff',
  mode: ThemeMode.LIGHT,
};

const defaultConfig = {
  sidebar: {
    borderColor: '#757575',
    menuStyle: MenuStyle.STANDARD,
    isSidebarBgImage: false,
    sidebarBgImage: 1,
    colorSet: LightSidebar,
  },
  themeStyle: ThemeStyle.STANDARD,
  themeMode: ThemeMode.LIGHT,
  navStyle: NavStyle.HEADER_USER,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  headerType: HeaderType.LIGHT,
  rtAnim: RouteTransition.SLIDE_RIGHT,
  footer: false,
  locale: {
    languageId: 'vietnam',
    locale: 'vi',
    name: 'Việt Nam',
    icon: 'vn',
  },
  rtlLocale: ['ar'],
};

export const colorStatus = {
  choTiepNhan: '#549bff',
  dangXuLy: '#ffa85d',
  daTiepNhan: '#0ccfb4',
  daTuChoi: '#ff0000',
  daXuLy: '#00f600',
};

export const stringStatus = {
  choTiepNhan: 'Chờ tiếp nhận',
  dangXuLy: 'Đang xử lý',
  daTiepNhan: 'Đã tiếp nhận',
  daTuChoi: 'Đã từ chối',
  daXuLy: 'Đã xử lý',
};

export const numberStatus = {
  choTiepNhan: 1,
  daTiepNhan: 2,
  daXuLy: 3,
  daTuChoi: 4,
};
export default defaultConfig;
