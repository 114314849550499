import CryptoJS from 'crypto-js';

let defKey = 'vfybd4lpB4MI1coiqhBNtg==';

export function encryptByAES(str, token) {
  if (!token) token = defKey;
  let key = CryptoJS.enc.Utf8.parse(token);
  let srcs = CryptoJS.enc.Utf8.parse(str);
  let encrypt = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypt.toString();
}

export function decryptByAES(str, token) {
  if (!token) token = defKey;
  let key = CryptoJS.enc.Utf8.parse(token);
  let decrypt = CryptoJS.AES.decrypt(str, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt);
}

export function encodeBase64(word) {
  let wordBytes = CryptoJS.enc.Utf8.parse(word);
  return CryptoJS.enc.Base64.stringify(wordBytes);
}

export function decodeBase64(word) {
  let keyBytes = CryptoJS.enc.Base64.parse(word);
  return CryptoJS.enc.Utf8.stringify(keyBytes);
}
