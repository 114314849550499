const {
  GET_UNITTTHC_LIST,
  DELETE_UNITTTHC,
  GET_UNITTTHC_DETAIL,
  CREATE_NEW_UNITTTHC,
  UPDATE_UNITTTHC_DETAIL,
  GET_UNITTTHC_LIST_CAP_HC
} = require('shared/constants/ActionTypes');

const initialState = {
  unitTTHCList: [],
  unitTTHCListCapHC: [],
  selectedUnitTTHC: null,
  paging: null,
  message: null,
};

const UnitTTHCReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNITTTHC_LIST:
      return {
        ...state,
        unitTTHCList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_UNITTTHC_LIST_CAP_HC:
      return {
        ...state,
        unitTTHCListCapHC: action.payload.data,
      };
    case CREATE_NEW_UNITTTHC:
      return {
        ...state,
        unitTTHCList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_UNITTTHC_DETAIL:
      return {
        ...state,
        unitTTHCList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_UNITTTHC: {
      return {
        ...state,
        unitTTHCList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_UNITTTHC_DETAIL:
      return {
        ...state,
        selectedUnitTTHC: action.payload,
      };
    default:
      return state;
  }
};

export default UnitTTHCReducer;
