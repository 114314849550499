const {
  GET_DATA_SLOGAN,
  GET_DATA_SLOGAN_CLIENT,
} = require('shared/constants/ActionTypes');

const initialState = {
  dataSlogan: {},
  dataSloganClient: {},
  message: null,
};

const sloganReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_SLOGAN:
      return {
        ...state,
        dataSlogan: action.payload.data,
      };
    case GET_DATA_SLOGAN_CLIENT:
      return {
        ...state,
        dataSloganClient: action.payload.data,
      };
    default:
      return state;
  }
};

export default sloganReducer;
