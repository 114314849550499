import {
  CREATE_NEW_PROCEDURE,
  DELETE_PROCEDURE,
  GET_PROCEDURE_DETAIL,
  GET_PROCEDURE_LIST,
  UPDATE_PROCEDURE_DETAIL,
  GET_LIST_POLICE_LEVEL,
  GET_PAPER_PROCEDURE_LIST,
  GET_PAPER_PROCEDURE_DETAIL,
} from 'shared/constants/ActionTypes';

const initialState = {
  procedureList: [],
  paperProcedureList: [],
  levelPoliceList: [],
  selectedProcedure: null,
  paging: null,
  message: null,
  paperProcedureDetail: null,
};

const ProcedureReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROCEDURE_LIST:
      return {
        ...state,
        procedureList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_PAPER_PROCEDURE_LIST:
      return {
        ...state,
        paperProcedureList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_PAPER_PROCEDURE_DETAIL:
      return {
        ...state,
        paperProcedureDetail: action.payload.data,
      };
    case CREATE_NEW_PROCEDURE:
      return {
        ...state,
        procedureList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_PROCEDURE_DETAIL:
      return {
        ...state,
        procedureList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_PROCEDURE:
      return {
        ...state,
        procedureList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_PROCEDURE_DETAIL:
      return {
        ...state,
        selectedProcedure: action.payload,
      };
    case GET_LIST_POLICE_LEVEL:
      return {
        ...state,
        levelPoliceList: action.payload.data,
      };

    default:
      return state;
  }
};

export default ProcedureReducer;
