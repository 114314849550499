const {GET_SECURITY} = require('shared/constants/ActionTypes');

const initialState = {
  securityData: null,
  message: null,
};

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECURITY:
      return {
        securityData: action.payload.data,
      };
    default:
      return state;
  }
};

export default securityReducer;
