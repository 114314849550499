import React from 'react';
import PropTypes from 'prop-types';
import AppCard from '@common/core/AppCard';
import Box from '@mui/material/Box';
import useTheme from '@mui/styles/useTheme';
import Typography from '@mui/material/Typography';
import AppLottieLaw from '@common/utility/AppLotitie/Law';
import {Fonts} from 'shared/constants/AppEnums';
import Link from 'next/link';
function CardPAKN(props) {
  const theme = useTheme();
  return (
    <AppCard
      sx={{
        mt: 0.2,
        '& .MuiCardContent-root': {
          padding: 0,
          paddingBottom: '0 !important',
        },
        pb: 0,
        cursor: 'pointer',
        borderRadius: '0 !important',
      }}
      sxStyle={{
        boxShadow: 'none',
        border: '0 none',
      }}
    >
      <Link href={'/phan-anh-kien-nghi'}>
        <Box
          sx={{
            minHeight: 83,
            background: theme.palette.secondary.mauExtension,
            position: 'relative',
            filter: blur(30),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              p: 4,
              pt: 5,
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                textAlign: 'center',
                fontWeight: Fonts.MEDIUM,
                zIndex: 1,
                color: (theme) => theme.palette.grey['50'],
              }}
            >
              PHẢN ÁNH KIẾN NGHỊ
            </Typography>
          </Box>

          <Box sx={{position: 'absolute', right: 0, bottom: 0, width: '40%'}}>
            <AppLottieLaw />
          </Box>
        </Box>
      </Link>
    </AppCard>
  );
}

CardPAKN.propTypes = {};

export default CardPAKN;
