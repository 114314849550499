// material-ui
// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableFooter(theme) {
  return {
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.gray[100],
          borderTop: `2px solid ${theme.palette.divider}`,
          borderBottom: `2px solid ${theme.palette.divider}`,
          borderLeft: `2px solid ${theme.palette.divider}`,
          borderRight: `2px solid ${theme.palette.divider}`,
        },
      },
    },
  };
}
