import React, {useState} from 'react';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import {styled} from '@mui/material/styles';
import {alpha} from '@mui/material/styles';

const SelectBox = styled(Select)(({theme}) => {
  return {
    marginLeft: 8,
    cursor: 'pointer',
    fontSize: 14,
    height: 42,
    border: '1px solid #d9d7d7',
    '& .MuiSelect-select': {
      paddingLeft: 5,
      paddingTop: 1,
      paddingBottom: 3,
      color: theme.palette.text.secondary,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
    '&.Mui-focused': {
      backgroundColor: alpha(theme.palette.common.black, 0.03),
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  };
});

const AppSelect = ({
  name,
  menus,
  setFieldValue,
  defaultValue,
  setSelectionType,
  selectionKey,
}) => {
  const handleSelectionType = (event) => {
    console.log(event.target);
    setSelectionType(event.target.value);
    setFieldValue(name, event.target.value);
  };

  return (
    <SelectBox
      // defaultValue={1}
      value={defaultValue}
      onChange={handleSelectionType}
      className='select-box'
    >
      {menus.map((menu, index) => {
        return (
          <MenuItem
            key={menu.value}
            value={menu.value}
            sx={{
              cursor: 'pointer',
              p: 2,
              fontSize: 14,
            }}
          >
            {menu.name ? menu.name : menu.display}
          </MenuItem>
        );
      })}
    </SelectBox>
  );
};

export default AppSelect;
AppSelect.propTypes = {
  name: PropTypes.any,
  menus: PropTypes.array,
  setFieldValue: PropTypes.func,
  defaultValue: PropTypes.any,
  selectionKey: PropTypes.string,
  setSelectionType: PropTypes.any,
};
AppSelect.defaultProps = {
  menus: [],
  defaultValue: '',
  selectionKey: '',
};
