import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import {Fonts} from 'shared/constants/AppEnums';
import Link from '@mui/material/Link';
import NextLink from 'next/link';
import Grid from '@mui/material/Grid';
import Iconify from '@common/core/Iconify';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Tooltip from '@mui/material/Tooltip';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {alpha} from '@mui/material/styles';

export default function MostViewQuestion() {
  const theme = useTheme();
  useEffect(() => {
    const param = window.location.pathname;
  }, [location]);
  const {questionMostView} = useSelector(({questionApp}) => questionApp);
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        width: '100%',
        borderTop: 6,
        mb: 2,
        borderColor: (theme) => theme.palette.secondary.mauExtension,
        borderRadius: 1,
        '& .MuiAccordion-root': {
          borderColor: theme.palette.divider,
          '& .MuiAccordionSummary-root': {
            bgcolor: alpha(theme.palette.primary.lighter, 0.35),
            flexDirection: 'row',
            backgroundColor: 'unset',
            minHeight: '30px',
          },
          '& .MuiAccordionDetails-root': {
            border: 'none',
          },
          '& .Mui-expanded': {
            color: theme.palette.primary.main,
          },
        },
      }}
    >
      <Accordion defaultExpanded sx={{border: 'none'}}>
        <AccordionSummary
          aria-controls='panel2d-content'
          id='panel2d-header'
          sx={{borderBottom: '1px solid #C7262E', backgroundColor: 'unset'}}
        >
          <Box
            component='span'
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 14,
              borderColor: (theme) => theme.palette.secondary.mauExtension,
            }}
          >
            <Box
              sx={{
                fontWeight: Fonts.BOLD,
                color: (theme) => theme.palette.secondary.mauExtension,
              }}
            >
              CÂU HỎI XEM NHIỀU
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {questionMostView.map((item, index) => {
            return (
              <Grid key={index}>
                <NextLink
                  href={
                    window.location.pathname.split('/').length > 2
                      ? `/hoi-dap/${item.slug}`
                      : `hoi-dap/${item.slug}`
                  }
                >
                  <Link
                    sx={{
                      all: 'unset',
                      cursor: 'pointer',
                    }}
                    href={
                      window.location.pathname.split('/').length > 2
                        ? `/hoi-dap/${item.slug}`
                        : `hoi-dap/${item.slug}`
                    }
                  >
                    <Grid container>
                      <ListItem
                        key={index}
                        className='item-hover'
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          cursor: 'pointer',
                          py: 0,
                        }}
                      >
                        <ListItemText
                          sx={{
                            flex: '1 1 0',
                            padding: 0,
                          }}
                          secondary={
                            <>
                              <Tooltip
                                title={item.tieuDe}
                                placement='top'
                                arrow
                              >
                                <Box
                                  component='span'
                                  sx={{
                                    wordWrap: 'break-word',
                                    color: (theme) =>
                                      theme.palette.text.primary.main,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    cursor: 'pointer',
                                    WebkitLineClamp: '2',
                                    '&:hover': {
                                      color: (theme) =>
                                        theme.palette.secondary.mauExtension,
                                    },
                                    fontSize: 14,
                                    padding: 0,
                                  }}
                                >
                                  <Iconify
                                    icon={'bxs:right-arrow'}
                                    sx={{
                                      height: 10,
                                      width: 10,
                                      color: (theme) =>
                                        theme.palette.secondary.mauExtension,
                                    }}
                                  />
                                  &nbsp;
                                  {item.tieuDe}&nbsp;{' '}
                                </Box>
                              </Tooltip>

                              <Box>
                                <label style={{float: 'right', fontSize: 12}}>
                                  <Iconify
                                    icon={'ic:baseline-remove-red-eye'}
                                    sx={{
                                      width: 16,
                                      height: 16,
                                      color: (theme) =>
                                        theme.palette.secondary.mauExtension,
                                      marginBottom: '-4px',
                                    }}
                                  />
                                  &nbsp;
                                  {item.luotXem}&nbsp;|&nbsp;
                                  <Iconify
                                    icon={'bx:time-five'}
                                    sx={{
                                      width: 16,
                                      height: 16,
                                      color: (theme) =>
                                        theme.palette.secondary.mauExtension,
                                      marginBottom: '-4px',
                                    }}
                                  />
                                  &nbsp;
                                  {item.ngayHoiString}
                                </label>
                              </Box>
                            </>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Link>
                </NextLink>
              </Grid>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Grid item xs={12}></Grid>
    </Box>
  );
}

MostViewQuestion.propTypes = {
  data: PropTypes.any,
};
