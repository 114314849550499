import {getExpires, getRefreshToken} from '@common/utility/AccessToken';
import axios from 'axios';
import {buildWebStorage, setupCache} from 'axios-cache-interceptor';
import router from 'next/router';
import {store} from 'redux/store';
import {
  FETCH_ERROR,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
} from 'shared/constants/ActionTypes';
import {URL_REFRESH_TOKEN} from 'shared/constants/api-url/authentication';

export const apiAxios = axios.create({
  baseURL: process.env.NEXT_AUTH_URL, // YOUR_API_URL HERE
  withCredentials: true,
});

const jwtAxios = axios.create({
  baseURL: process.env.NEXT_AUTH_URL, // YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.request.use(async (config) => {
  try {
    const exp = getExpires();
    if (exp && Date.now() >= exp) {
      const {data} = await apiAxios.post(URL_REFRESH_TOKEN, {
        ip: '',
        refreshToken: getRefreshToken(),
      });
      if (data && data.statusCode === 200) {
        store.dispatch({
          type: REFRESH_TOKEN_SUCCESS,
          payload: {exp: data.exp, token: data.data},
        });
      } else {
        // localStorage.clear();
        store.dispatch({type: LOGOUT});
      }
    }
  } catch (error) {
    // localStorage.clear();
    store.dispatch({type: LOGOUT});
  }

  return config;
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err.response &&
      err.response.data &&
      (err.response.data.statusCode === 400 ||
        err.response.data.statusCode === 500)
    ) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.statusCode === 400 &&
        err.response.data.message
      ) {
        try {
          JSON.parse(err.response.data.message);
        } catch (e) {
          store.dispatch({
            type: FETCH_ERROR,
            payload: err.response.data.message || 'Lỗi hệ thống',
          });
        }
      } else {
        store.dispatch({
          type: FETCH_ERROR,
          payload: err.response.data.message || 'Lỗi hệ thống',
        });
      }
    }
    if (err.response && err.response.status === 403) {
      if (err.config.method === 'get') {
        if (!err.config.baseURL.includes('cat/api')) {
          router.push('/admin/403');
        } else {
          router.push('/403');
        }
      } else {
        store.dispatch({
          type: FETCH_ERROR,
          payload:
            'Bạn không được quyền cho sử dụng chức năng này. Vui lòng liên hệ với quản trị viên để được hỗ trợ',
        });
      }
    }
    if (err.response && err.response.data.msg === 'Token không đúng') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token, typeToken) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = `${typeToken} ` + token;
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
  }
};

export default jwtAxios;
