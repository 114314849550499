import {combineReducers} from 'redux';
import Settings from './Setting';
import Common from './Common';
import Config from './Config';
import Category from './Category';
import Banner from './Banner';
import Slogan from './Slogan';
import Link from './Link';
import MediaLibrary from './MediaLibrary';
import DocumentType from './DocumentType';
import Document from './Document';
import LawDissemination from './LawDissemination';
import AgencyIssued from './AgencyIssued';
import Contact from './Contact';
import Article from './Article';
import SnackBar from './Snackbar';
import LeadCadres from './LeadCadres';
import ProcedureReducer from './AdministrativeProcedure';
import PoliceReducer from './Police';
import ArticleProcedureReducer from './ArticleProcedure';
import Reflect from './Reflect';
import ReflectionField from './ReflectionField';
import AuthReducer from './Authentication';
import TagReducer from './Tag';
import UserReducer from './User';
import Customer from './Customer';
import EmailServer from './EmailServer';
import RoleReducer from './Role';
import Layout from './Layout';
import Unit from './Unit';
import Question from './Question';
import configReflectReducer from './ConfigReflect';
import AnalyticsReducer from './Analytics';
import OnlineInquiry from './OnlineInquiry';
import ConfigQuestion from './ConfigQuestion';
import LeaderActivity from './LeaderActivity';
import SendEvlReducer from './SendEvl';
import EvlQRReducer from './EvlQR';
import Security from './Security';

import {persistReducer} from 'redux-persist';

import {getPersistConfig} from 'redux-deep-persist';
import {encryptTransform} from 'redux-persist-transform-encrypt';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// import storage from 'redux-persist/lib/storage';
import {print} from '@common/utility/Utils';
import layoutClientReducer from './LayoutClient';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ActivityReducer from './Activity';
import CriteriaReducer from './Criteria';
import LevelReducer from './Level';
import DeviceReducer from './Device';
import UnitTTHCReducer from './UnitTTHC';
import EvlReportReducer from './EvlReport';
import TeamHCCReducer from './TeamHCC';
import securityClientReducer from './SecurityClient';

// const createNoopStorage = () => {
//   return {
//     getItem(_key) {
//       return Promise.resolve(null);
//     },
//     setItem(_key, value) {
//       return Promise.resolve(value);
//     },
//     removeItem(_key) {
//       return Promise.resolve();
//     },
//   };
// };

// const storage =
//   typeof window !== 'undefined'
//     ? createWebStorage('local')
//     : createNoopStorage();

const persistSettingConfig = getPersistConfig({
  key: 'settings',
  storage,
  blacklist: [],
  stateReconciler: autoMergeLevel2,
  rootReducer: Settings,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt settings');
      },
    }),
  ],
});

const persistCommonConfig = getPersistConfig({
  key: 'common',
  storage,
  rootReducer: Common,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt common');
      },
    }),
  ],
  blacklist: ['error', 'loading', 'isShowSidebarRight', 'validation'],
});

const persistConfigApp = getPersistConfig({
  key: 'configApp',
  storage,
  blacklist: [],
  rootReducer: Config,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt configApp');
      },
    }),
  ],
});

const persistCategoryApp = getPersistConfig({
  key: 'categoryApp',
  storage,
  blacklist: [],
  rootReducer: Category,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt categoryApp');
      },
    }),
  ],
});

const persistConfigQuestionApp = getPersistConfig({
  key: 'configQuestionApp',
  storage,
  blacklist: [],
  rootReducer: ConfigQuestion,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt configQuestionApp');
      },
    }),
  ],
});

const persistBannerApp = getPersistConfig({
  key: 'categoryApp',
  storage,
  blacklist: [],
  rootReducer: Banner,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt categoryApp');
      },
    }),
  ],
});

const persistSloganApp = getPersistConfig({
  key: 'categoryApp',
  storage,
  blacklist: [],
  rootReducer: Slogan,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt categoryApp');
      },
    }),
  ],
});

const persistLinkApp = getPersistConfig({
  key: 'linkApp',
  storage,
  blacklist: [],
  rootReducer: Link,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt linkApp');
      },
    }),
  ],
});

const persistMediaLibraryApp = getPersistConfig({
  key: 'mediaLibraryApp',
  storage,
  blacklist: [],
  rootReducer: MediaLibrary,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt mediaLibraryApp');
      },
    }),
  ],
});

const persistDocumentTypeApp = getPersistConfig({
  key: 'documentTypeApp',
  storage,
  blacklist: [],
  rootReducer: DocumentType,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt documentTypeApp');
      },
    }),
  ],
});

const persistDocumentApp = getPersistConfig({
  key: 'documentApp',
  storage,
  blacklist: [],
  rootReducer: Document,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt documentApp');
      },
    }),
  ],
});

const persistLawDisseminationApp = getPersistConfig({
  key: 'lawDisseminationApp',
  storage,
  blacklist: [],
  rootReducer: LawDissemination,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt lawDisseminationApp');
      },
    }),
  ],
});

const persistLeadCadresApp = getPersistConfig({
  key: 'categoryApp',
  storage,
  blacklist: [],
  rootReducer: LeadCadres,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt categoryApp');
      },
    }),
  ],
});

const persistOnlineInquiryApp = getPersistConfig({
  key: 'onlineInquiryApp',
  storage,
  blacklist: [],
  rootReducer: OnlineInquiry,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt onlineInquiryApp');
      },
    }),
  ],
});

const persistAgencyIssuedApp = getPersistConfig({
  key: 'agencyIssuedApp',
  storage,
  blacklist: [],
  rootReducer: AgencyIssued,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt agencyIssuedApp');
      },
    }),
  ],
});

const persistCustomerApp = getPersistConfig({
  key: 'customerApp',
  storage,
  blacklist: [],
  rootReducer: Customer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt customerApp');
      },
    }),
  ],
});

const persistEmailServerApp = getPersistConfig({
  key: 'emailServerApp',
  storage,
  blacklist: [],
  rootReducer: EmailServer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt emailServerApp');
      },
    }),
  ],
});

const persistLeaderActivityApp = getPersistConfig({
  key: 'leaderActivityApp',
  storage,
  blacklist: [],
  rootReducer: LeaderActivity,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt leaderActivityApp');
      },
    }),
  ],
});

const persistContactApp = getPersistConfig({
  key: 'contactApp',
  storage,
  blacklist: [],
  rootReducer: Contact,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt contactApp');
      },
    }),
  ],
});

const persistArticleApp = getPersistConfig({
  key: 'articleApp',
  storage,
  blacklist: [],
  rootReducer: Article,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt articleApp');
      },
    }),
  ],
});

const persistSnackBarApp = getPersistConfig({
  key: 'snackbar',
  storage,
  blacklist: [],
  rootReducer: SnackBar,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt SnackBar');
      },
    }),
  ],
});

const persistProcedureApp = getPersistConfig({
  key: 'procedureApp',
  storage,
  blacklist: [],
  rootReducer: ProcedureReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt procedureApp');
      },
    }),
  ],
});

const persistPoliceApp = getPersistConfig({
  key: 'policeApp',
  storage,
  blacklist: [],
  rootReducer: PoliceReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt policeApp');
      },
    }),
  ],
});

const persistArticleProcedureApp = getPersistConfig({
  key: 'articleProcedureApp',
  storage,
  blacklist: [],
  rootReducer: ArticleProcedureReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt articleProcedureApp');
      },
    }),
  ],
});

const persistReflectApp = getPersistConfig({
  key: 'reflectApp',
  storage,
  blacklist: [],
  rootReducer: Reflect,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt reflectApp');
      },
    }),
  ],
});

const persistReflectionFieldApp = getPersistConfig({
  key: 'reflectionFieldApp',
  storage,
  blacklist: [],
  rootReducer: ReflectionField,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt reflectionFieldApp');
      },
    }),
  ],
});

const persistAuthReducerApp = getPersistConfig({
  key: 'authentication',
  storage,
  blacklist: [],
  rootReducer: AuthReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt authentication');
      },
    }),
  ],
});

const persistTagApp = getPersistConfig({
  key: 'tagApp',
  storage,
  blacklist: [],
  rootReducer: TagReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt tagApp');
      },
    }),
  ],
});

const persistUserApp = getPersistConfig({
  key: 'userApp',
  storage,
  blacklist: [],
  rootReducer: UserReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt userApp');
      },
    }),
  ],
});

const persistRoleApp = getPersistConfig({
  key: 'roleApp',
  storage,
  blacklist: [],
  rootReducer: RoleReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt roleApp');
      },
    }),
  ],
});

const persistLayoutApp = getPersistConfig({
  key: 'layoutApp',
  storage,
  blacklist: [],
  rootReducer: Layout,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt layoutApp');
      },
    }),
  ],
});

const persistUnitApp = getPersistConfig({
  key: 'unitApp',
  storage,
  blacklist: [],
  rootReducer: Unit,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt unitApp');
      },
    }),
  ],
});

const persistConfigReflectApp = getPersistConfig({
  key: 'configReflectApp',
  storage,
  blacklist: [],
  rootReducer: configReflectReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt configReflectApp');
      },
    }),
  ],
});

const persistAnalyticsApp = getPersistConfig({
  key: 'analyticsApp',
  storage,
  blacklist: [],
  rootReducer: AnalyticsReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt analyticsApp');
      },
    }),
  ],
});

const persistLayoutClientApp = getPersistConfig({
  key: 'layoutClientApp',
  storage,
  blacklist: [],
  rootReducer: layoutClientReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt analyticsApp');
      },
    }),
  ],
});

const persistQuestionApp = getPersistConfig({
  key: 'questionApp',
  storage,
  blacklist: [],
  rootReducer: Question,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistActivityApp = getPersistConfig({
  key: 'activity',
  storage,
  blacklist: [],
  rootReducer: ActivityReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistCriteriaApp = getPersistConfig({
  key: 'criteriaApp',
  storage,
  blacklist: [],
  rootReducer: CriteriaReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistLevelApp = getPersistConfig({
  key: 'levelApp',
  storage,
  blacklist: [],
  rootReducer: LevelReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistDeviceApp = getPersistConfig({
  key: 'deviceApp',
  storage,
  blacklist: [],
  rootReducer: DeviceReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistUnitTTHCApp = getPersistConfig({
  key: 'unitTTHCApp',
  storage,
  blacklist: [],
  rootReducer: UnitTTHCReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistTeamHCCApp = getPersistConfig({
  key: 'teamHCCApp',
  storage,
  blacklist: [],
  rootReducer: TeamHCCReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistSendEvlApp = getPersistConfig({
  key: 'sendEvlApp',
  storage,
  blacklist: [],
  rootReducer: SendEvlReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistEvlReportApp = getPersistConfig({
  key: 'evlReportApp',
  storage,
  blacklist: [],
  rootReducer: EvlReportReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistEvlQRApp = getPersistConfig({
  key: 'evlQRApp',
  storage,
  blacklist: [],
  rootReducer: EvlQRReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt questionApp');
      },
    }),
  ],
});

const persistSecurityApp = getPersistConfig({
  key: 'securityApp',
  storage,
  blacklist: [],
  rootReducer: Security,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt securityApp');
      },
    }),
  ],
});

const persistSecurityClientApp = getPersistConfig({
  key: 'securityClientApp',
  storage,
  blacklist: [],
  rootReducer: securityClientReducer,
  stateReconciler: autoMergeLevel2,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_SUPER_SECRET_KEY,
      onError: function (error) {
        print(error, 'red', 'Error về redux encrypt securityClientApp');
      },
    }),
  ],
});

const reducers = combineReducers({
  settings: persistReducer(persistSettingConfig, Settings),
  common: persistReducer(persistCommonConfig, Common),
  configApp: persistReducer(persistConfigApp, Config),
  categoryApp: persistReducer(persistCategoryApp, Category),
  bannerApp: persistReducer(persistBannerApp, Banner),
  sloganApp: persistReducer(persistSloganApp, Slogan),
  leadCadresApp: persistReducer(persistLeadCadresApp, LeadCadres),
  linkApp: persistReducer(persistLinkApp, Link),
  documentTypeApp: persistReducer(persistDocumentTypeApp, DocumentType),
  documentApp: persistReducer(persistDocumentApp, Document),
  lawDisseminationApp: persistReducer(
    persistLawDisseminationApp,
    LawDissemination,
  ),
  mediaLibraryApp: persistReducer(persistMediaLibraryApp, MediaLibrary),
  onlineInquiryApp: persistReducer(persistOnlineInquiryApp, OnlineInquiry),
  agencyIssuedApp: persistReducer(persistAgencyIssuedApp, AgencyIssued),
  customerApp: persistReducer(persistCustomerApp, Customer),
  emailServerApp: persistReducer(persistEmailServerApp, EmailServer),
  contactApp: persistReducer(persistContactApp, Contact),
  articleApp: persistReducer(persistArticleApp, Article),
  // snackbar: persistReducer(persistSnackBarApp, SnackBar),
  snackbar: SnackBar,
  procedureApp: persistReducer(persistProcedureApp, ProcedureReducer),
  policeApp: persistReducer(persistPoliceApp, PoliceReducer),
  articleProcedureApp: persistReducer(
    persistArticleProcedureApp,
    ArticleProcedureReducer,
  ),
  reflectApp: persistReducer(persistReflectApp, Reflect),
  reflectionFieldApp: persistReducer(
    persistReflectionFieldApp,
    ReflectionField,
  ),
  authentication: persistReducer(persistAuthReducerApp, AuthReducer),
  tagApp: persistReducer(persistTagApp, TagReducer),
  userApp: persistReducer(persistUserApp, UserReducer),
  roleApp: persistReducer(persistRoleApp, RoleReducer),
  layoutApp: persistReducer(persistLayoutApp, Layout),
  unitApp: persistReducer(persistUnitApp, Unit),
  configReflectApp: persistReducer(
    persistConfigReflectApp,
    configReflectReducer,
  ),
  securityApp: persistReducer(persistSecurityApp, Security),
  securityClientApp: persistReducer(
    persistSecurityClientApp,
    securityClientReducer,
  ),
  analyticsApp: persistReducer(persistAnalyticsApp, AnalyticsReducer),
  layoutClientApp: persistReducer(persistLayoutClientApp, layoutClientReducer),
  questionApp: persistReducer(persistQuestionApp, Question),
  activityApp: persistReducer(persistActivityApp, ActivityReducer),
  configQuestionApp: persistReducer(persistConfigQuestionApp, ConfigQuestion),
  leaderActivityApp: persistReducer(persistLeaderActivityApp, LeaderActivity),
  criteriaApp: persistReducer(persistCriteriaApp, CriteriaReducer),
  levelApp: persistReducer(persistLevelApp, LevelReducer),
  deviceApp: persistReducer(persistDeviceApp, DeviceReducer),
  unitTTHCApp: persistReducer(persistUnitTTHCApp, UnitTTHCReducer),
  teamHCCApp: persistReducer(persistTeamHCCApp, TeamHCCReducer),
  sendEvlApp: persistReducer(persistSendEvlApp, SendEvlReducer),
  evlReportApp: persistReducer(persistEvlReportApp, EvlReportReducer),
  evlQRApp: persistReducer(persistEvlQRApp, EvlQRReducer),
});
export default reducers;
