const {
  GET_LEAD_CADRES_LIST,
  GET_LEAD_CADRES_DETAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  leadCadresList: [],
  selectedLeadCadres: null,
  paging: null,
  message: null,
};

const leadCadresReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAD_CADRES_LIST:
      return {
        ...state,
        leadCadresList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_LEAD_CADRES_DETAIL:
      return {
        ...state,
        selectedLeadCadres: action.payload,
      };
    default:
      return state;
  }
};

export default leadCadresReducer;
