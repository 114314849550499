import {
  FETCH_ERROR,
  FETCH_ERROR_FROM_BE,
  FETCH_START,
  FETCH_SUCCESS,
  GET_IP_USER,
  HIDE_MESSAGE,
  HIDE_RIGHT_SIDEBAR,
  SET_BREADCRUMBS,
  SHOW_MESSAGE,
  SHOW_RIGHT_SIDEBAR,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  isValidForm: false,
  validation: null,
  isShowSidebarRight: true,
  ip: null,
  breadcrumbs: null,
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: '',
        message: '',
        loading: true,
        isValidForm: false,
        validation: null,
      };
    }
    case UPDATING_CONTENT: {
      return {...state, error: '', message: '', updatingContent: true};
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        // message: '',
        loading: false,
        updatingContent: false,
        isValidForm: false,
        validation: null,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.payload,
        loading: false,
        updatingContent: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
        updatingContent: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        updatingContent: false,
      };
    }
    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }
    case FETCH_ERROR_FROM_BE: {
      return {
        ...state,
        loading: false,
        isValidForm: true,
        validation: action.payload,
        updatingContent: false,
        message: '',
      };
    }

    case HIDE_RIGHT_SIDEBAR: {
      return {
        ...state,
        isShowSidebarRight: false,
      };
    }
    case SHOW_RIGHT_SIDEBAR: {
      return {
        ...state,
        isShowSidebarRight: true,
      };
    }
    case GET_IP_USER: {
      return {
        ...state,
        ip: action.payload,
      };
    }
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };

    default:
      return state;
  }
};
export default commonReducer;
