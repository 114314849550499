import jwtAxios from '@common/services/auth/jwt-auth';
import {
  GET_BANNER_CLIENT_LIST,
  GET_BANNER_CLIENT_LIST_FAIL,
  GET_BANNER_CLIENT_LIST_SUCCESS,
} from 'shared/constants/ActionTypes';
import {
  URL_BANNER_CLIENT_LIST,
  URL_BANNER_CLIENT_LIST_V2,
} from 'shared/constants/api-url/banner';
import {API_CAT} from 'shared/constants/UrlApis';

export const onGetBannerClientList = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_BANNER_CLIENT_LIST});
    const url = `${URL_BANNER_CLIENT_LIST}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: GET_BANNER_CLIENT_LIST_SUCCESS, payload: response});
        } else {
          dispatch({
            type: GET_BANNER_CLIENT_LIST_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_BANNER_CLIENT_LIST_FAIL,
        });
        // if (error.response.status === 404) {
        //   router.push('/404');
        // }
      });
  };
};

export const onGetBannerClientListV2 = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_BANNER_CLIENT_LIST});
    const url = `${URL_BANNER_CLIENT_LIST_V2}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: GET_BANNER_CLIENT_LIST_SUCCESS, payload: response});
        } else {
          dispatch({
            type: GET_BANNER_CLIENT_LIST_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_BANNER_CLIENT_LIST_FAIL,
        });
        // if (error.response.status === 404) {
        //   router.push('/404');
        // }
      });
  };
};
