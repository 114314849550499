import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import Image from '@common/core/LazyLoadImage';

function AppBannerDown() {
  const dataBanner = useSelector(
    ({bannerApp}) => bannerApp.bannerListClient.bottom,
  );

  if (!dataBanner || !(Array.isArray(dataBanner) && dataBanner.length > 0))
    return null;
  return (
    <Box
      sx={{
        py: 0,
        borderRadius: 1,
        pb: 1,
      }}
    >
      <Image src={dataBanner[0]} />
    </Box>
  );
}

AppBannerDown.propTypes = {};

export default AppBannerDown;
