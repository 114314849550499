import {decryptByAES} from '@common/utility/Encrypt';
import {
  CHANGE_SELECTED_ALL_PERMISSION,
  CHANGE_SELECTED_PERMISSION,
  CREATE_NEW_ROLE,
  DELETE_ROLE,
  GET_CREATE_OBJ_ROLE,
  GET_JOB_LIST,
  GET_ROLE_DETAIL,
  GET_ROLE_LIST,
  SET_VALUE_ROLE,
  UPDATE_ROLE_DETAIL,
} from 'shared/constants/ActionTypes';

const initialState = {
  roleList: [],
  selectedRole: null,
  paging: null,
  message: null,
  jobList: null,
};

const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_ROLE:
      return {
        ...state,
        roleList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_ROLE_DETAIL:
      return {
        ...state,
        roleList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_ROLE:
      return {
        ...state,
        roleList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_ROLE_DETAIL:
      return {
        ...state,
        selectedRole: action.payload.data,
      };
    case GET_JOB_LIST:
      return {
        ...state,
        jobList: action.payload.data,
      };
    case CHANGE_SELECTED_ALL_PERMISSION:
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          chucNangs: !!action.payload.value
            ? [
                ...state.selectedRole.chucNangs.filter(
                  (x) => x.id !== action.payload.id,
                ),
                {id: action.payload.id, quyen: action.payload.permissionList},
              ]
            : [...state.selectedRole.chucNangs].filter(
                (x) => x.id !== action.payload.id,
              ),
        },
      };
    case CHANGE_SELECTED_PERMISSION:
      let arrResult = [];
      let arr = [...state.selectedRole.chucNangs].filter(
        (x) => x.id === action.payload.id,
      )[0];
      if (!!arr) {
        arrResult = arr.quyen.split(',');
        if (arrResult.includes(action.payload.permission)) {
          //Trường hợp : unchecked => permission R => remove R to CRUD : CUD

          arrResult = !action.payload.value
            ? arrResult.filter((x) => x !== action.payload.permission)
            : arrResult;
        } else {
          //Trường hợp : checked => permission R => add R to CUD : CRUD
          if (!!action.payload.value) arrResult.push(action.payload.permission);
        }
      } else {
        //thêm mới``
        arrResult.push(action.payload.permission);
      }
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          chucNangs: [
            ...state.selectedRole.chucNangs.filter(
              (x) => x.id !== action.payload.id || x.quyen === '',
            ),
            arrResult.length > 0 && {
              id: action.payload.id,
              quyen: arrResult.join(','),
            },
          ].filter(Boolean),
        },
      };
    case GET_CREATE_OBJ_ROLE:
      return {
        ...state,
        selectedRole: {
          id: null,
          tenQuyen: null,
          ghiChu: null,
          chucNangs: [],
        },
      };
    case SET_VALUE_ROLE:
      return {
        ...state,
        selectedRole: {
          ...state.selectedRole,
          tenQuyen: action.payload.tenQuyen,
          ghiChu: action.payload.ghiChu,
        },
      };
    default:
      return state;
  }
};

export default RoleReducer;
