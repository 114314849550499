const {
  GET_AGENCY_ISSUED_LIST,
  GET_AGENCY_ISSUED_DETAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  agencyIssuedList: [],
  selectedAgencyIssued: null,
  paging: null,
  message: null,
};

const agencyIssuedReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENCY_ISSUED_LIST:
      return {
        ...state,
        agencyIssuedList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_AGENCY_ISSUED_DETAIL:
      return {
        ...state,
        selectedAgencyIssued: action.payload,
      };
    default:
      return state;
  }
};

export default agencyIssuedReducer;
