import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import Image from '@common/core/LazyLoadImage';

function AppPoster({index}) {
  const dataBanner = useSelector(
    ({bannerApp}) => bannerApp.bannerListClient.poster,
  );

  if (!dataBanner || !(Array.isArray(dataBanner) && dataBanner.length > 0))
    return null;
  if (dataBanner.length < index) return null;
  return (
    <Box
      sx={{
        py: 0,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Image src={dataBanner[index]} />
    </Box>
  );
}

AppPoster.propTypes = {
  index: PropTypes.number,
};

export default AppPoster;
