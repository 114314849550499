import {CLOSE_SNACKBAR, OPEN_SNACKBAR} from 'shared/constants/ActionTypes';

const initialSnackbar = {
  action: false,
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  variant: 'default',
  alert: {
    color: 'primary',
    variant: 'filled',
  },
  transition: 'Fade',
  close: true,
  actionButton: false,
};

const snackbarReducer = (state = initialSnackbar, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR: {
      const {
        open,
        message,
        anchorOrigin,
        variant,
        alert,
        transition,
        close,
        actionButton,
      } = action.payload;
      return {
        ...state,
        open: open || initialSnackbar.open,
        message: message || initialSnackbar.message,
        anchorOrigin: anchorOrigin || initialSnackbar.anchorOrigin,
        variant: variant || initialSnackbar.variant,
        alert: {
          color: alert?.color || initialSnackbar.alert.color,
          variant: alert?.variant || initialSnackbar.alert.variant,
        },
        transition: transition || initialSnackbar.transition,
        close: close === false ? close : initialSnackbar.close,
        actionButton: actionButton || initialSnackbar.actionButton,
      };
    }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
        close: true,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
