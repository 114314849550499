const {GET_EMAIL_SERVER_LIST} = require('shared/constants/ActionTypes');

const initialState = {
  emailServerList: [],
  selectedEmailServer: null,
  paging: null,
  message: null,
};

const emailServerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL_SERVER_LIST:
      return {
        ...state,
        emailServerList: action.payload.data,
        paging: action.payload.paging,
      };
    default:
      return state;
  }
};

export default emailServerReducer;
