const {GET_MEDIA_LIBRARY_LIST} = require('shared/constants/ActionTypes');

const initialState = {
  mediaLibraryList: [],
  selectedMediaLibrary: null,
  paging: null,
  message: null,
};

const mediaLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDIA_LIBRARY_LIST:
      return {
        ...state,
        mediaLibraryList: action.payload.data,
        paging: action.payload.paging,
      };
    default:
      return state;
  }
};

export default mediaLibraryReducer;
