import 'regenerator-runtime/runtime';
import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import {CacheProvider} from '@emotion/react';
import createEmotionCache from '../createEmotionCache';
import AppContextProvider from '../@common/utility/AppContextProvider';
import {Provider} from 'react-redux';
import AppThemeProvider from '../@common/utility/AppThemeProvider';
import AppStyleProvider from '../@common/utility/AppStyleProvider';
import AppLocaleProvider from '../@common/utility/AppLocaleProvider';
import AuthRoutes from '../@common/utility/AuthRoutes';
import {useStore} from '../redux/store'; // Client-side cache, shared for the whole session of the user in the browser.
import NextNProgress from 'nextjs-progressbar';
import JWTAuthAuthProvider from '@common/services/auth/jwt-auth/JWTAuthProvider';
import AppPageMeta from '@common/core/AppPageMeta';

import '../@common/services/index';
import '../shared/vendors/index.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// lightbox
import 'react-image-lightbox/style.css';

// plyr
import 'plyr-react/plyr.css';

//analytic
import {GoogleAnalytics} from 'nextjs-google-analytics';
import {useRouter} from 'next/router';

//persist
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {AppLoader} from '@common';
import useRouteUrlHistory from '@common/utility/hooks/useRouterUrlHistory';
import AppNotistackProvider from '@common/utility/AppNotistack';
import MotionLazyContainer from '@common/core/AppAnimate/MotionLazyContainer';
import AppSnackbar from '@common/core/third-party/Snackbar';
import {print} from '@common/utility/Utils';
import {UserAgentProvider} from '@common/utility/AppUA';
import 'tinymce/skins/content/default/content.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export const RouteContext = React.createContext({previousRoute: ''});

function MyApp(props) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: {session, ...pageProps},
  } = props;
  const store = useStore(pageProps.initialReduxState);
  // initialize persistor
  const persistor = persistStore(store, null, () => {
    persistor.persist();
  });

  if (process.env.NODE_ENV !== 'development' && typeof window !== 'undefined') {
    const APP_VERSION = process.env.NEXT_APP_CURRENT_GIT_SHA;
    if (
      typeof localStorage.APP_VERSION === 'undefined' ||
      localStorage.APP_VERSION === null
    ) {
      localStorage.setItem('APP_VERSION', APP_VERSION);
    }
    if (localStorage.APP_VERSION != APP_VERSION) {
      localStorage.setItem('APP_VERSION', APP_VERSION);
      persistor.purge();
      print(
        {},
        'blue',
        `Đã cập nhật phiên bản Website ${localStorage.APP_VERSION}`,
      );
    }
  }

  const router = useRouter();
  const {previousRoute} = useRouteUrlHistory();
  const isBrowser = typeof window !== 'undefined';

  return (
    <CacheProvider value={emotionCache}>
      {/* <SessionProvider
        session={session} // Re-fetch session every 30 minutes
      > */}
      <AppContextProvider>
        <AppPageMeta />
        <RouteContext.Provider
          value={{
            previousRoute,
          }}
        >
          <UserAgentProvider>
            <MotionLazyContainer>
              <AppThemeProvider>
                <AppStyleProvider>
                  <AppNotistackProvider>
                    <AppLocaleProvider>
                      <Provider store={store}>
                        <PersistGate
                          loading={<AppLoader />}
                          persistor={persistor}
                          // onBeforeLift={() =>
                          //   new Promise((resolve) => setTimeout(resolve, 500))
                          // }
                        >
                          <JWTAuthAuthProvider>
                            <AuthRoutes>
                              <GoogleAnalytics trackPageViews />
                              <AppSnackbar />
                              <CssBaseline />
                              <NextNProgress
                                color='#C7262E'
                                stopDelayMs={200}
                                height={3}
                                showOnShallow={true}
                              />
                              <Component {...pageProps} key={router.asPath} />
                            </AuthRoutes>
                          </JWTAuthAuthProvider>
                        </PersistGate>
                      </Provider>
                    </AppLocaleProvider>
                  </AppNotistackProvider>
                </AppStyleProvider>
              </AppThemeProvider>
            </MotionLazyContainer>
          </UserAgentProvider>
        </RouteContext.Provider>
      </AppContextProvider>
      {/* </SessionProvider> */}
    </CacheProvider>
  );
}

// MyApp.getInitialProps = async (appContext) => {
//   const appProps = await App.getInitialProps(appContext);
//   const reduxStore = initializeStore({});

//   appProps.pageProps = {
//     ...appProps.pageProps,
//     initialReduxState: reduxStore.getState(),
//   };
//   return appProps;
// };

export default MyApp;

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
