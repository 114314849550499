import {createWrapper} from 'next-redux-wrapper';
import {useMemo} from 'react';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import reducers from '../reducers';
let store;
// const createNoopStorage = () => {
//   return {
//     getItem(_key) {
//       return Promise.resolve(null);
//     },
//     setItem(_key, value) {
//       return Promise.resolve(value);
//     },
//     removeItem(_key) {
//       return Promise.resolve();
//     },
//   };
// };

// const storage =
//   typeof window !== 'undefined'
//     ? createWebStorage('local')
//     : createNoopStorage();
// const persistConfig = {
//   key: 'primary',
//   storage,
//   transforms: [
//     encryptTransform({
//       secretKey: process.env.NEXT_SUPER_SECRET_KEY,
//       onError: function (error) {
//         print(error, 'red', 'Error về redux encrypt');
//       },
//     }),
//   ],
//   blacklist: [
//     'snackbar',
//     'common.error',
//     'common.loading',
//     'common.isShowSidebarRight',
//     'common.validation',
//   ],
//   rootReducer: reducers,
// };

// const persistedReducer = persistReducer(persistConfig, reducers);

function initStore(initialState) {
  return createStore(
    reducers,
    initialState,
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools(applyMiddleware(thunkMiddleware))
      : applyMiddleware(thunkMiddleware),
  );
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    store = undefined;
  }
  if (typeof window === 'undefined') return _store;
  if (!store) store = _store;
  return _store;
};

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}

export const wrapper = createWrapper(initStore, {debug: false});

export {store};
