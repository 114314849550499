import React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import Image from '@common/core/LazyLoadImage';
import AppCard from '@common/core/AppCard';

function AppBannerRight() {
  const dataBanner = useSelector(
    ({bannerApp}) => bannerApp.bannerListClient.right,
  );

  if (!dataBanner || !(Array.isArray(dataBanner) && dataBanner.length > 0))
    return null;
  return (
    <AppCard contentStyle={{px: 1}} sx={{my: 2}}>
      <Box
        sx={{
          py: 0,
          borderRadius: 1,
          pb: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* <Marquee
          axis={'Y'}
          height={'420px'}
          duration={10000}
          reverse={true}
          align='center'
          width='100%'
          pauseOnHover={true}
        > */}
        {dataBanner &&
          dataBanner.map((rs, index) => {
            return (
              <Image
                src={rs}
                style={{
                  borderRadius: '4px',
                }}
                key={index}
              />
            );
          })}
        {/* </Marquee> */}
      </Box>
    </AppCard>
  );
}

AppBannerRight.propTypes = {};

export default AppBannerRight;
