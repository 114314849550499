const {
  GET_LEVEL_LIST,
  DELETE_LEVEL,
  GET_LEVEL_DETAIL,
  CREATE_NEW_LEVEL,
  UPDATE_LEVEL_DETAIL
} = require('shared/constants/ActionTypes');

const initialState = {
  levelList: [],
  selectedLevel: null,
  paging: null,
  message: null,
};

const LevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEVEL_LIST:
      return {
        ...state,
        levelList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_LEVEL:
      return {
        ...state,
        levelList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_LEVEL_DETAIL:
      return {
        ...state,
        levelList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_LEVEL: {
      return {
        ...state,
        levelList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_LEVEL_DETAIL:
      return {
        ...state,
        selectedLevel: action.payload,
      };
    default:
      return state;
  }
};

export default LevelReducer;
