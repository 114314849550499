const {
  GET_ARTICLE_PROCEDURE_LIST,
  DELETE_ARTICLE_PROCEDURE,
  GET_ARTICLE_PROCEDURE_DETAIL,
  CREATE_NEW_ARTICLE_PROCEDURE,
  UPDATE_CONFIG_ARTICLE_PROCEDURE,
  ARTICLE_PROCEDURE_PUBLISHER,
  DELETE_DATA_ARTICLE_PROCEDURE,
  LIST_ADMINISTRATIVE_PAPER,
} = require('shared/constants/ActionTypes');

const initialState = {
  articleProcedureList: [],
  selectedArticleProcedure: null,
  paging: null,
  message: null,
  listArticlePublisher: [],
  listAdministrativePapers: [],
  detailsArticleProcedure: null,
};

const articleProcedureReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE_PROCEDURE_LIST:
      return {
        ...state,
        articleProcedureList: action.payload.data,
        paging: action.payload.paging,
        selectedArticleProcedure: null,
      };
    case CREATE_NEW_ARTICLE_PROCEDURE:
      return {
        ...state,
        articleProcedureList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_CONFIG_ARTICLE_PROCEDURE:
      return {
        ...state,
        articleProcedureList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_ARTICLE_PROCEDURE: {
      return {
        ...state,
        articleProcedureList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_ARTICLE_PROCEDURE_DETAIL:
      return {
        ...state,
        selectedArticleProcedure: action.payload.data,
      };
    case DELETE_DATA_ARTICLE_PROCEDURE:
      return {
        ...state,
        selectedArticleProcedure: null,
      };
    case ARTICLE_PROCEDURE_PUBLISHER:
      return {
        ...state,
        listArticlePublisher: action.payload.data,
      };
    case LIST_ADMINISTRATIVE_PAPER:
      return {
        ...state,
        listAdministrativePapers: action.payload.data,
      };
    default:
      return state;
  }
};

export default articleProcedureReducer;
