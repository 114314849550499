import AppCard from '@common/core/AppCard';
import Image from '@common/core/LazyLoadImage';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import Marquee from 'react-easy-marquee';
import {Fonts} from 'shared/constants/AppEnums';
import parseHtml from '@common/utility/HtmlParser';
import {useSelector} from 'react-redux';
const SloganPolice = () => {
  const dataSloganClient = useSelector(
    ({sloganApp}) => sloganApp.dataSloganClient,
  );
  return (
    <Hidden lgDown>
      {dataSloganClient ? (
        <Box
          sx={{
            pt: {lg: 0, xs: 0},
            // borderTop: 6,
            // borderColor: (theme) => theme.palette.secondary.mauExtension,
            position: 'relative',
            // bgcolor: '#B60704',
            minHeight: 320,
          }}
        >
          <Box sx={{zIndex: -9999}}>
            <Image src={dataSloganClient?.filePath} style={{minHeight: 320}} />
          </Box>
          <Box
            sx={{
              zIndex: 2,
              position: 'absolute',
              top: 90,
              left: 40,
              right: 40,
            }}
          >
            <Marquee
              duration={20000}
              background='transparent'
              height={200}
              axis='Y'
              pauseOnHover={true}
              reverse={true}
            >
              <div
                sx={{p: 2, lineHeight: 1.5, fontSize: 8}}
                className='preview'
              >
                {dataSloganClient?.noiDung &&
                  parseHtml(dataSloganClient?.noiDung)}
              </div>
              {/* <Box
                sx={{
                  pb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.grey['50']}
                >
                  ĐỐI VỚI ĐỊCH, PHẢI{' '}
                </Typography>
                <Typography
                  align='left'
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.secondary.yellowCongAn}
                >
                  CƯƠNG QUYẾT KHÔN KHÉO
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.grey['50']}
                >
                  ĐỐI VỚI TỰ MÌNH, PHẢI
                </Typography>
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.secondary.yellowCongAn}
                >
                  CẦN, KIỆM, LIÊM, CHÍNH
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.grey['50']}
                >
                  ĐỐI VỚI CHÍNH PHỦ, PHẢI
                </Typography>
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.secondary.yellowCongAn}
                >
                  TUYỆT ĐỐI TRUNG THÀNH
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.grey['50']}
                >
                  ĐỐI VỚI CÔNG VIỆC, PHẢI{' '}
                </Typography>
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.secondary.yellowCongAn}
                >
                  {' '}
                  TẬN TỤY
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.grey['50']}
                >
                  ĐỐI VỚI NHÂN DÂN, PHẢI
                </Typography>
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.secondary.yellowCongAn}
                >
                  KÍNH TRỌNG LỄ PHÉP
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.grey['50']}
                >
                  ĐỐI VỚI ĐỒNG SỰ, PHẢI{' '}
                </Typography>
                <Typography
                  sx={{fontWeight: Fonts.BOLD, fontSize: 12}}
                  color={(theme) => theme.palette.secondary.yellowCongAn}
                >
                  THÂN ÁI GIÚP ĐỠ
                </Typography>
              </Box> */}
            </Marquee>
          </Box>
        </Box>
      ) : null}
    </Hidden>
  );
};

SloganPolice.propTypes = {
  // data: PropTypes.any,
};

export default SloganPolice;
