import React, {useEffect, useRef} from 'react';
import lottie from 'lottie-web';
import Box from '@mui/material/Box';

export default function AppLottieLaw() {
  let container1 = useRef();
  useEffect(() => {
    lottie.loadAnimation({
      container: container1.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('./json/law-green.json'),
    });
  }, []);

  return (
    <React.Fragment>
      <Box ref={container1} sx={{maxHeight: 90, height: 90}} />
    </React.Fragment>
  );
}
