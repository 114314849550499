const prefix = '/danh-muc';
const prefix3 = '/danh-muc';
const prefix4 = '/lien-ket';
const prefix1 = '/tin-bai';
const prefix2 = '/common';
const prefix5 = '/danh-ba';
const prefix6 = '/banner';
const prefix7 = '/don-vi';
const prefix8 = '/tin-bai-web';

export const URL_CATEGORY_GET_LIST = prefix + '/list';
export const URL_CATEGORY_GET_DETAIL = prefix + '/get';
export const URL_CATEGORY_GET_PARENT = prefix3 + '/get-menu';
export const URL_CATEGORY_DELETE = prefix + '/delete';
export const URL_CATEGORY_CREATE = prefix + '/submit';
export const URL_CATEGORY_CHILDRENT_DETAIL =
  prefix1 + '/tin-bai-by-link-danh-muc';
export const URL_DETAIL_NEW_CATEGORY_CHILDRENT = prefix1 + '/chi-tiet';
export const URL_LIST_MEWS_CATEGORY = prefix1 + '/tin-moi-nhat';
export const URL_LIST_VIEWS_CATEGORY = prefix1 + '/xem-nhieu-nhat';
export const URL_LIST_LINKS_PAGE_CATEGORY = prefix4 + '/list';
export const URL_COUNT_CLICK = prefix4 + '/them-luot-click';
export const URL_GET_CATEGORY_PARENT = prefix2 + '/dropdown-danh-muc';
export const URL_GET_PHONE_BOOK_CATEGORY = prefix5 + '/list';
export const URL_LIST_BANNER_CATEGORY = prefix6 + '/list';
export const URL_GET_LIST_IMAGE = prefix + '/get-media';
export const URL_CATEGORY_UPLOAD_FILE = prefix + '/upload-file';

export const URL_LIST_DON_VI = prefix7 + '/list';

export const URL_LIST_TTHC_NGUON_2 = prefix8 + '/dm-tthc';
export const URL_LIST_TTHC_NGUON_3 = prefix8 + '/cap-hanh-chinh';
export const URL_LIST_TTHC_NGUON_4 = prefix8 + '/dvi-cong-an';
export const URL_LIST_TTHC_NGUON_5 = prefix8 + '/giay-to-tthc';
export const URL_LIST_TTHC_NGUON_6 = prefix8 + '/tin-bai-giay-to';
export const URL_LIST_TTHC_NGUON_7 = prefix8 + '/chi-tiet';
