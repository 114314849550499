import jwtAxios from '@common/services/auth/jwt-auth';
import {
  appIntl,
  buildQueryString,
  parseParams,
} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GETALL_VIDEO_ARTICLE_CLIENT,
  GETALL_VIDEO_ARTICLE_CLIENT_SUCCESS,
  GET_ARTICLES_CLIENT,
  GET_ARTICLES_CLIENT_FAIL,
  GET_ARTICLES_CLIENT_SUCCESS,
  GET_COUNT_APPROVAL,
  GET_DETAILS_ARTICLE_CLIENT,
  GET_DETAILS_ARTICLE_CLIENT_FAIL,
  GET_DETAILS_ARTICLE_CLIENT_SUCCESS,
  GET_DETAILS_ARTICLE_VIDEO_CLIENT,
  GET_DETAILS_ARTICLE_VIDEO_CLIENT_FAIL,
  GET_DETAILS_ARTICLE_VIDEO_CLIENT_SUCCESS,
  GET_DETAIL_APPROVAL,
  GET_FEATURED_ARTICLE_CLIENT,
  GET_FEATURED_ARTICLE_CLIENT_FAIL,
  GET_FEATURED_ARTICLE_CLIENT_SUCCESS,
  GET_LATEST_ARTICLE_CLIENT,
  GET_LATEST_ARTICLE_CLIENT_FAIL,
  GET_LATEST_ARTICLE_CLIENT_SUCCESS,
  GET_MOST_VIEW_ARTICLE_CLIENT,
  GET_MOST_VIEW_ARTICLE_CLIENT_FAIL,
  GET_MOST_VIEW_ARTICLE_CLIENT_SUCCESS,
  GET_VIDEO_ARTICLE_CLIENT,
  GET_VIDEO_ARTICLE_CLIENT_FAIL,
  GET_VIDEO_ARTICLE_CLIENT_SUCCESS,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import {
  URL_HOMEPAGE_APPROVAL,
  URL_HOMEPAGE_APPROVAL_ARTICLE,
  URL_HOMEPAGE_DETAIL_APPROVAL,
  URL_HOMEPAGE_DETAIL_VID_ART,
  URL_HOMEPAGE_GETALL_VID_ART,
  URL_HOMEPAGE_GET_ARTS,
  URL_HOMEPAGE_GET_FEATURED_ART,
  URL_HOMEPAGE_GET_LATEST_ART,
  URL_HOMEPAGE_GET_MOSTVIEWS_ART,
  URL_HOMEPAGE_GET_VIDEO_ART,
} from 'shared/constants/api-url/article';
import {URL_DETAIL_NEW_CATEGORY_CHILDRENT} from 'shared/constants/api-url/category';
import {API_CAT, API_CAT_DANHMUC} from 'shared/constants/UrlApis';

export const onGetDetailPost = (slug, router) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_DETAILS_ARTICLE_CLIENT});
    jwtAxios
      .get(URL_DETAIL_NEW_CATEGORY_CHILDRENT, {
        params: {
          slug,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_DETAILS_ARTICLE_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: GET_DETAILS_ARTICLE_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_DETAILS_ARTICLE_CLIENT_FAIL,
        });
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};

export const onGetLatestPost = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_LATEST_ARTICLE_CLIENT});
    jwtAxios
      .get(URL_HOMEPAGE_GET_LATEST_ART, {
        params: {
          // selectTop: selectTop,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_LATEST_ARTICLE_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_LATEST_ARTICLE_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_LATEST_ARTICLE_CLIENT_FAIL});
      });
  };
};

export const onGetMostViewPost = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_MOST_VIEW_ARTICLE_CLIENT});
    jwtAxios
      .get(URL_HOMEPAGE_GET_MOSTVIEWS_ART, {
        params: {
          // selectTop: selectTop,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_MOST_VIEW_ARTICLE_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_MOST_VIEW_ARTICLE_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_MOST_VIEW_ARTICLE_CLIENT_FAIL});
      });
  };
};

export const onGetArticlesClient = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_ARTICLES_CLIENT});
    jwtAxios
      .get(URL_HOMEPAGE_GET_ARTS, {
        params: {
          // selectTop: selectTop,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_ARTICLES_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_ARTICLES_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_ARTICLES_CLIENT_FAIL});
      });
  };
};

export const onGetFeaturedPost = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_FEATURED_ARTICLE_CLIENT});
    jwtAxios
      .get(URL_HOMEPAGE_GET_FEATURED_ART, {
        params: {
          // selectTop: selectTop,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_FEATURED_ARTICLE_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_FEATURED_ARTICLE_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_MOST_VIEW_ARTICLE_CLIENT_FAIL});
      });
  };
};

export const onGetVideoPost = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_VIDEO_ARTICLE_CLIENT});
    jwtAxios
      .get(URL_HOMEPAGE_GET_VIDEO_ART, {
        params: {
          // selectTop: selectTop,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_VIDEO_ARTICLE_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_VIDEO_ARTICLE_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_VIDEO_ARTICLE_CLIENT_FAIL});
      });
  };
};

export const onGetCountApproval = () => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  return (dispatch) => {
    jwtAxios
      .get(URL_HOMEPAGE_APPROVAL, {
        params: {
          // selectTop: selectTop,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_COUNT_APPROVAL,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const onGetAllVideoPost = (dataFilter) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GETALL_VIDEO_ARTICLE_CLIENT});
    const url = `${URL_HOMEPAGE_GETALL_VID_ART}?${buildQueryString(
      parseParams(dataFilter),
    )}`;
    jwtAxios
      .get(url, {
        params: {
          // selectTop: selectTop,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GETALL_VIDEO_ARTICLE_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GETALL_VIDEO_ARTICLE_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GETALL_VIDEO_ARTICLE_CLIENT_FAIL});
      });
  };
};

export const onGetDetailPostVideo = (slug, router, dataFilter) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  const url = `${URL_HOMEPAGE_DETAIL_VID_ART}?${buildQueryString(
    parseParams(dataFilter),
  )}`;

  return (dispatch) => {
    dispatch({type: GET_DETAILS_ARTICLE_VIDEO_CLIENT});
    jwtAxios
      .get(url, {
        params: {
          slug,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_DETAILS_ARTICLE_VIDEO_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: GET_DETAILS_ARTICLE_VIDEO_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_DETAILS_ARTICLE_VIDEO_CLIENT_FAIL,
        });
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};

export const onGetDetailApproval = (slug, router) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;

  return (dispatch) => {
    jwtAxios
      .get(URL_HOMEPAGE_DETAIL_APPROVAL, {
        params: {
          slug,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_DETAIL_APPROVAL,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          console.log('error', data);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};

export const onApprovalArticle = (dataPublished, router) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_HOMEPAGE_APPROVAL_ARTICLE, {slug: dataPublished.slug})
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(onGetCountApproval());
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.articleConfirmTrue'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};
