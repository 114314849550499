import React, {useCallback} from 'react';
import TablePagination from '@mui/material/TablePagination';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';

const AppsPagination = ({count, page, onPageChange, rowsPerPage, ...rest}) => {
  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  function handleDebounceFn() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  const onChange = (e, value) => {
    onPageChange(e, value);
    debounceFn();
  };
  return (
    <Pagination
      component='div'
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{'aria-label': 'Trang trước'}}
      nextIconButtonProps={{'aria-label': 'Trang tiếp theo'}}
      onChange={onChange}
      // rowsPerPageOptions={[]}
      variant='outlined'
      shape='rounded'
      {...rest}
    />
  );
};

export default AppsPagination;

AppsPagination.defaultProps = {
  rowsPerPage: 15,
};

AppsPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
};
