const {
  GET_CONTACT_LIST,
  DELETE_CONTACT,
  GET_CONTACT_DETAIL,
  CREATE_NEW_CONTACT,
  UPDATE_CONTACT_DETAIL,
  GET_CONTACT_CLIENT,
  GET_CONTACT_CLIENT_SUCCESS,
  GET_STAFF_LIST,
  GET_DETAIL_INTRODUCE,
} = require('shared/constants/ActionTypes');
import lodash from 'lodash';

const initialState = {
  contactList: [],
  selectedContact: null,
  paging: null,
  message: null,
  contactListClient: [],
  isLoadingClient: null,
  staffList: {},
  detailIntroduces: {},
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_LIST:
      return {
        ...state,
        contactList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_CONTACT:
      return {
        ...state,
        contactList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_CONTACT_DETAIL:
      return {
        ...state,
        contactList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_CONTACT: {
      return {
        ...state,
        contactList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_CONTACT_DETAIL:
      return {
        ...state,
        selectedContact: action.payload,
      };
    case GET_CONTACT_CLIENT:
      return {
        ...state,
        isLoadingClient: true,
      };
    case GET_CONTACT_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingClient: false,
        contactListClient: action.payload.data,
      };
    case GET_CONTACT_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingClient: false,
        contactListClient: [],
      };
    case GET_STAFF_LIST:
      return {
        ...state,
        staffList: lodash.groupBy(action.payload.data, 'chucVu'),
      };
    case GET_DETAIL_INTRODUCE:
      return {
        ...state,
        detailIntroduces: action.payload.data,
      };
    default:
      return state;
  }
};

export default contactReducer;
