import {getClientIp} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_IP_USER,
  HIDE_MESSAGE,
  HIDE_RIGHT_SIDEBAR,
  SET_BREADCRUMBS,
  SHOW_MESSAGE,
  SHOW_RIGHT_SIDEBAR,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
} from 'shared/constants/ActionTypes';

export const fetchStart = () => {
  return (dispatch) => dispatch({type: FETCH_START});
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({type: FETCH_SUCCESS});
};
export const updatingContent = () => {
  return (dispatch) => dispatch({type: UPDATING_CONTENT});
};

export const fetchError = (error) => {
  return (dispatch) => dispatch({type: FETCH_ERROR, payload: error});
};

export const showMessage = (message) => {
  return (dispatch) => dispatch({type: SHOW_MESSAGE, payload: message});
};
export const onToggleAppDrawer = () => {
  return (dispatch) => dispatch({type: TOGGLE_APP_DRAWER});
};

export const hideMessage = () => {
  return (dispatch) => dispatch({type: HIDE_MESSAGE});
};

export const hideRightSidebar = () => {
  return (dispatch) => dispatch({type: HIDE_RIGHT_SIDEBAR});
};
export const showRightSidebar = () => {
  return (dispatch) => dispatch({type: SHOW_RIGHT_SIDEBAR});
};

export const setBreadcrumbs = (data) => {
  return (dispatch) => dispatch({type: SET_BREADCRUMBS, payload: data});
};

export const getIpUser = () => {
  return async (dispatch) => {
    const ip = await getClientIp();
    dispatch({type: GET_IP_USER, payload: ip});
  };
};
