const {GET_CONFIG_QUESTION_LIST} = require('shared/constants/ActionTypes');

const initialState = {
  configList: [],
  paging: null,
  message: null,
};

const configQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_QUESTION_LIST:
      return {
        ...state,
        configList: action.payload.data,
        paging: action.payload.paging,
      };

    default:
      return state;
  }
};

export default configQuestionReducer;
