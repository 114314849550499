import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/src/simplebar.css';
import {useEffect} from 'react';
import {useRef} from 'react';

const StyledSimpleBarReact = styled(SimpleBarReact)(() => ({
  height: '100%',
  width: '100%',
}));

const AppScrollbar = (props) => {
  const {children, triggerScrollTop, ...others} = props;
  let _scrollBarRef = useRef(null);
  useEffect(() => {
    if (triggerScrollTop) {
      if (_scrollBarRef?.current) {
        _scrollBarRef.current
          .getScrollElement()
          .scrollTo({top: 500, behavior: 'smooth'});
      }
    }
  }, [triggerScrollTop]);

  return (
    <StyledSimpleBarReact {...others} ref={_scrollBarRef}>
      {children}
    </StyledSimpleBarReact>
  );
};

export default AppScrollbar;

AppScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  triggerScrollTop: PropTypes.any,
};
