const {GET_LAYOUT_CLIENT} = require('shared/constants/ActionTypes');

const initialState = {
  layoutClientList: [],
};

const layoutClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAYOUT_CLIENT:
      return {
        ...state,
        layoutClientList: action.payload.data,
      };
    default:
      return state;
  }
};

export default layoutClientReducer;
