const {
  GET_QUESTION_CLIENT,
  GET_QUESTION_LIST,
  QUESTION_GET_FIELD,
  GET_QUESTION_CLIENT_FAIL,
  GET_QUESTION_DETAIL_CLIENT,
  GET_QUESTION_DETAIL,
  GET_QUESTION_FIELD_CLIENT_FAIL,
  GET_QUESTION_DETAIL_CLIENT_FAIL,
  SENT_QUESTION,
  SEND_QUESTION,
  GET_MOST_VIEW,
  GET_CLIENT_MOST_VIEW_FIELD,
  QUESTION_ANSWER,
  QUESTION_ANSWERED,
  QUESTION_GET_CAP_HC_BY_FIELD,
  QUESTION_GET_CAP_HC_CLIENT_FAIL,
  GET_QUESTION_NEED_PROCESS,
  GET_QUESTION_NEED_PROCESS_FAIL,
  GET_QUESTION_NEED_PROCESS_CLIENT,
  GET_QUESTION_NEED_PROCESS_CLIENT_FAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  questionList: [],
  questionPaging: null,
  listQuestionField: [],
  listQuestionCapHC: [],
  detailQuestion: {},
  message: null,
  isLoadingClient: null,
  isLoadingDetailClient: null,
  isLoadingSendQuestionClient: false,
  config: null,
  dataReport: null,
  isLoadingAnswer: null,
  questionMostView: [],
  questionNeedProcess: [],
  questionNeedProcessCount: null,
  isLoadingNeedProcessClient: null,
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_LIST:
      return {
        ...state,
        questionList: action.payload.data,
        isLoadingClient: false,
        questionPaging: action.payload.paging,
      };
    case GET_QUESTION_CLIENT:
      return {
        ...state,
        isLoadingClient: true,
      };
    case QUESTION_GET_FIELD:
      return {
        ...state,
        listQuestionField: action.payload.data,
      };
    case QUESTION_GET_CAP_HC_BY_FIELD:
      return {
        ...state,
        listQuestionCapHC: action.payload.data,
      };
    case GET_QUESTION_DETAIL:
      return {
        ...state,
        detailQuestion: action.payload.data,
        isLoadingDetailClient: false,
      };
    case GET_QUESTION_DETAIL_CLIENT:
      return {
        ...state,
        isLoadingDetailClient: true,
      };
    case GET_QUESTION_CLIENT_FAIL:
      return {
        ...state,
        reflectList: [],
      };
    case GET_QUESTION_FIELD_CLIENT_FAIL:
      return {
        ...state,
        listQuestionField: [],
      };
    case QUESTION_GET_CAP_HC_CLIENT_FAIL:
      return {
        ...state,
        listQuestionCapHC: [],
      };
    case GET_QUESTION_DETAIL_CLIENT_FAIL:
      return {
        ...state,
        detailReflection: [],
      };
    case SENT_QUESTION:
      return {
        ...state,
        isLoadingSendQuestionClient: false,
      };
    case SEND_QUESTION:
      return {
        ...state,
        isLoadingSendQuestionClient: true,
      };
    case GET_MOST_VIEW:
      return {
        ...state,
        questionMostView: action.payload.data,
      };
    case GET_CLIENT_MOST_VIEW_FIELD:
      return {
        ...state,
        questionMostView: [],
      };
    case QUESTION_ANSWER:
      return {
        ...state,
        isLoadingAnswer: true,
      };
    case QUESTION_ANSWERED:
      return {
        ...state,
        isLoadingAnswer: false,
      };
    case GET_QUESTION_NEED_PROCESS_CLIENT:
      return {
        ...state,
        isLoadingNeedProcessClient: true,
      };
    case GET_QUESTION_NEED_PROCESS_CLIENT_FAIL:
      return {
        ...state,
        isLoadingNeedProcessClient: false,
      };
    case GET_QUESTION_NEED_PROCESS:
      return {
        ...state,
        questionNeedProcess: action.payload.data,
        questionNeedProcessCount: action.payload.paging,
        isLoadingNeedProcessClient: false,
      };
    case GET_QUESTION_NEED_PROCESS_FAIL:
      return {
        ...state,
        questionNeedProcess: [],
        questionNeedProcessCount: null,
        isLoadingNeedProcessClient: false,
      };
    default:
      return state;
  }
};

export default questionReducer;
