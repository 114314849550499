import {decryptByAES} from '@common/utility/Encrypt';
import {
  AUTH_GET_UNIT,
  CHANGE_PASSWORD_CLIENT,
  CHANGE_PASSWORD_CLIENT_FAIL,
  CHANGE_PASSWORD_CLIENT_SUCCESS,
  GET_INFO_USER,
  LOGOUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  SET_REMEMBER_ME,
  SET_USER_REGISTER,
  SET_USER_UNREGISTER,
  SIGN_IN,
  SIGN_IN_FAIL,
  SIGN_IN_SUCCESS,
  UPDATE_INFO_USER_CLIENT,
  UPDATE_INFO_USER_CLIENT_FAIL,
  UPDATE_INFO_USER_CLIENT_SUCCESS,
} from 'shared/constants/ActionTypes';

const initialState = {
  isSuperAdmin: false,
  isAuthenticated: null,
  isLoading: false,
  accessToken: null,
  user: null,
  callBackUrl: null,
  refreshToken: null,
  role: null,
  permission: null,
  expires: null,
  rememberMe: null,
  typeToken: null,

  isLoadingUpdateInfo: false,
  units: [],
  isCreatedAccount: false, //just created an account
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isLoading: true,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isSuperAdmin: action.payload.data.isSuperAdmin,
        accessToken: action.payload.data.accessToken,
        refreshToken: action.payload.data.refreshToken,
        user: {
          ...state.user,
          ...action.payload.data.thongTinNguoiDung,
          email: action.payload.data.thongTinNguoiDung.email
            ? decryptByAES(
                action.payload.data.thongTinNguoiDung.email,
                process.env.NEXT_SECRET_KEY_HASH,
              )
            : null,
          dienThoai: action.payload.data.thongTinNguoiDung.dienThoai
            ? decryptByAES(
                action.payload.data.thongTinNguoiDung.dienThoai,
                process.env.NEXT_SECRET_KEY_HASH,
              )
            : null,
        },
        isLoading: false,
        expires: action.payload.data.expiredToken,
        typeToken: action.payload.data.loaiToken,
        permission: action.payload.data.chucNangs,
      };
    case SIGN_IN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        user: null,
        isLoading: false,
        role: null,
      };
    case SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.payload.rememberMe,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        isLoading: true,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.token,
        expires: action.payload.exp,
      };
    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        ...initialState,
      };
    case UPDATE_INFO_USER_CLIENT:
      return {
        ...state,
        isLoadingUpdateInfo: true,
      };
    case UPDATE_INFO_USER_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingUpdateInfo: false,
      };
    case UPDATE_INFO_USER_CLIENT_FAIL:
      return {
        ...state,
        isLoadingUpdateInfo: false,
      };
    case AUTH_GET_UNIT:
      return {
        ...state,
        units: action.payload.data,
      };
    case CHANGE_PASSWORD_CLIENT:
      return {
        ...state,
      };
    case CHANGE_PASSWORD_CLIENT_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_PASSWORD_CLIENT_FAIL:
      return {
        ...state,
      };
    case SET_USER_REGISTER:
      return {
        ...state,
        isCreatedAccount: true,
      };
    case SET_USER_UNREGISTER:
      return {
        ...state,
        isCreatedAccount: false,
      };

    case GET_INFO_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
          email: action.payload.data.email
            ? decryptByAES(
                action.payload.data.email,
                process.env.NEXT_SECRET_KEY_HASH,
              )
            : null,
          dienThoai: action.payload.data.dienThoai
            ? decryptByAES(
                action.payload.data.dienThoai,
                process.env.NEXT_SECRET_KEY_HASH,
              )
            : null,
        },
      };
    default:
      return state;
  }
};

export default AuthReducer;
