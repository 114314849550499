import {
  GET_ACTIVITY_CLIENT,
  GET_ACTIVITY_CLIENT_DETAIL,
  GET_ACTIVITY_CLIENT_DETAIL_SUCCESS,
  GET_ACTIVITY_CLIENT_DETAIL_FAIL,
  GET_ACTIVITY_CLIENT_IMAGE,
} from 'shared/constants/ActionTypes';

const initialState = {
  activitiesClient: [],
  activitiesClientDetail: null,
  isLoadingActivityDetails: false,
  activitiesClientImage: null,
};

const ActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_CLIENT:
      return {
        ...state,
        activitiesClient: action.payload,
      };
    case GET_ACTIVITY_CLIENT_IMAGE:
      return {
        ...state,
        activitiesClientImage: action.payload.data,
      };
    case GET_ACTIVITY_CLIENT_DETAIL:
      return {
        ...state,
        activitiesClientDetail: {},
        isLoadingActivityDetails: true,
      };
    case GET_ACTIVITY_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        activitiesClientDetail: action.payload.data,
        isLoadingActivityDetails: false,
      };
    case GET_ACTIVITY_CLIENT_DETAIL_FAIL:
      return {
        ...state,
        activitiesClientDetail: null,
        isLoadingActivityDetails: false,
      };
    default:
      return state;
  }
};

export default ActivityReducer;
