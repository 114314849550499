import {useSelector, useDispatch} from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import styled from '@mui/styles/styled';
import TextField from '@mui/material/TextField';
import {onCountClick} from 'redux/actions/guess/Link';
import AppCard from '@common/core/AppCard';
import {Fonts} from 'shared/constants/AppEnums';
import Image from '@common/core/LazyLoadImage';
import router from 'next/router';
import Link from 'next/link';
import {Fragment} from 'react';
import {ElementSection} from 'shared/constants/AppEnums';

const TitleStyle = styled('h2')(({theme}) => ({
  width: '100%',
  textTransform: 'uppercase',
  paddingTop: theme.spacing(4),
  fontWeight: Fonts.BOLD,
  textAlign: 'center',
  display: 'inline-block',
  position: 'relative',
  paddingBottom: '10px',
  '&::after': {
    content: `""`,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '85px',
    margin: '0 auto',
    height: '3px',
    background: '#C7262E',
  },
}));

const BannerRightBar = () => {
  const dispatch = useDispatch();
  const handleRedirectLink = (e) => {
    if (!!e && !!e.link) {
      window.open(e.link, '_blank');
      dispatch(onCountClick(e.id));
    }
  };
  const dataLinksPage = useSelector(({linkApp}) => linkApp.linksClient);
  const isLoading = useSelector(({linkApp}) => linkApp.isLoadLinksClient);
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  const handleClick = (e, rs) => {
    e.preventDefault();
    window.open(rs.link);
    dispatch(onCountClick(rs.id));
  };

  return (
    <Fragment>
      <AppCard sxStyle={{height: 1}} contentStyle={{p: 0}}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            width: '100%',
            borderTop: 6,
            borderColor:
              Array.isArray(layoutClient) &&
              layoutClient.length > 0 &&
              layoutClient.filter(
                (x) =>
                  x.sectionName === ElementSection.GRAYBACKGROUND &&
                  x.isDisplay,
              ).length > 0
                ? '#494949'
                : (theme) => theme.palette.secondary.mauExtension,
          }}
        >
          {/* <TitleStyle>Liên kết website</TitleStyle> */}
          <Box
            sx={{
              py: 3,
              borderRadius: 1,
              pb: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {dataLinksPage &&
              dataLinksPage
                .filter((x) => x.isHienThiAnh)
                .map((rs, index) => {
                  return (
                    <Link key={index} href={rs.link}>
                      <a
                        target='_blank'
                        style={{marginBottom: '4px'}}
                        onClick={(e) => handleClick(e, rs)}
                      >
                        <Image alt={rs.tieuDe} src={rs.filePath} />
                      </a>
                    </Link>
                  );
                })}
          </Box>
        </Box>
      </AppCard>
      <Box
        sx={{
          mt: 1,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Autocomplete
          options={
            !!dataLinksPage && dataLinksPage.filter((x) => !x.isHienThiAnh)
          }
          size='medium'
          onChange={(event, newInputValue) => handleRedirectLink(newInputValue)}
          isOptionEqualToValue={(option, value) => {
            return option?.['link'] === value?.['link'];
          }}
          getOptionLabel={(option) => option.tieuDe}
          renderInput={(params) => (
            <TextField {...params} placeholder='Liên kết website' />
          )}
          sx={{
            '& .MuiOutlinedInput-root': {
              p: 2,
              border: '1px solid',
            },
            '& .MuiAutocomplete-tag': {
              bgcolor: 'primary.lighter',
              border: '1px solid',
              borderColor: 'primary.light',
              '& .MuiSvgIcon-root': {
                color: 'primary.main',
                '&:hover': {
                  color: 'primary.dark',
                },
              },
            },
          }}
        />
      </Box>
    </Fragment>
  );
};

BannerRightBar.propTypes = {};
export default BannerRightBar;
