const {
  GET_TEAMHCC_LIST,
  CREATE_NEW_TEAMHCC,
  UPDATE_TEAMHCC_DETAIL,
  GET_TEAMHCC_DETAIL,
  DELETE_TEAMHCC,
  GET_TEAMHCC_LIST_BY_CAP_HC,
  GET_TEAMHCC_LIST_DROP,
} = require('shared/constants/ActionTypes');

const initialState = {
  teamHCCList: [],
  teamHCCListByCHC: [],
  teamHCCDrop: [],
  selectedTeamHCC: null,
  paging: null,
  message: null,
};

const TeamHCCReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAMHCC_LIST:
      return {
        ...state,
        teamHCCList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_TEAMHCC_LIST_BY_CAP_HC:
      return {
        ...state,
        teamHCCListByCHC: action.payload.data,
      };
    case GET_TEAMHCC_LIST_DROP:
      return {
        ...state,
        teamHCCDrop: action.payload.data,
      };
    case CREATE_NEW_TEAMHCC:
      return {
        ...state,
        teamHCCList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_TEAMHCC_DETAIL:
      return {
        ...state,
        teamHCCList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_TEAMHCC: {
      return {
        ...state,
        teamHCCList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_TEAMHCC_DETAIL:
      return {
        ...state,
        selectedTeamHCC: action.payload,
      };
    default:
      return state;
  }
};

export default TeamHCCReducer;
