const {
  GET_LEADER_ACTIVITY_LIST,
  GET_LEADER_ACTIVITY_DETAIL,
  DELETE_DATA_LEADER_ACTIVITY,
  GET_LEADER_ACTIVITY_IMAGE,
} = require('shared/constants/ActionTypes');

const initialState = {
  leaderActivityList: [],
  selectedLeaderActivity: null,
  leaderActivityImage: null,
  paging: null,
  message: null,
};

const leaderActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADER_ACTIVITY_LIST:
      return {
        ...state,
        leaderActivityList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_LEADER_ACTIVITY_IMAGE:
      return {
        ...state,
        leaderActivityImage: action.payload.data,
      };
    case GET_LEADER_ACTIVITY_DETAIL:
      return {
        ...state,
        selectedLeaderActivity: action.payload.data,
      };
    case DELETE_DATA_LEADER_ACTIVITY:
      return {
        ...state,
        selectedLeaderActivity: null,
      };
    default:
      return state;
  }
};

export default leaderActivityReducer;
