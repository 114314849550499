const {
  GET_DOCUMENT_TYPE_LIST,
  GET_DOCUMENT_TYPE_DETAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  documentTypeList: [],
  selectedDocumentType: null,
  paging: null,
  message: null,
};

const documentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENT_TYPE_LIST:
      return {
        ...state,
        documentTypeList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_DOCUMENT_TYPE_DETAIL:
      return {
        ...state,
        selectedDocumentType: action.payload,
      };
    default:
      return state;
  }
};

export default documentTypeReducer;
