const {
  GET_LIST_BLOG_SUCCESS,
  GET_LIST_BLOG_FAIL,
  GET_LIST_ARTICLE_SUCCESS,
  GET_LIST_ARTICLE_FAIL,
  GET_LIST_CATEGORY_DOCUMENT_DETAIL_SUCCESS,
  GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS,
  GET_DETAILS_ART_DOCUMENT,
  GET_DETAILS_ART_DOCUMENT_SUCCESS,
  GET_DETAILS_ART_DOCUMENT_FAILS,
} = require('shared/constants/ActionTypes');

const initialState = {
  listBlog: [],
  listArticle: {},
  listArticleByCategory: [],
  selectedArticle: null,
  detailsArtDocument: [],
  isLoadingDetails: false,
  paging: null,
  message: null,
};

const lawDisseminationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_BLOG_SUCCESS:
      return {
        ...state,
        listBlog: action.payload.data,
      };
    case GET_LIST_BLOG_FAIL:
      return {
        ...state,
        listBlog: null,
      };
    case GET_LIST_ARTICLE_SUCCESS:
      return {
        ...state,
        listArticle: action.payload.data,
      };
    case GET_LIST_ARTICLE_FAIL:
      return {
        ...state,
        listArticle: null,
      };
    case GET_LIST_CATEGORY_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        listArticleByCategory: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS:
      return {
        ...state,
        listArticleByCategory: null,
      };
    case GET_DETAILS_ART_DOCUMENT:
      return {
        ...state,
        detailsArtDocument: null,
        isLoadingDetails: true,
      };
    case GET_DETAILS_ART_DOCUMENT_SUCCESS:
      return {
        ...state,
        detailsArtDocument: action.payload.data,
        isLoadingDetails: false,
      };
    case GET_DETAILS_ART_DOCUMENT_FAILS:
      return {
        ...state,
        detailsArtDocument: null,
        isLoadingDetails: false,
      };
    default:
      return state;
  }
};

export default lawDisseminationReducer;
