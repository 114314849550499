//API
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const UPDATING_CONTENT = 'UPDATING_CONTENT';
export const FETCH_ERROR_FROM_BE = 'FETCH_ERROR_FROM_BE';
export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR';
export const HIDE_RIGHT_SIDEBAR = 'HIDE_RIGHT_SIDEBAR';
export const GET_IP_USER = 'GET_IP_USER';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'TOGGLE_NAV_COLLAPSED';
export const TOGGLE_NAV_COLLAPSED_HIDE = 'TOGGLE_NAV_COLLAPSED_HIDE';
export const SET_INITIAL_PATH = 'SET_INITIAL_PATH';

//TODO-APP
export const GET_TASK_LIST = 'GET_TASK_LIST';
export const CREATE_NEW_TASK = 'CREATE_NEW_TASK';
export const TOGGLE_TODO_DRAWER = 'TOGGLE_TODO_DRAWER';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//Config App
export const GET_CONFIG_LIST = 'GET_CONFIG_LIST';
export const CREATE_NEW_CONFIG = 'CREATE_NEW_CONFIG';
export const UPDATE_CONFIG_DETAIL = 'UPDATE_CONFIG_DETAIL';
export const GET_CONFIG_DETAIL = 'GET_CONFIG_DETAIL';
export const DELETE_CONFIG = 'DELETE_CONFIG';

//Config Reflection
export const GET_CONFIG_REFLECT_LIST = 'GET_CONFIG_REFLECT_LIST';

//Category App
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const CREATE_NEW_CATEGORY = 'CREATE_NEW_CATEGORY';
export const UPDATE_CATEGORY_DETAIL = 'UPDATE_CATEGORY_DETAIL';
export const GET_CATEGORY_DETAIL = 'GET_CATEGORY_DETAIL';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const GET_CATEGORY_PARENT = 'GET_CATEGORY_PARENT';
export const GET_LIST_IMAGE = 'GET_LIST_IMAGE';

//Category app tree home
export const GET_TREE_CATEGORY = 'GET_TREE_CATEGORY';
export const LOAD_TREE_CATEGORY = 'LOAD_TREE_CATEGORY';
export const LOAD_TREE_CATEGORY_SUCCESS = 'LOAD_TREE_CATEGORY_SUCCESS';
export const LOAD_TREE_CATEGORY_FAIL = 'LOAD_TREE_CATEGORY_FAIL';

//Category chilrent app tree home
export const GET_TREE_CHILDRENT_CATEGORY = 'GET_TREE_CHILDRENT_CATEGORY';
export const LOAD_TREE_CHILDRENT_CATEGORY = 'LOAD_TREE_CHILDRENT_CATEGORY';
export const LOAD_TREE_CHILDRENT_CATEGORY_SUCCESS =
  'LOAD_TREE_CHILDRENT_CATEGORY_SUCCESS';
export const LOAD_TREE_CHILDRENT_CATEGORY_FAIL =
  'LOAD_TREE_CHILDRENT_CATEGORY_FAIL';

export const LOAD_BOOLEAN_DETAIL_HOME = 'LOAD_BOOLEAN_DETAIL_HOME';

// Category mới nhất
export const GET_LIST_NEW_CATEGORY = 'GET_LIST_NEW_CATEGORY';
export const LOAD_LIST_NEW_CATEGORY = 'LOAD_LIST_NEW_CATEGORY';
export const LOAD_LIST_NEW_CATEGORY_SUCCESS = 'LOAD_LIST_NEW_CATEGORY_SUCCESS';
export const LOAD_LIST_NEW_CATEGORY_FAIL = 'LOAD_LIST_NEW_CATEGORY_FAIL';

// Banner Home page
export const GET_LIST_BANNER_CATEGORY = 'GET_LIST_BANNER_CATEGORY';
export const LOAD_LIST_BANNER_CATEGORY = 'LOAD_LIST_BANNER_CATEGORY';
export const LOAD_LIST_BANNER_CATEGORY_SUCCESS =
  'LOAD_LIST_BANNER_CATEGORY_SUCCESS';
export const LOAD_LIST_BANNER_CATEGORY_FAIL = 'LOAD_LIST_BANNER_CATEGORY_FAIL';

// Category xem nhiều nhất
export const GET_LIST_VIEWS_CATEGORY = 'GET_LIST_VIEWS_CATEGORY';
export const LOAD_LIST_VIEWS_CATEGORY = 'LOAD_LIST_VIEWS_CATEGORY';
export const LOAD_LIST_VIEWS_CATEGORY_SUCCESS =
  'LOAD_LIST_VIEWS_CATEGORY_SUCCESS';
export const LOAD_LIST_VIEWS_CATEGORY_FAIL = 'LOAD_LIST_VIEWS_CATEGORY_FAIL';

//GET DETAIL NEW Category chilrent app  home
export const GET_DETAIL_NEW_CHILDRENT_CATEGORY =
  'GET_DETAIL_NEW_CHILDRENT_CATEGORY';
export const LOAD_DETAIL_NEW_CHILDRENT_CATEGORY =
  'LOAD_DETAIL_NEW_CHILDRENT_CATEGORY';
export const LOAD_DETAIL_NEW_CHILDRENT_CATEGORY_SUCCESS =
  'LOAD_DETAIL_NEW_CHILDRENT_CATEGORY_SUCCESS';
export const LOAD_DETAIL_NEW_CHILDRENT_CATEGORY_FAIL =
  'LOAD_DETAIL_NEW_CHILDRENT_CATEGORY_FAIL';

//GET phone book app  home
export const GET_PHONE_BOOK_CATEGORY = 'GET_PHONE_BOOK_CATEGORY';
export const LOAD_PHONE_BOOK_CATEGORY = 'LOAD_PHONE_BOOK_CATEGORY';
export const LOAD_PHONE_BOOK_SUCCESS = 'LOAD_PHONE_BOOK_SUCCESS';
export const LOAD_PHONE_BOOK_FAIL = 'LOAD_PHONE_BOOK_FAIL';

//Config Question App
export const GET_CONFIG_QUESTION_LIST = 'GET_CONFIG_QUESTION_LIST';

//Leader Activity App
export const GET_LEADER_ACTIVITY_LIST = 'GET_LEADER_ACTIVITY_LIST';
export const GET_LEADER_ACTIVITY_DETAIL = 'GET_LEADER_ACTIVITY_DETAIL';
export const DELETE_DATA_LEADER_ACTIVITY = 'DELETE_DATA_LEADER_ACTIVITY';
export const GET_LEADER_ACTIVITY_IMAGE = 'GET_LEADER_ACTIVITY_IMAGE';

//Article App
export const GET_ARTICLE_LIST = 'GET_ARTICLE_LIST';
export const CREATE_NEW_ARTICLE = 'CREATE_NEW_ARTICLE';
export const UPDATE_ARTICLE_DETAIL = 'UPDATE_ARTICLE_DETAIL';
export const GET_ARTICLE_DETAIL = 'GET_ARTICLE_DETAIL';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const ARTICLE_GET_PARENT = 'ARTICLE_GET_PARENT';
export const ARTICLE_GET_CATEGORY = 'ARTICLE_GET_CATEGORY';
export const ARTICLE_GET_LIST_PARENT = 'ARTICLE_GET_LIST_PARENT';
export const ARTICLE_PUBLISHER = 'ARTICLE_PUBLISHER';
export const ARTICLE_GET_TREE_CATEGORY = 'ARTICLE_GET_TREE_CATEGORY';
export const DELETE_DATA_ARTICLE = 'DELETE_DATA_ARTICLE';

//Banner App
export const GET_BANNER_LIST = 'GET_BANNER_LIST';
export const CREATE_NEW_BANNER = 'CREATE_NEW_BANNER';
export const UPDATE_BANNER_DETAIL = 'UPDATE_BANNER_DETAIL';
export const GET_BANNER_DETAIL = 'GET_BANNER_DETAIL';
export const DELETE_BANNER = 'DELETE_BANNER';
export const GET_BANNER_POSITION = 'GET_BANNER_POSITION';

//Slogan App
export const GET_DATA_SLOGAN = 'GET_DATA_SLOGAN';
export const GET_DATA_SLOGAN_CLIENT = 'GET_DATA_SLOGAN_CLIENT';

//Lead Cadres App
export const GET_LEAD_CADRES_LIST = 'GET_LEAD_CADRES_LIST';
export const GET_LEAD_CADRES_DETAIL = 'GET_LEAD_CADRES_DETAIL';

//Contact App
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const DELETE_CONTACT = 'DELETE_CONTACT';

//Unit App
export const GET_UNIT_LIST = 'GET_UNIT_LIST';
export const CREATE_NEW_UNIT = 'CREATE_NEW_UNIT';
export const UPDATE_UNIT_DETAIL = 'UPDATE_UNIT_DETAIL';
export const GET_UNIT_DETAIL = 'GET_UNIT_DETAIL';
export const DELETE_UNIT = 'DELETE_UNIT';
export const GET_UNIT_LIST_DROP = 'GET_UNIT_LIST_DROP';
export const GET_UNIT_CAP_HC = 'GET_UNIT_CAP_HC';

//Link App
export const GET_LINK_LIST = 'GET_LINK_LIST';
export const CREATE_NEW_LINK = 'CREATE_NEW_LINK';
export const UPDATE_LINK_DETAIL = 'UPDATE_LINK_DETAIL';
export const GET_LINK_DETAIL = 'GET_LINK_DETAIL';
export const DELETE_LINK = 'DELETE_LINK';

//Online Inquiry App
export const GET_ONLINE_INQUIRY_LIST = 'GET_ONLINE_INQUIRY_LIST';
export const LIST_FIELDS = 'LIST_FIELDS';
export const GET_ONLINE_INQUIRY_DETAIL = 'GET_ONLINE_INQUIRY_DETAIL';
export const DELETE_DATA_ONLINE_INQUIRY = 'DELETE_DATA_ONLINE_INQUIRY';
export const GET_LIST_ASSIGNMENT = 'GET_LIST_ASSIGNMENT';

//SnackBar
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

//Media Library App
export const GET_MEDIA_LIBRARY_LIST = 'GET_MEDIA_LIBRARY_LIST';

//HOME PAGE
export const GET_NEWS_LIST = 'GET_NEWS_LIST';
export const GET_RELATED_NEWS_LIST = 'GET_RELATED_NEWS_LIST';

//Reflect App
export const GET_REFLECT_LIST = 'GET_REFLECT_LIST';
export const GET_REFLECT_DETAIL = 'GET_REFLECT_DETAIL';
export const REFLECT_GET_FIELD = 'REFLECT_GET_FIELD';
export const RECEIVE_REFELECT = 'RECEIVE_REFELECT';
export const REJECT_REFLECT = 'REJECT_REFLECT';
export const GET_STATUS = 'GET_STATUS';
export const GET_UNIT = 'GET_UNIT';
export const SEND_REFLECT = 'SEND_REFLECT';
export const SENT_REFLECT = 'SENT_REFLECT';
export const GET_EXCEL_REFLECT = 'GET_EXCEL_REFLECT';
export const GET_REFLECT_CONFIG = 'GET_REFLECT_CONFIG';
export const GET_REFLECT_REPORT = 'GET_REFLECT_REPORT';

//Reflection field
export const GET_REFLECTION_FIELD_LIST = 'GET_REFLECTION_FIELD_LIST';

//Detail Footer
export const GET_DATA_FOOTER = 'GET_DATA_FOOTER';
export const GET_DATA_FOOTER_FAIL = 'GET_DATA_FOOTER_FAIL';

//Customer App
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST';
export const GET_CUSTOMER_DETAIL = 'GET_CUSTOMER_DETAIL';

//Email Server App
export const GET_EMAIL_SERVER_LIST = 'GET_CUSTOMER_LIST';

//Layout App
export const GET_LAYOUT_LIST = 'GET_LAYOUT_LIST';
export const GET_LAYOUT_DETAIL = 'GET_LAYOUT_DETAIL';

export const GET_LAYOUT_CLIENT = 'GET_LAYOUT_CLIENT';

//Evaluation Criteria App

export const GET_CRITERIA_LIST = 'GET_CRITERIA_LIST';
export const CREATE_NEW_CRITERIA = 'CREATE_NEW_CRITERIA';
export const UPDATE_CRITERIA_DETAIL = 'UPDATE_CRITERIA_DETAIL';
export const GET_CRITERIA_DETAIL = 'GET_CRITERIA_DETAIL';
export const DELETE_CRITERIA = 'DELETE_CRITERIA';

//Evaluation Level App

export const GET_LEVEL_LIST = 'GET_LEVEL_LIST';
export const CREATE_NEW_LEVEL = 'CREATE_NEW_LEVEL';
export const UPDATE_LEVEL_DETAIL = 'UPDATE_LEVEL_DETAIL';
export const GET_LEVEL_DETAIL = 'GET_LEVEL_DETAIL';
export const DELETE_LEVEL = 'DELETE_LEVEL';

//Evaluation Device App

export const GET_DEVICE_LIST = 'GET_DEVICE_LIST';
export const CREATE_NEW_DEVICE = 'CREATE_NEW_DEVICE';
export const UPDATE_DEVICE_DETAIL = 'UPDATE_DEVICE_DETAIL';
export const GET_DEVICE_DETAIL = 'GET_DEVICE_DETAIL';
export const DELETE_DEVICE = 'DELETE_DEVICE';

//Evaluation Unit TTHC App

export const GET_UNITTTHC_LIST = 'GET_UNITTTHC_LIST';
export const CREATE_NEW_UNITTTHC = 'CREATE_NEW_UNITTTHC';
export const UPDATE_UNITTTHC_DETAIL = 'UPDATE_UNITTTHC_DETAIL';
export const GET_UNITTTHC_DETAIL = 'GET_UNITTTHC_DETAIL';
export const DELETEUNIT_TTHC = 'DELETE_UNITTTHC';
export const GET_UNITTTHC_LIST_CAP_HC = 'GET_UNITTTHC_LIST_CAP_HC';

//Evaluation Team HCC App

export const GET_TEAMHCC_LIST = 'GET_TEAMHCC_LIST';
export const CREATE_NEW_TEAMHCC = 'CREATE_NEW_TEAMHCC';
export const UPDATE_TEAMHCC_DETAIL = 'UPDATE_TEAMHCC_DETAIL';
export const GET_TEAMHCC_DETAIL = 'GET_TEAMHCC_DETAIL';
export const DELETE_TEAMHCC = 'DELETE_TEAMHCC';
export const GET_TEAMHCC_LIST_BY_CAP_HC = 'GET_TEAMHCC_LIST_BY_CAP_HC';
export const GET_TEAMHCC_LIST_DROP = 'GET_TEAMHCC_LIST_DROP';

//Evaluation Report

export const GET_REPORT_BY_UNIT = 'GET_REPORT_BY_UNIT';
export const GET_REPORT_BY_TIME = 'GET_REPORT_BY_TIME';
export const GET_REPORT_BY_ACCESS = 'GET_REPORT_BY_ACCESS';
export const GET_REPORT_BY_CRITERIA = 'GET_REPORT_BY_CRITERIA';
export const GET_REPORT_BY_QR = 'GET_REPORT_BY_QR';

//

export const SEND_EVALUATION = 'SEND_EVALUATION';
export const GET_EVL_LIST_TTHC = 'GET_EVL_LIST_TTHC';
export const GET_EVL_LIST_TTHC_FAILD = 'GET_EVL_LIST_TTHC_FAILD';
export const SEND_EVL_SUCCESS = 'SEND_EVL_SUCCESS';
export const SEND_EVL_FAIL = 'SEND_EVL_FAIL';
export const GET_LIST_EVL = 'GET_LIST_EVL';
export const GET_EVL_DETAIL = 'GET_EVL_DETAIL';
export const GET_EVL_DETAIL_FAIL = 'GET_EVL_DETAIL_FAIL';
export const GET_EVL_HCC_DROPDOWN = 'GET_EVL_HCC_DROPDOWN';
export const GET_EVL_CONFIG = 'GET_EVL_CONFIG';
export const GET_EVL_QR = 'GET_EVL_QR';

// ==============================|| CLIENT ACTION ||============================== //
export const GET_BANNER_CLIENT_LIST = 'GET_BANNER_CLIENT_LIST';
export const GET_BANNER_CLIENT_LIST_SUCCESS = 'GET_BANNER_CLIENT_LIST_SUCCESS';
export const GET_BANNER_CLIENT_LIST_FAIL = 'GET_BANNER_CLIENT_LIST_FAIL';

export const GET_DETAILS_ARTICLE_CLIENT = 'GET_DETAILS_ARTICLE_CLIENT';
export const GET_DETAILS_ARTICLE_CLIENT_SUCCESS =
  'GET_DETAILS_ARTICLE_CLIENT_SUCCESS';
export const GET_DETAILS_ARTICLE_CLIENT_FAIL =
  'GET_DETAILS_ARTICLE_CLIENT_FAIL';

export const GET_LIST_ARTICLE_BY_CAT_CLIENT = 'GET_LIST_ARTICLE_BY_CAT_CLIENT';
export const GET_LIST_ARTICLE_BY_CAT_CLIENT_SUCCESS =
  'GET_LIST_ARTICLE_BY_CAT_CLIENT_SUCCESS';
export const GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS =
  'GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS';

export const GET_CONTACT_CLIENT = 'GET_CONTACT_CLIENT';
export const GET_CONTACT_CLIENT_SUCCESS = 'GET_CONTACT_CLIENT_SUCCESS';
export const GET_CONTACT_CLIENT_FAIL = 'GET_CONTACT_CLIENT_FAIL';

export const GET_LATEST_ARTICLE_CLIENT = 'GET_LATEST_ARTICLE_CLIENT';
export const GET_LATEST_ARTICLE_CLIENT_SUCCESS =
  'GET_LATEST_ARTICLE_CLIENT_SUCCESS';
export const GET_LATEST_ARTICLE_CLIENT_FAIL = 'GET_LATEST_ARTICLE_CLIENT_FAIL';

export const GET_MOST_VIEW_ARTICLE_CLIENT = 'GET_MOST_VIEW_ARTICLE_CLIENT';
export const GET_MOST_VIEW_ARTICLE_CLIENT_SUCCESS =
  'GET_MOST_VIEW_ARTICLE_CLIENT_SUCCESS';
export const GET_MOST_VIEW_ARTICLE_CLIENT_FAIL =
  'GET_MOST_VIEW_ARTICLE_CLIENT_FAIL';

export const GET_FEATURED_ARTICLE_CLIENT = 'GET_FEATURED_ARTICLE_CLIENT';
export const GET_FEATURED_ARTICLE_CLIENT_SUCCESS =
  'GET_FEATURED_ARTICLE_CLIENT_SUCCESS';
export const GET_FEATURED_ARTICLE_CLIENT_FAIL =
  'GET_FEATURED_ARTICLE_CLIENT_FAIL';

export const GET_ARTICLES_CLIENT = 'GET_ARTICLES_CLIENT';
export const GET_ARTICLES_CLIENT_SUCCESS = 'GET_ARTICLES_CLIENT_SUCCESS';
export const GET_ARTICLES_CLIENT_FAIL = 'GET_ARTICLES_CLIENT_FAIL';

export const GET_LIST_BLOG_SUCCESS = 'GET_LIST_BLOG_SUCCESS';
export const GET_LIST_BLOG_FAIL = 'GET_LIST_BLOG_FAIL';

//GET Links page Category  home
export const GET_LINKS_CLIENT = 'GET_LINKS_CLIENT';
export const GET_LINKS_CLIENT_SUCCESS = 'GET_LINKS_CLIENT_SUCCESS';
export const GET_LINKS_CLIENT_FAIL = 'GET_LINKS_CLIENT_FAIL';

//GET Links page Category  home
export const GET_TAGS_CLIENT = 'GET_TAGS_CLIENT';
export const GET_TAGS_CLIENT_SUCCESS = 'GET_TAGS_CLIENT_SUCCESS';
export const GET_TAGS_CLIENT_FAIL = 'GET_TAGS_CLIENT_FAIL';

export const REFLECT_PROCESS = 'REFLECT_PROCESS';
export const REFLECT_PROCESSED = 'REFLECT_PROCESSED';

export const GET_QUESTION_CLIENT = 'GET_QUESTION_CLIENT';
export const GET_QUESTION_LIST = 'GET_QUESTION_LIST';
export const GET_QUESTION_NEED_PROCESS = 'GET_QUESTION_NEED_PROCESS';
export const GET_QUESTION_NEED_PROCESS_CLIENT =
  'GET_QUESTION_NEED_PROCESS_CLIENT';
export const GET_QUESTION_NEED_PROCESS_CLIENT_FAIL =
  'GET_QUESTION_NEED_PROCESS_CLIENT_FAIL';
export const GET_QUESTION_NEED_PROCESS_FAIL = 'GET_QUESTION_NEED_PROCESS_FAIL';
export const QUESTION_GET_FIELD = 'QUESTION_GET_FIELD';
export const QUESTION_GET_CAP_HC_BY_FIELD = 'QUESTION_GET_CAP_HC_BY_FIELD';
export const GET_QUESTION_CLIENT_FAIL = 'GET_QUESTION_CLIENT_FAIL';
export const GET_QUESTION_DETAIL_CLIENT = 'GET_QUESTION_DETAIL_CLIENT';
export const GET_QUESTION_DETAIL = 'GET_QUESTION_DETAIL';
export const GET_QUESTION_FIELD_CLIENT_FAIL = 'GET_QUESTION_FIELD_CLIENT_FAIL';
export const QUESTION_GET_CAP_HC_CLIENT_FAIL =
  'QUESTION_GET_CAP_HC_CLIENT_FAIL';
export const GET_QUESTION_DETAIL_CLIENT_FAIL =
  'GET_QUESTION_DETAIL_CLIENT_FAIL';
export const SENT_QUESTION = 'SENT_QUESTION';
export const SEND_QUESTION = 'SEND_QUESTION';
export const GET_MOST_VIEW = 'GET_MOST_VIEW';
export const GET_CLIENT_MOST_VIEW_FIELD = 'GET_CLIENT_MOST_VIEW_FIELD';
export const QUESTION_ANSWERED = 'QUESTION_ANSWERED';
export const QUESTION_ANSWER = 'QUESTION_ANSWER';
export const GET_REFLECT_CLIENT = 'GET_REFLECT_CLIENT';
export const GET_REFLECT_DETAIL_CLIENT = 'GET_REFLECT_DETAIL_CLIENT';
export const GET_REFLECT_CLIENT_SUCCESS = 'GET_REFLECT_CLIENT_SUCCESS';
export const GET_REFLECT_CLIENT_FAIL = 'GET_REFLECT_CLIENT_FAIL';
export const GET_REFLECT_DETAIL_CLIENT_FAIL = 'GET_REFLECT_DETAIL_CLIENT_FAIL';
export const GET_REFLECT_FIELD_CLIENT_FAIL = 'GET_REFLECT_FIELD_CLIENT_FAIL';
export const GET_REFLECT_STATUS_CLIENT_FAIL = 'GET_REFLECT_STATUS_CLIENT_FAIL';
export const GET_LIST_ART_PAGINATION = 'GET_LIST_ART_PAGINATION';
export const GET_LIST_ART_PAGINATION_SUCCESS =
  'GET_LIST_ART_PAGINATION_SUCCESS';
export const GET_LIST_ART_PAGINATION_FAILS = 'GET_LIST_ART_PAGINATION_FAILS';

export const GET_REFLECT_NOTIFICATION = 'GET_REFLECT_NOTIFICATION';
export const GET_REFLECT_NOTI_NO_FILTER = 'GET_REFLECT_NOTI_NO_FILTER';
export const GET_MY_REFLECTION = 'GET_MY_REFLECTION';

export const UPDATE_INFO_USER_CLIENT = 'UPDATE_INFO_USER_CLIENT';
export const UPDATE_INFO_USER_CLIENT_SUCCESS =
  'UPDATE_INFO_USER_CLIENT_SUCCESS';
export const UPDATE_INFO_USER_CLIENT_FAIL = 'UPDATE_INFO_USER_CLIENT_FAIL';

export const CHANGE_PASSWORD_CLIENT = 'CHANGE_PASSWORD_CLIENT';
export const CHANGE_PASSWORD_CLIENT_SUCCESS = 'CHANGE_PASSWORD_CLIENT_SUCCESS';
export const CHANGE_PASSWORD_CLIENT_FAIL = 'CHANGE_PASSWORD_CLIENT_FAIL';

export const GET_VIDEO_ARTICLE_CLIENT = 'GET_VIDEO_ARTICLE_CLIENT';
export const GET_VIDEO_ARTICLE_CLIENT_SUCCESS =
  'GET_VIDEO_ARTICLE_CLIENT_SUCCESS';
export const GET_VIDEO_ARTICLE_CLIENT_FAIL = 'GET_VIDEO_ARTICLE_CLIENT_FAIL';

export const GETALL_VIDEO_ARTICLE_CLIENT = 'GETALL_VIDEO_ARTICLE_CLIENT';
export const GETALL_VIDEO_ARTICLE_CLIENT_SUCCESS =
  'GETALL_VIDEO_ARTICLE_CLIENT_SUCCESS';
export const GETALL_VIDEO_ARTICLE_CLIENT_FAIL =
  'GETALL_VIDEO_ARTICLE_CLIENT_FAIL';

export const GET_DETAILS_ARTICLE_VIDEO_CLIENT =
  'GET_DETAILS_ARTICLE_VIDEO_CLIENT';
export const GET_DETAILS_ARTICLE_VIDEO_CLIENT_SUCCESS =
  'GET_DETAILS_ARTICLE_VIDEO_CLIENT_SUCCESS';
export const GET_DETAILS_ARTICLE_VIDEO_CLIENT_FAIL =
  'GET_DETAILS_ARTICLE_VIDEO_CLIENT_FAIL';

export const SIGN_UP_CLIENT = 'SIGN_UP_CLIENT';
export const SIGN_UP_CLIENT_SUCCESS = 'SIGN_UP_CLIENT_SUCCESS';
export const SIGN_UP_CLIENT_FAIL = 'SIGN_UP_CLIENT_FAIL';

export const GET_ANALYTICS_VIEW_CLIENT = 'GET_ANALYTICS_VIEW_CLIENT';

export const GET_COUNT_APPROVAL = 'GET_COUNT_APPROVAL';

export const GET_STAFF_LIST = 'GET_STAFF_LIST';
export const GET_DETAIL_INTRODUCE = 'GET_DETAIL_INTRODUCE';

export const GET_DETAIL_APPROVAL = 'GET_DETAIL_APPROVAL';

export const APPROVAL_ARTICLE = 'APPROVAL_ARTICLE';

export const GET_ACTIVITY_CLIENT = 'GET_ACTIVITY_CLIENT';
export const GET_ACTIVITY_CLIENT_DETAIL = 'GET_ACTIVITY_CLIENT_DETAIL';
export const GET_ACTIVITY_CLIENT_DETAIL_SUCCESS =
  'GET_ACTIVITY_CLIENT_DETAIL_SUCCESS';
export const GET_ACTIVITY_CLIENT_DETAIL_FAIL =
  'GET_ACTIVITY_CLIENT_DETAIL_FAIL';
export const GET_ACTIVITY_CLIENT_IMAGE = 'GET_ACTIVITY_CLIENT_IMAGE';

// ==============================|| ADMINISTRATIVE PROCEDURE ACTION ||============================== //
export const GET_PROCEDURE_LIST = 'GET_PROCEDURE_LIST';
export const CREATE_NEW_PROCEDURE = 'CREATE_NEW_PROCEDURE';
export const UPDATE_PROCEDURE_DETAIL = 'UPDATE_PROCEDURE_DETAIL';
export const GET_PROCEDURE_DETAIL = 'GET_PROCEDURE_DETAIL';
export const DELETE_PROCEDURE = 'DELETE_PROCEDURE';
export const GET_LIST_POLICE_LEVEL = 'GET_LIST_POLICE_LEVEL';

export const GET_POLICE_LIST = 'GET_POLICE_LIST';
export const CREATE_NEW_POLICE = 'CREATE_NEW_POLICE';
export const UPDATE_POLICE_DETAIL = 'UPDATE_POLICE_DETAIL';
export const GET_POLICE_DETAIL = 'GET_POLICE_DETAIL';
export const DELETE_POLICE = 'DELETE_POLICE';
export const GET_LIST_LEVEL = 'GET_LIST_LEVEL';
export const GET_LIST_PROCEDURE = 'GET_LIST_PROCEDURE';

export const GET_ARTICLE_PROCEDURE_LIST = 'GET_ARTICLE_PROCEDURE_LIST';
export const DELETE_ARTICLE_PROCEDURE = 'DELETE_ARTICLE_PROCEDURE';
export const GET_ARTICLE_PROCEDURE_DETAIL = 'GET_ARTICLE_PROCEDURE_DETAIL';
export const CREATE_NEW_ARTICLE_PROCEDURE = 'CREATE_NEW_ARTICLE_PROCEDURE';
export const UPDATE_CONFIG_ARTICLE_PROCEDURE =
  'UPDATE_CONFIG_ARTICLE_PROCEDURE';
export const ARTICLE_PROCEDURE_PUBLISHER = 'ARTICLE_PROCEDURE_PUBLISHER';
export const DELETE_DATA_ARTICLE_PROCEDURE = 'DELETE_DATA_ARTICLE_PROCEDURE';
export const LIST_ADMINISTRATIVE_PAPER = 'LIST_ADMINISTRATIVE_PAPER';
export const GET_PAPER_PROCEDURE_LIST = 'GET_PAPER_PROCEDURE_LIST';
export const GET_PAPER_PROCEDURE_DETAIL = 'GET_PAPER_PROCEDURE_DETAIL';

// ==============================|| USER ACTION ||============================== //
export const GET_USER_LIST = 'GET_USER_LIST';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const DELETE_USER = 'DELETE_USER';

export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const CREATE_NEW_ROLE = 'CREATE_NEW_ROLE';
export const UPDATE_ROLE_DETAIL = 'UPDATE_ROLE_DETAIL';
export const GET_ROLE_DETAIL = 'GET_ROLE_DETAIL';
export const DELETE_ROLE = 'DELETE_ROLE';
export const SET_VALUE_ROLE = 'SET_VALUE_ROLE';

export const CHANGE_SELECTED_ALL_PERMISSION = 'CHANGE_SELECTED_ALL_PERMISSION';
export const CHANGE_SELECTED_PERMISSION = 'CHANGE_SELECTED_PERMISSION';

export const GET_CREATE_OBJ_ROLE = 'GET_CREATE_OBJ_ROLE';

export const GET_ROLE_LIST_BY_USER = 'GET_ROLE_LIST_BY_USER';

export const GET_JOB_LIST = 'GET_JOB_LIST';
export const GET_JOB_LIST_BY_USER = 'GET_JOB_LIST_BY_USER';
export const UPDATE_JOB_USER = 'UPDATE_JOB_USER';

export const GET_PERMISSION_LIST = 'GET_PERMISSION_LIST';
export const CREATE_NEW_PERMISSION = 'CREATE_NEW_PERMISSION';
export const UPDATE_PERMISSION_DETAIL = 'UPDATE_PERMISSION_DETAIL';
export const GET_PERMISSION_DETAIL = 'GET_PERMISSION_DETAIL';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';

// ==============================|| AUTH ACTION ||============================== //

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const LOGOUT = 'LOGOUT';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const REQUEST_NEW_PASSWORD = 'REQUEST_NEW_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const AUTH_GET_UNIT = 'AUTH_GET_UNIT';

export const SET_USER_REGISTER = 'SET_USER_REGISTER';
export const SET_USER_UNREGISTER = 'SET_USER_UNREGISTER';
export const GET_INFO_USER = 'GET_INFO_USER';

// ==============================|| LAW DISSEMINATION ||============================== //
export const GET_DOCUMENT_TYPE_LIST = 'GET_DOCUMENT_TYPE_LIST';
export const GET_DOCUMENT_TYPE_DETAIL = 'GET_DOCUMENT_TYPE_DETAIL';

export const GET_AGENCY_ISSUED_LIST = 'GET_AGENCY_ISSUED_LIST';
export const GET_AGENCY_ISSUED_DETAIL = 'GET_AGENCY_ISSUED_DETAIL';

export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';
export const GET_DOCUMENT_DETAIL = 'GET_DOCUMENT_DETAIL';
export const LIST_PUBLISHER_DOCUMENT = 'LIST_PUBLISHER_DOCUMENT';
export const LIST_AGENCY_ISSUED = 'LIST_AGENCY_ISSUED';
export const LIST_DOCUMENT_TYPE = 'LIST_DOCUMENT_TYPE';
export const LIST_CATEGORY_DOCUMENT = 'LIST_CATEGORY_DOCUMENT';
export const DELETE_DATA_DOCUMENT = 'DELETE_DATA_DOCUMENT';

export const GET_LIST_ARTICLE_SUCCESS = 'GET_LIST_ARTICLE_SUCCESS';
export const GET_LIST_ARTICLE_FAIL = 'GET_LIST_ARTICLE_FAIL';
export const GET_LIST_CATEGORY_DOCUMENT_DETAIL_SUCCESS =
  'GET_LIST_CATEGORY_DOCUMENT_DETAIL_SUCCESS';
export const GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS =
  'GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS';
export const GET_DETAILS_ART_DOCUMENT = 'GET_DETAILS_ART_DOCUMENT';
export const GET_DETAILS_ART_DOCUMENT_SUCCESS =
  'GET_DETAILS_ART_DOCUMENT_SUCCESS';
export const GET_DETAILS_ART_DOCUMENT_FAILS = 'GET_DETAILS_ART_DOCUMENT_FAILS';

// SECURITY
export const GET_SECURITY = 'GET_SECURITY';
export const GET_SECURITY_CLIENT = 'GET_SECURITY_CLIENT';
