export const URL_LOGIN = `/tai-khoan/dang-nhap`;
export const URL_LOGOUT = `/tai-khoan/dang-xuat`;
export const URL_CHANGE_PASSWORD = `/tai-khoan/doi-mat-khau`;
export const URL_FORGET_PASSWORD = `/tai-khoan/quen-mat-khau`;
export const URL_CONFIRM_PASSWORD = `/tai-khoan/xac-thuc-quen-mat-khau`;
export const URL_VALID_TOKEN = `/tai-khoan/xac-thuc-tai-khoan`;
export const URL_USER_INFO = '/nguoi-dung/thong-tin-ca-nhan';
export const URL_REFRESH_TOKEN = '/tai-khoan/gia-han-token';
export const URL_CHECK_TOKEN_PASSWORD = '/tai-khoan/xac-thuc-quen-mat-khau';
export const URL_RESET_PASSWORD = `/tai-khoan/dat-lai-mat-khau`;
