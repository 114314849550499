const {
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_DETAIL,
  GET_DATA_FOOTER,
  GET_DATA_FOOTER_FAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  customerList: [],
  selectedCustomer: null,
  dataFooter: {},
  paging: null,
  message: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_CUSTOMER_DETAIL:
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    case GET_DATA_FOOTER:
      return {
        ...state,
        dataFooter: action.payload.data,
      };
    case GET_DATA_FOOTER_FAIL:
      return {
        ...state,
        // dataFooter: {},
      };

    default:
      return state;
  }
};

export default customerReducer;
