const {
  GET_LAYOUT_LIST,
  GET_LAYOUT_DETAIL,
  GET_LAYOUT_CLIENT,
} = require('shared/constants/ActionTypes');

const initialState = {
  layoutList: [],
  selectedLayout: null,
  paging: null,
  message: null,
  // layoutClient: [],
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAYOUT_LIST:
      return {
        layoutList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_LAYOUT_DETAIL:
      return {
        ...state,
        selectedLayout: action.payload,
      };
    // case GET_LAYOUT_CLIENT:
    //   return {
    //     ...state,
    //     layoutClient: action.payload.data,
    //   };
    default:
      return state;
  }
};

export default layoutReducer;
