import Image from 'next/image';
import parse, {domToReact} from 'html-react-parser';
import Link from 'next/link';
import React, {Fragment} from 'react';
import AppAudioPlayer from '@common/core/AppAudioPlayer';
import AppVideoPlayer from '@common/core/AppVideoPlayer';
import Box from '@mui/material/Box';

export default function parseHtml(
  html,
  listImage,
  setOpenLightbox,
  setSelectedImage,
  setListImage,
) {
  const handleOpenLightbox = (url) => {
    if (!listImage || !setOpenLightbox || !setSelectedImage || !setListImage)
      return;
    const selectedImage = listImage.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const options = {
    replace: ({name, attribs, children}) => {
      if (name === '&nbsp;') return <></>;
      if (name === 'img') {
        const {src, alt, width, height, title} = attribs;
        return (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{cursor: 'pointer', '& > img': {borderRadius: '4px'}}}
          >
            <Image
              src={src}
              width={width || 450}
              height={height || 350}
              alt={alt}
              title={title}
              placeholder='blur'
              blurDataURL={'/assets/images/placeholder.png'}
              onClick={() => handleOpenLightbox(src)}
            />
          </Box>
        );
      }
      if (name === 'video') {
        return <AppVideoPlayer url={children[1].attribs.src} />;
      }
      if (name === 'audio') {
        return (
          <Box sx={{py: 4}}>
            <AppAudioPlayer
              src={attribs.src}
              inline
              display='waveform'
              waveColor='#C7262E'
              containerWidth={400}
              playPauseIconButtonProps={{
                sx: {
                  boxShadow: 'none',
                  backgroundColor: (theme) =>
                    theme.palette.secondary.mauExtension,
                  color: (theme) => theme.palette.primary.contrastText,
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.dark,
                  },
                },
              }}
            />
          </Box>
        );
      }

      if (name === 'table') {
        return domToReact(children, options);
      }

      // Convert internal links to Next.js Link components.
      const isInternalLink =
        (name === 'a' && attribs['data-internal-link'] === 'true') ||
        attribs?.href?.includes(process.env.SITE_URL);

      if (isInternalLink) {
        return (
          <Link href={attribs.href}>
            <a {...attribs}>{domToReact(children, options)}</a>
          </Link>
        );
      }
    },
  };

  return <Fragment>{parse(html, options)}</Fragment>;
}
