const {
  GET_ONLINE_INQUIRY_LIST,
  GET_ONLINE_INQUIRY_DETAIL,
  LIST_FIELDS,
  DELETE_DATA_ONLINE_INQUIRY,
  GET_LIST_ASSIGNMENT,
  QUESTION_GET_CAP_HC_BY_FIELD,
  QUESTION_GET_CAP_HC_CLIENT_FAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  onlineInquiryList: [],
  selectedOnlineInquiry: null,
  paging: null,
  message: null,
  listFields: [],
  listAssignment: [],
  listQuestionCapHC: [],
};

const onlineInquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_INQUIRY_LIST:
      return {
        ...state,
        onlineInquiryList: action.payload.data,
        paging: action.payload.paging,
      };
    case QUESTION_GET_CAP_HC_BY_FIELD:
      return {
        ...state,
        listQuestionCapHC: action.payload.data,
      };
    case QUESTION_GET_CAP_HC_CLIENT_FAIL:
      return {
        ...state,
        listQuestionCapHC: [],
      };
    case GET_ONLINE_INQUIRY_DETAIL:
      return {
        ...state,
        selectedOnlineInquiry: action.payload.data,
      };
    case LIST_FIELDS:
      return {
        ...state,
        listFields: action.payload.data,
      };
    case DELETE_DATA_ONLINE_INQUIRY:
      return {
        ...state,
        selectedOnlineInquiry: null,
      };
    case GET_LIST_ASSIGNMENT:
      return {
        ...state,
        listAssignment: action.payload.data,
      };
    default:
      return state;
  }
};

export default onlineInquiryReducer;
