const languageData = [
  {
    languageId: 'vietnam',
    locale: 'vi',
    name: 'Việt Nam',
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
  },
];
export default languageData;
