const {
  GET_LINK_LIST,
  DELETE_LINK,
  GET_LINK_DETAIL,
  CREATE_NEW_LINK,
  GET_LINKS_CLIENT,
  GET_LINKS_CLIENT_SUCCESS,
} = require('shared/constants/ActionTypes');

const initialState = {
  linkList: [],
  selectedLink: null,
  paging: null,
  message: null,

  linksClient: null,
  isLoadLinksClient: false,
};

const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LINK_LIST:
      return {
        ...state,
        linkList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_LINK:
      return {
        ...state,
        linkList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_LINK: {
      return {
        ...state,
        linkList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_LINK_DETAIL:
      return {
        ...state,
        selectedLink: action.payload,
      };
    case GET_LINKS_CLIENT:
      return {
        ...state,
        // isLoadLinksClient: true,
      };
    case GET_LINKS_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadLinksClient: false,
        linksClient: action.payload.data,
      };
    case GET_LINKS_CLIENT:
      return {
        ...state,
        isLoadLinksClient: false,
        // linksClient: [],
      };
    default:
      return state;
  }
};

export default linkReducer;
