// assets

import Iconify from '@common/core/Iconify';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme) {
  const {palette, spacing} = theme;

  return {
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: (
          <Iconify
            icon='ant-design:caret-right-outlined'
            style={{fontSize: '1.5rem'}}
          />
        ),
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.lighter,
          flexDirection: 'row-reverse',
          minHeight: 46,
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },
        content: {
          marginTop: spacing(1.25),
          marginBottom: spacing(1.25),
          marginLeft: spacing(1),
        },
      },
    },
  };
}
