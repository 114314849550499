export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const RoutePermittedRole = {
  admin: 'admin',
  user: 'user',
};
export const linkDanhGia = {
  qr: 'url_evl_danh_gia_qr',
  ttdt: 'url_evl_danh_gia_nguon_ttdt',
};
export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: '/assets/images/avatar/A11.jpg',
};

export const defaultPageIndex = 1;
export const defaultPageSize = 20;
export const defaultOrderBy = 'asc';
export const defaultOrderByField = 'updateAt';

export const formatDateInput = 'dd/MM/yyyy';
export const formatDate = 'YYYY-MM-DD';

export const MAX_INTERGER = 2147483647;

export const initialUrl = '/'; // this url will open after login
