export const URL_USER_GET_LIST = `/nguoi-dung/list`;
export const URL_USER_GET_DETAIL = `/nguoi-dung/get`;
export const URL_USER_DELETE = `/nguoi-dung/delete`;
export const URL_USER_CREATE = `/nguoi-dung/submit`;
export const URL_USER_UPDATE = `/nguoi-dung`;
export const URL_USER_SELF_UPDATE = `/nguoi-dung/tu-cap-nhat`;
export const URL_USER_CREATE_WITH_UNITS = `/nguoi-dung/tao-nguoi-dung-dv`;
export const URL_USER_DISABLE = `/nguoi-dung/disable`;
export const URL_USER_PERMISSION = `/common/list-quyen-he-thong`;
export const URL_USER_UPDATE_SYS = `/nguoi-dung/cap-nhat`;

export const URL_ROLE_GET_LIST = `/quyen-he-thong/list`;
export const URL_ROLE_GET_DETAIL = `/quyen-he-thong`;
export const URL_ROLE_DELETE = `/quyen-he-thong`;
export const URL_ROLE_CREATE = `/quyen-he-thong`;
export const URL_ROLE_UPDATE = `/quyen-he-thong/cap-nhat`;

export const URL_JOB_GET_LIST = '/chuc-nang/list';
export const URL_JOB_GET_UPDATE = '/chuc-nang/nguoi-dung-chuc-nang';
export const URL_JOB_GET_LIST_BY_USER = '/chuc-nang/list-cnnd';

export const URL_PERMISSION_GET_LIST = `/permission/list`;
export const URL_PERMISSION_GET_DETAIL = `/permission/get`;
export const URL_PERMISSION_DELETE = `/permission/delete`;
export const URL_PERMISSION_CREATE = `/permission/submit`;
export const URL_PERMISSION_UPDATE = `/permission/update`;
