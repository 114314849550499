let accessToken = '';
let expires = null;
let refreshToken = '';

export const setAccessToken = (s) => {
  accessToken = s;
};

export const getAccessToken = () => {
  return accessToken;
};

export const setExp = (s) => {
  expires = s;
};

export const getExpires = () => {
  return expires;
};

export const setRefreshToken = (s) => {
  refreshToken = s;
};

export const getRefreshToken = () => {
  return refreshToken;
};
