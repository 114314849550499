const prefix = '/tin-bai';
const prefixVideo = '/tin-bai-video';

export const URL_ARTICLE_GET_LIST = prefix + '/list';
export const URL_ARTICLE_GET_DETAIL = prefix + '/get';
export const URL_ARTICLE_GET_PARENT = prefix + '/get-by-danh-muc';
export const URL_ARTICLE_DELETE = prefix + '/delete';
export const URL_ARTICLE_CREATE = prefix + '/submit';
export const URL_ARTICLE_GET_LIST_CATEGORY =
  '/common/dropdown-danh-muc-tin-bai';
export const URL_ARTICLE_GET_LIST_PARENT = '/common/dropdown-tin-bai';
export const URL_ARTICLE_GET_TREE_CATEGORY =
  'common/dropdown-cay-danh-muc-tin-bai';
export const URL_ARTICLE_PUBLISHER = '/common/dropdown-nguoi-duyet-bai';
export const URL_ARTICLE_CONFIRM = prefix + '/duyet';
export const URL_ARTICLE_ONE_PUBLISHER = prefix + '/set-publish';

export const URL_HOMEPAGE_GET_LATEST_ART = prefix + '/tin-moi-nhat';

export const URL_HOMEPAGE_GET_MOSTVIEWS_ART = prefix + '/xem-nhieu-nhat';

export const URL_HOMEPAGE_GET_FEATURED_ART = prefix + '/tin-noi-bat';

export const URL_HOMEPAGE_GET_VIDEO_ART = prefix + '/tin-video';

export const URL_HOMEPAGE_GET_ARTS = prefix + '/trang-chu';

export const URL_HOMEPAGE_GET_VID_ART = prefixVideo + '/trang-chu';

export const URL_HOMEPAGE_GET_VID_ART_BY_CATE =
  prefixVideo + '/get-by-danh-muc';

export const URL_HOMEPAGE_GETALL_VID_ART = prefixVideo + '/all';

export const URL_HOMEPAGE_DETAIL_VID_ART = prefixVideo + '/chi-tiet';

export const URL_HOMEPAGE_APPROVAL = '/tin-bai/list-tin-can-duyet';
export const URL_HOMEPAGE_DETAIL_APPROVAL = '/tin-bai/xem-truoc-tin-can-duyet';
export const URL_HOMEPAGE_APPROVAL_ARTICLE = '/tin-bai/duyet-tin-bai-web';
