import moment from 'moment';
import {PermissionCode} from 'shared/constants/AppEnums';
import {authRole} from '../../shared/constants/AppConst';

export const createRoutes = (routeConfigs) => {
  let allRoutes = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      const auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return {...route, auth};
    });
  }

  return [...routes];
};

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const multiPropsFilter = (products, filters, stringKey = 'title') => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      console.log('key', key, filters[key], product[key]);
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

export const getCustomDateTime = (
  value = 0,
  unit = 'days',
  format = 'YYYY-MM-DD',
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment().add(value, unit).format(format);
  }
};

export const timeFromNow = (date) => {
  const timestamp = moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const checkPermission = (routeAuth, userRole) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const getUserFromAuth0 = (user) => {
  if (user) {
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.user,
    };
  }
  return user;
};

export const getUserFromFirebase = (user) => {
  console.log('user: ', user);
  if (user) {
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : 'Crema User',
      email: user.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  }
  return user;
};
export const getUserFromAWS = (user) => {
  if (user) {
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Crema User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  }
  return user;
};

export const getUserFromJwtAuth = (user) => {
  if (user) {
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      role: authRole.user,
    };
  }
  return user;
};

export const print = (state, color, text) => {
  if (process.browser && process.env.NODE_ENV === 'development') {
    const msg = typeof text == 'object' ? JSON.stringify(text) : text || '';
    console.log(
      '%c' + state + '%c %s',
      'color:white;font-family:sans-serif;font-size:10px;font-weight:bold;background:' +
        color +
        ';padding:4px 6px 3px 5px;border-radius:3px;',
      '',
      msg,
    );
  }
};

//Array method
export const recursivelyFindKeyValue = (key, keyValue, list, depth = 0) => {
  let results = [];

  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    for (const key of Object.keys(item)) {
      //check if its not an array
      if (Array.isArray(item[key])) {
        let res = recursivelyFindKeyValue(key, keyValue, item[key], depth + 1);
        results = results.concat(res);
      }
      //we have found it
      else if (item[key] === keyValue) {
        //found, return the list

        //return { found: true, containingArray: list, depth: depth };
        results.push({
          found: true,
          containingArray: list,
          depth: depth,
          object: item,
        });
      }
    }
  }

  return results;
};

export const getDisplayPermission = (code) => {
  switch (code) {
    case PermissionCode.CREATE:
      return 'Tạo mới';
    case PermissionCode.READ:
      return 'Xem';
    case PermissionCode.DELETE:
      return 'Xoá';
    case PermissionCode.UPDATE:
      return 'Chỉnh sửa';
    case PermissionCode.APPLY:
      return 'Duyệt';
    case PermissionCode.XULY:
      return 'Xử lý';
    case PermissionCode.TUCHOI:
      return 'Từ chối';
    case PermissionCode.TIEPNHAN:
      return 'Tiếp nhận';
    case PermissionCode.IN:
      return 'In';
    default:
      return '';
  }
};

export const formatDate = (dt, stringformat) => {
  let strformat = '';
  for (let i = 0; i < stringformat.length; i++)
    strformat += char2num(dt, stringformat[i]);
  return strformat;
};

export const char2num = (dt, c) => {
  switch (c) {
    case 'Y':
      return dt.getFullYear();
    case 'y':
      let y = dt.getFullYear();
      y = y % 100;
      return y;
    case 'M':
      let mth = dt.getMonth() + 1;
      let sMth = mth.toString();
      if (mth < 10) {
        sMth = '0' + sMth;
      }
      return sMth;
    case 'd':
      let day = dt.getDate();
      let sDay = day.toString();
      if (day < 10) {
        sDay = '0' + sDay;
      }
      return sDay;
    case 'h':
      return dt.getHours();
    case 'm':
      return dt.getMinutes();
    case 's':
      return dt.getSeconds();
    case 'f':
      return dt.getMilliseconds();
    default:
      return c;
  }
};
