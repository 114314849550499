import {
  GET_TAGS_CLIENT,
  GET_TAGS_CLIENT_FAIL,
  GET_TAGS_CLIENT_SUCCESS,
} from 'shared/constants/ActionTypes';

const initialState = {
  artsByTag: null,
  paging: null,
  message: null,
  isLoadTagClient: null,
};

const TagReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS_CLIENT:
      return {
        ...state,
        artsByTag: null,
        paging: null,
        isLoadTagClient: true,
      };
    case GET_TAGS_CLIENT_SUCCESS:
      return {
        ...state,
        artsByTag: action.payload.data,
        paging: action.payload.paging,
        isLoadTagClient: false,
      };
    case GET_TAGS_CLIENT_FAIL:
      return {
        ...state,
        artsByTag: null,
        paging: null,
        isLoadTagClient: false,
      };
    default:
      return state;
  }
};

export default TagReducer;
