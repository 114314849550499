import {
  GET_REPORT_BY_UNIT,
  GET_REPORT_BY_TIME,
  GET_REPORT_BY_ACCESS,
  GET_REPORT_BY_CRITERIA,
  GET_REPORT_BY_QR,
} from 'shared/constants/ActionTypes';

const initialState = {
  dataReportByUnit: [],
  dataReportByTime: [],
  dataReportByAccess: [],
  dataReportByCriteria: [],
  dataReportByQr: [],
  tongReportByUnit: 0,
  tongReportByAccess: 0,
};

const EvlReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_BY_UNIT:
      return {
        ...state,
        dataReportByUnit: action.payload.data.danhGiaResponses,
        tongReportByUnit: action.payload.data.tongLuotDanhGia,
      };
    case GET_REPORT_BY_TIME:
      return {
        ...state,
        dataReportByTime: action.payload.data,
      };
    case GET_REPORT_BY_ACCESS:
      return {
        ...state,
        dataReportByAccess: action.payload.data.data,
        tongReportByAccess: action.payload.data.tongLuotTruyCap,
      };
    case GET_REPORT_BY_CRITERIA:
      return {
        ...state,
        dataReportByCriteria: action.payload.data,
      };
    case GET_REPORT_BY_QR:
      return {
        ...state,
        dataReportByQr: action.payload.data,
      };
    default:
      return state;
  }
};

export default EvlReportReducer;
