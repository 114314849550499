export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};
export const ThemeStyleRadius = {
  MODERN: 5,
  STANDARD: 5,
};
export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
  FRAMED: 'framed',
};
export const MenuStyle = {
  DEFAULT: 'default',
  STANDARD: 'standard',
  ROUNDED: 'rounded',
  ROUNDED_REVERSE: 'rounded-reverse',
  CURVED_MENU: 'curved-menu',
};
export const LayoutDirection = {
  RTL: 'rtl',
  LTR: 'ltr',
};
export const NavStyle = {
  DEFAULT: 'default',
  BIT_BUCKET: 'bit-bucket',
  STANDARD: 'standard',
  DRAWER: 'drawer',
  MINI: 'mini',
  MINI_SIDEBAR_TOGGLE: 'mini-sidebar-toggle',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  H_DEFAULT: 'h-default',
  HOR_HEADER_FIXED: 'hor-header-fixed',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const HeaderType = {
  DARK: 'dark',
  LIGHT: 'light',
  FIXED: 'fixed',
};
export const RouteTransition = {
  NONE: 'none',
  alpha: 'alpha',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
  LIGHT: '300',
  REGULAR: '400',
  MEDIUM: '500',
  SEMI_BOLD: '600',
  BOLD: '700',
};

export const AuthType = {
  FIREBASE: 'firebase',
  AWS_COGNITO: 'aws_cognito',
  AUTH0: 'auth0',
  JWT_AUTH: 'jwt_auth',
};

export const ElementSection = {
  EXTENSION: 'Extension',
  POSTER: 'Poster',
  FEATURED: 'Tin nổi bật',
  ARTICLE_BY_CATEGORY: 'Tin bài danh mục',
  SLOGAN: 'section_sologan',
  POPULATE: 'section_tin_moi_nhat_xem_nhieu_nhat',
  HOTLINE: 'section_duong_day_nong',
  EMBED: 'section_lien_ket',
  STATISTICAL: 'section_thong_ke',
  BANNER_UP: 'Hiển thị banner phía trên',
  BANNER_DOWN: 'Hiển thị banner phía dưới',
  BANNER_LEFT: 'Hiển thị banner bên trái',
  BANNER_RIGHT: 'Hiển thị banner bên phải',
  ARTICLE_VIDEO: 'section_tin_video',
  ACTIVITY: 'section_hoat_dong_noi_bat',
  FEEDBACK: 'module_phan_anh_kien_nghi',
  GRAYBACKGROUND: 'is_gray_background',
};

export const PermissionCode = {
  CREATE: 'C',
  READ: 'R',
  UPDATE: 'U',
  DELETE: 'D',
  APPLY: 'A',
  XULY: 'X',
  TUCHOI: 'T',
  TIEPNHAN: 'N',
  IN: 'I',
};
