import {GET_ANALYTICS_VIEW_CLIENT} from 'shared/constants/ActionTypes';

const initialState = {
  today: 0,
  month: 0,
  all: 0,
  lastMonth: 0,
};

const AnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_VIEW_CLIENT:
      return {
        ...state,
        today: action.payload.today,
        month: action.payload.month,
        all: action.payload.all,
        lastMonth: action.payload.lastMonth,
      };

    default:
      return state;
  }
};

export default AnalyticsReducer;
