const {
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_DETAIL,
  LIST_PUBLISHER_DOCUMENT,
  LIST_AGENCY_ISSUED,
  LIST_DOCUMENT_TYPE,
  LIST_CATEGORY_DOCUMENT,
  DELETE_DATA_DOCUMENT,
} = require('shared/constants/ActionTypes');

const initialState = {
  documentList: [],
  selectedDocument: null,
  paging: null,
  message: null,
  listCategoryDocument: [],
  listDocumentType: [],
  listAgencyIssued: [],
  listPublisherDocument: [],
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENT_LIST:
      return {
        ...state,
        documentList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_DOCUMENT_DETAIL:
      return {
        ...state,
        selectedDocument: action.payload.data,
      };
    case LIST_PUBLISHER_DOCUMENT:
      return {
        ...state,
        listPublisherDocument: action.payload.data,
      };
    case LIST_AGENCY_ISSUED:
      return {
        ...state,
        listAgencyIssued: action.payload.data,
      };
    case LIST_DOCUMENT_TYPE:
      return {
        ...state,
        listDocumentType: action.payload.data,
      };
    case LIST_CATEGORY_DOCUMENT:
      return {
        ...state,
        listCategoryDocument: action.payload.data,
      };
    case DELETE_DATA_DOCUMENT:
      return {
        ...state,
        selectedDocument: null,
      };
    default:
      return state;
  }
};

export default documentReducer;
