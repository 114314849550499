const {
  GET_BANNER_LIST,
  DELETE_BANNER,
  GET_BANNER_DETAIL,
  CREATE_NEW_BANNER,
  UPDATE_BANNER_DETAIL,
  GET_BANNER_POSITION,
  GET_BANNER_CLIENT_LIST,
  GET_BANNER_CLIENT_LIST_FAIL,
  GET_BANNER_CLIENT_LIST_SUCCESS,
} = require('shared/constants/ActionTypes');

const initialState = {
  bannerList: [],
  selectedBanner: null,
  paging: null,
  message: null,
  listPosition: [],
  bannerListClient: [],
  isLoadBannerClient: null,
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNER_LIST:
      return {
        ...state,
        bannerList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_BANNER:
      return {
        ...state,
        bannerList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_BANNER_DETAIL:
      return {
        ...state,
        bannerList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_BANNER: {
      return {
        ...state,
        bannerList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_BANNER_DETAIL:
      return {
        ...state,
        selectedBanner: action.payload,
      };
    case GET_BANNER_POSITION:
      return {
        ...state,
        listPosition: action.payload.data,
      };
    case GET_BANNER_CLIENT_LIST:
      return {
        ...state,
        isLoadBannerClient: false,
      };
    case GET_BANNER_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        isLoadBannerClient: false,
        bannerListClient: action.payload.data,
      };
    case GET_BANNER_CLIENT_LIST_FAIL:
      return {
        ...state,
        // bannerListClient: [],
        isLoadBannerClient: false,
      };
    default:
      return state;
  }
};

export default bannerReducer;
