import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_INFO_USER,
  LOGOUT,
  SET_REMEMBER_ME,
  SET_USER_REGISTER,
  SET_USER_UNREGISTER,
  SIGN_IN_FAIL,
  SIGN_IN_SUCCESS,
} from 'shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './index';
import {encryptByAES} from '@common/utility/Encrypt';
import {
  URL_LOGIN,
  URL_USER_INFO,
} from 'shared/constants/api-url/authentication';
import useSession from '@common/utility/hooks/useSession';
import {URL_USER_UPDATE} from 'shared/constants/api-url/user';
import serialize from '@common/utility/helper/ObjectToFormData';
import router from 'next/router';
import {formErrorBE} from '@common/utility/helper/FormHelper';
import {API_CAT_AUTH} from 'shared/constants/UrlApis';
import {
  setAccessToken,
  setExp,
  setRefreshToken,
} from '@common/utility/AccessToken';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);
jwtAxios.defaults.baseURL = process.env.NEXT_AUTH_URL;
const JWTAuthAuthProvider = ({children}) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    permission: null,
  });
  const {isAuthenticated, accessToken, user, typeToken, expires, refreshToken} =
    useSelector(({authentication}) => authentication);

  const dispatch = useDispatch();
  const [, setValue] = useSession('rememberMe');

  useEffect(() => {
    const getAuthUser = () => {
      setAuthToken(accessToken, typeToken);
      getUserInfo();
      setAccessToken(accessToken);
      setJWTAuthData({
        user: user,
        isLoading: false,
        isAuthenticated: isAuthenticated,
      });
    };

    getAuthUser();
  }, []);

  useEffect(() => {
    setExp(expires);
    setRefreshToken(refreshToken);
  }, [expires, refreshToken]);

  const signInUser = async ({
    username,
    password,
    ip,
    callbackUrl,
    rememberMe,
  }) => {
    jwtAxios.defaults.baseURL = process.env.NEXT_AUTH_URL;
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post(URL_LOGIN, {
        tenDangNhap: username,
        matKhau: encryptByAES(password, process.env.NEXT_SECRET_KEY_HASH),
        ip,
        urlDieuHuong: callbackUrl,
      });
      setValue(rememberMe);
      const dataLogin = data.data;
      setAuthToken(dataLogin.accessToken, dataLogin.loaiToken);
      setAccessToken(dataLogin.accessToken);
      setJWTAuthData({
        user: dataLogin.thongTinNguoiDung,
        isAuthenticated: true,
        isLoading: false,
        permission: dataLogin.chucNangs,
      });
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SIGN_IN_SUCCESS, payload: data});
      dispatch({type: SET_REMEMBER_ME, payload: {rememberMe}});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
        permission: null,
      });
      console.log('error:', error);
      // formErrorBE(data, dispatch, onToggle);
      dispatch({type: SIGN_IN_FAIL});
      dispatch({
        type: FETCH_ERROR,
        payload:
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          'Lỗi hệ thống',
      });
    }
  };

  const signUpUser = async ({hoTen, tenDangNhap, email, matKhau}) => {
    jwtAxios.defaults.baseURL = process.env.NEXT_AUTH_URL;
    dispatch({type: FETCH_START});
    let formData = serialize({hoTen, tenDangNhap, email, matKhau});
    try {
      const {data} = await jwtAxios.post(URL_USER_UPDATE, formData);
      if (data.data && data.statusCode === 200) {
        dispatch({type: SET_USER_REGISTER});
        router.push('/kiem-tra-email');
        dispatch({type: FETCH_SUCCESS});
      } else {
        formErrorBE(data, dispatch, () => false);
      }
    } catch (error) {
      dispatch({type: SET_USER_UNREGISTER});
      formErrorBE(error, dispatch, () => false);
    }
  };

  const logout = async () => {
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    // localStorage.clear();
    dispatch({type: LOGOUT});
  };

  const getUserInfo = async () => {
    if (isAuthenticated && !!user && accessToken) {
      jwtAxios.defaults.baseURL = API_CAT_AUTH;

      const {data} = await jwtAxios.get(URL_USER_INFO);
      const dataUser = data.data;
      dispatch({type: GET_INFO_USER, payload: {data: dataUser}});
      try {
      } catch (error) {
        logout();
        console.log(error);
      }
    }
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          getUserInfo,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
