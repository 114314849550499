const {
  GET_CONFIG_LIST,
  DELETE_CONFIG,
  GET_CONFIG_DETAIL,
  CREATE_NEW_CONFIG,
  UPDATE_CONFIG_DETAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  configList: [],
  selectedConfig: null,
  paging: null,
  message: null,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_LIST:
      return {
        ...state,
        configList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_CONFIG:
      return {
        ...state,
        configList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_CONFIG_DETAIL:
      return {
        ...state,
        configList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_CONFIG:
      return {
        // ...state,
        // configList: state.configList.filter(
        //   (item) => item.id !== action.payload,
        // ),
        // paging: {
        //   ...state.paging,
        // },
        ...state,
        configList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_CONFIG_DETAIL:
      return {
        ...state,
        selectedConfig: action.payload,
      };

    default:
      return state;
  }
};

export default configReducer;
