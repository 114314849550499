import React from 'react';
import PropTypes from 'prop-types';
import {NextSeo} from 'next-seo';

const SITE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://22-cat-fe.dev.3si.vn';

const defaultTitle = 'CÔNG AN TỈNH THỪA THIÊN HUẾ';
const defaultDescription =
  'TRANG THÔNG TIN ĐIỆN TỬ CÔNG AN TỈNH THỪA THIÊN HUẾ';
const defaultImage = '/assets/images/banner/banner-1.png';
const defaultSep = ' | ';

const isUrl = (str) => {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str);
};

const AppPage = ({children, ...rest}) => {
  const {
    title,
    description,
    image,
    category = 'cong-an-tinh',
    tags = ['cong-an', 'cong-an-thua-thien-hue'],
  } = rest;
  const theTitle = title
    ? title?.length > 48
      ? title.replace(
          /^(\w)(.+)/,
          (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase(),
        )
      : title.replace(
          /^(\w)(.+)/,
          (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase(),
        ) +
        defaultSep +
        defaultTitle
    : defaultTitle;
  const theDescription = description
    ? description.substring(0, 155)
    : defaultDescription;
  const theImage = image
    ? isUrl(image)
      ? image
      : `${SITE_URL}${image}`
    : defaultImage;

  return (
    <React.Fragment>
      <NextSeo
        title={theTitle}
        description={theDescription}
        canonical='https://www.canonical.ie/'
        openGraph={{
          url: 'https://www.url.ie/a',
          title: theTitle,
          description: theDescription,
          images: [
            {
              url: theImage,
              width: 800,
              height: 600,
              alt: 'Cong-an-tinh',
              type: 'image/*',
            },
            {
              url: theImage,
              width: 900,
              height: 800,
              alt: 'Cong-an-tinh',
              type: 'image/*',
            },
          ],
          site_name: 'cong-an-tinh',
        }}
        tags={tags}
        category={category}
      />
      {children}
    </React.Fragment>
  );
};

export default AppPage;

AppPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};
