// material-ui
import {Theme} from '@mui/material/styles';

// third-party
import {merge} from 'lodash';
import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummarry';
import Alert from './Alert';
import AlertTitle from './AlertTile';
import Autocomplete from './Autocomplete';
import Button from './Button';
import Checkbox from './Checkbox';
import Chip from './Chip';
import Dialog from './Dialog';
import DialogContentText from './DialogContentText';
import DialogTitle from './DialogTitle';
import IconButton from './IconButton';
import InputBase from './InputBase';
import InputLabel from './InputLabel';
import ListItemButton from './ListItemButton';
import ListItemIcon from './ListItemIcon';
import OutlinedInput from './OutlinedInput';
import Pagination from './Pagination';
import PaginationItem from './PaginationItem';
import Popover from './Popover';
import Tab from './Tab';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableRow from './TableRow';
import Typography from './Typography';
import Fab from './Fab';

export default function ComponentsOverrides(theme) {
  return merge(
    OutlinedInput(theme),
    Pagination(),
    PaginationItem(theme),
    TableBody(theme),
    TableCell(theme),
    TableHead(theme),
    TableRow(),
    TableFooter(theme),
    InputLabel(theme),
    Button(theme),
    Autocomplete(),
    Checkbox(theme),
    Chip(theme),
    TablePagination(),
    InputBase(),
    Dialog(),
    DialogContentText(theme),
    DialogTitle(),
    Alert(theme),
    AlertTitle(),
    Tab(theme),
    Typography(),
    Popover(theme),
    // IconButton(theme),
    Accordion(theme),
    AccordionDetails(theme),
    AccordionSummary(theme),
    ListItemIcon(theme),
    ListItemButton(theme),
    Fab(theme),
  );
}
