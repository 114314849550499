import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import {Fonts} from 'shared/constants/AppEnums';
import Link from 'next/link';
// import NextLink from 'next/link';
import Grid from '@mui/material/Grid';
import Iconify from '@common/core/Iconify';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Tooltip from '@mui/material/Tooltip';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {alpha} from '@mui/material/styles';
import Image from '@common/core/LazyLoadImage';
import Marquee from 'react-easy-marquee';
import useResponsive from '@common/utility/hooks/useResponsive';

export default function Activity() {
  const theme = useTheme();
  useEffect(() => {
    const param = window.location.pathname;
  }, [location]);
  const {activitiesClient, activitiesClientImage} = useSelector(
    ({activityApp}) => activityApp,
  );
  const isDesktop = useResponsive('up', 'sm');
  return (
    <Box
      sx={{
        pt: {lg: 0, xs: 0},
        // borderTop: 6,
        // borderColor: (theme) => theme.palette.secondary.mauExtension,
        position: 'relative',
        // bgcolor: '#B60704',
        // minHeight: 320,
        // maxHeight: 350,
      }}
    >
      {Array.isArray(activitiesClientImage) &&
        activitiesClientImage.length > 0 &&
        activitiesClientImage.map(
          (item, index) =>
            item.type === 3 && ( // eslint-disable-next-line react/jsx-key
              <Link href={'https://cand.com.vn/lanh-dao-bo-cong-an/'}>
                <a
                  target='_blank'
                  style={{
                    all: 'unset',
                    cursor: 'pointer',
                  }}
                >
                  <Image src={item.filePath ? item.filePath : null} />
                </a>
              </Link>
            ),
        )}
      {Array.isArray(activitiesClientImage) &&
        activitiesClientImage.length > 0 &&
        activitiesClientImage.map(
          (item, index) =>
            item.type === 2 && ( // eslint-disable-next-line react/jsx-key
              <Link href={`/hoat-dong-noi-bat-cua-lanh-dao`}>
                <a
                  style={{
                    all: 'unset',
                    cursor: 'pointer',
                  }}
                >
                  <Image src={item.filePath ? item.filePath : null} />
                </a>
              </Link>
            ),
        )}
      {/* <Box sx={{zIndex: -9999}}>
        <Image
          src={activitiesClientImage?.filePath}
          style={{minHeight: 320, maxHeight: 350}}
        />
      </Box>

      <Box
        sx={{
          zIndex: 2,
          position: 'absolute',
          top: 10,
          left: 15,
          right: 15,
        }}
      >
        <Box
          sx={{
            fontWeight: Fonts.BOLD,
            color: '#eef10f',
            fontSize: 13,
            pl: isDesktop ? 2 : 0,
          }}
        >
          HOẠT ĐỘNG NỔI BẬT CỦA LÃNH ĐẠO CÔNG AN TỈNH
        </Box>
        <Box
          sx={{
            marginBottom: 2,
            marginTop: 1,
            p: 0,
            borderBottom: '2px solid #fff',
          }}
        ></Box>

        <Marquee
          duration={35000}
          background='transparent'
          height={250}
          axis='Y'
          pauseOnHover={true}
          reverse={true}
        >
          {activitiesClient.map((item, index) => {
            return (
              <Grid key={index}>
                <NextLink href={`/hoat-dong-noi-bat-cua-lanh-dao/${item.slug}`}>
                  <Link
                    sx={{
                      all: 'unset',
                      cursor: 'pointer',
                    }}
                    href={`/hoat-dong-noi-bat-cua-lanh-dao/${item.slug}`}
                  >
                    <Grid container>
                      <ListItem
                        key={index}
                        className='item-hover'
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          cursor: 'pointer',
                          p: isDesktop ? 2 : 0,
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box
                              component='span'
                              sx={{
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: 12,
                              }}
                            >
                              <Box
                                sx={{
                                  fontWeight: Fonts.BOLD,
                                  color: '#fff',
                                }}
                              >
                                {item.ngayHoatDong}
                              </Box>
                            </Box>
                          }
                          secondary={
                            <Box
                              component='span'
                              sx={{
                                whiteSpace: 'break-spaces',
                                ml: 2,
                                wordWrap: 'break-word',
                                color: '#f1c40f',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                // WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                                cursor: 'pointer',
                                fontWeight: Fonts.BOLD,
                                '&:hover': {
                                  color: '#fbd51a',
                                },
                                fontSize: 14,
                              }}
                            >
                              <Iconify
                                icon={'bxs:right-arrow'}
                                sx={{
                                  height: 10,
                                  width: 10,
                                  color: '#f1c40f',
                                }}
                              />
                              &nbsp;
                              {item.tieuDe}
                            </Box>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Link>
                </NextLink>
              </Grid>
            );
          })}
        </Marquee>
      </Box> */}
      {/* <Accordion defaultExpanded sx={{border: 'none'}}>
        <AccordionSummary
          aria-controls='panel2d-content'
          id='panel2d-header'
          sx={{borderBottom: '1px solid #C7262E', backgroundColor: 'unset'}}
        >
          <Box
            component='span'
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 13,
              borderColor: (theme) => theme.palette.secondary.mauExtension,
            }}
          >
            <Box
              sx={{
                fontWeight: Fonts.BOLD,
                color: (theme) => theme.palette.secondary.mauExtension,
              }}
            >
              HOẠT ĐỘNG NỔI BẬT CỦA LÃNH ĐẠO
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Marquee
            duration={20000}
            background='transparent'
            height={200}
            axis='Y'
            pauseOnHover={true}
            reverse={true}
          >
            {activitiesClient.map((item, index) => {
              return (
                <Grid key={index}>
                  <NextLink
                    href={`/hoat-dong-noi-bat-cua-lanh-dao/${item.slug}`}
                  >
                    <Link
                      sx={{
                        all: 'unset',
                        cursor: 'pointer',
                      }}
                      href={`/hoat-dong-noi-bat-cua-lanh-dao/${item.slug}`}
                    >
                      <Grid container>
                        <ListItem
                          key={index}
                          className='item-hover'
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            cursor: 'pointer',
                            py: 0,
                          }}
                        >
                          <ListItemText
                            primary={
                              <Box
                                component='span'
                                sx={{
                                  mb: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: 12,
                                }}
                              >
                                <Box
                                  sx={{
                                    fontWeight: Fonts.BOLD,
                                    color: (theme) =>
                                      theme.palette.secondary.mauExtension,
                                  }}
                                >
                                  {item.ngayHoatDong}
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box
                                component='span'
                                sx={{
                                  whiteSpace: 'break-spaces',
                                  ml: 2,
                                  wordWrap: 'break-word',
                                  color: (theme) =>
                                    theme.palette.text.primary.main,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '2',
                                  WebkitBoxOrient: 'vertical',
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: (theme) =>
                                      theme.palette.secondary.mauExtension,
                                  },
                                  fontSize: 14,
                                }}
                              >
                                <Iconify
                                  icon={'bxs:right-arrow'}
                                  sx={{
                                    height: 10,
                                    width: 10,
                                    color: (theme) =>
                                      theme.palette.secondary.mauExtension,
                                  }}
                                />
                                &nbsp;
                                {item.tieuDe}
                              </Box>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Link>
                  </NextLink>
                </Grid>
              );
            })}
          </Marquee>
        </AccordionDetails>
      </Accordion> */}
    </Box>
  );
}

Activity.propTypes = {
  data: PropTypes.any,
};
