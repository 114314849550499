const {
  GET_REFLECT_LIST,
  REFLECT_GET_FIELD,
  GET_STATUS,
  GET_UNIT,
  GET_REFLECT_DETAIL,
  GET_REFLECT_CLIENT,
  GET_REFLECT_CLIENT_FAIL,
  GET_REFLECT_STATUS_CLIENT_FAIL,
  GET_REFLECT_FIELD_CLIENT_FAIL,
  GET_REFLECT_DETAIL_CLIENT_FAIL,
  GET_REFLECT_DETAIL_CLIENT,
  SEND_REFLECT,
  SENT_REFLECT,
  GET_REFLECT_CONFIG,
  GET_REFLECT_REPORT,
  REFLECT_PROCESS,
  REFLECT_PROCESSED,
  GET_REFLECT_NOTIFICATION,
  GET_REFLECT_NOTI_NO_FILTER,
  GET_MY_REFLECTION,
} = require('shared/constants/ActionTypes');

const initialState = {
  reflectList: [],
  reflectPaging: null,
  listField: [],
  listTrangThai: [],
  listDonVi: [],
  detailReflection: {},
  message: null,
  isLoadingClient: null,
  isLoadingDetailClient: null,
  isLoadingSendClient: false,
  config: null,
  dataReport: null,
  isLoadingProcess: null,
  reflectNotification: [],
  reflectNotificationPaging: null,
  reflectNotificationNoFilter: [],
  myReflection: [],
  myReflectionPaging: null,
  isLoadingMyReflect: null,
  isLoadingNotiReflect: null,
};

const reflectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFLECT_LIST:
      return {
        ...state,
        reflectList: action.payload.data,
        isLoadingClient: false,
        reflectPaging: action.payload.paging,
      };
    case GET_REFLECT_CLIENT:
      return {
        ...state,
        isLoadingClient: true,
        isLoadingMyReflect: true,
        isLoadingNotiReflect: true,
      };
    case REFLECT_GET_FIELD:
      return {
        ...state,
        listField: action.payload.data,
      };
    case GET_STATUS:
      return {
        ...state,
        listTrangThai: action.payload.data,
      };
    case GET_UNIT:
      return {
        ...state,
        listDonVi: action.payload.data,
      };
    case GET_REFLECT_DETAIL:
      return {
        ...state,
        detailReflection: action.payload.data,
        isLoadingDetailClient: false,
      };
    case GET_REFLECT_DETAIL_CLIENT:
      return {
        ...state,
        isLoadingDetailClient: true,
      };
    case GET_REFLECT_CLIENT_FAIL:
      return {
        ...state,
        reflectList: [],
      };
    case GET_REFLECT_FIELD_CLIENT_FAIL:
      return {
        ...state,
        listField: [],
      };
    case GET_REFLECT_STATUS_CLIENT_FAIL:
      return {
        ...state,
        listTrangThai: [],
      };
    case GET_REFLECT_DETAIL_CLIENT_FAIL:
      return {
        ...state,
        detailReflection: [],
      };
    case SENT_REFLECT:
      return {
        ...state,
        isLoadingSendClient: false,
      };
    case SEND_REFLECT:
      return {
        ...state,
        isLoadingSendClient: true,
      };
    case GET_REFLECT_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case GET_REFLECT_REPORT:
      return {
        ...state,
        dataReport: action.payload.data,
      };
    case REFLECT_PROCESS:
      return {
        ...state,
        isLoadingProcess: true,
      };
    case REFLECT_PROCESSED:
      return {
        ...state,
        isLoadingProcess: false,
      };
    case GET_REFLECT_NOTIFICATION:
      return {
        ...state,
        reflectNotification: action.payload.data,
        reflectNotificationPaging: action.payload.paging,
        isLoadingNotiReflect: false,
      };
    case GET_REFLECT_NOTI_NO_FILTER:
      return {
        ...state,
        reflectNotificationNoFilter: action.payload.data,
      };
    case GET_MY_REFLECTION:
      return {
        ...state,
        myReflection: action.payload.data,
        myReflectionPaging: action.payload.paging,
        isLoadingMyReflect: false,
      };
    default:
      return state;
  }
};

export default reflectReducer;
