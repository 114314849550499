import {
  SEND_EVL_SUCCESS,
  SEND_EVALUATION,
  GET_EVL_LIST_TTHC,
  SEND_EVL_FAIL,
  FETCH_START,
  GET_EVL_LIST_TTHC_FAILD,
  GET_LIST_EVL,
  GET_EVL_DETAIL,
  GET_EVL_HCC_DROPDOWN,
  GET_EVL_CONFIG,
} from 'shared/constants/ActionTypes';

const initialState = {
  listEvlTTHC: [],
  message: null,
  isLoadingSendEvlClient: false,
  listEvl: [],
  listEvlPaging: null,
  evlDetail: {},
  hccDropdown: [],
  evlConfig: [],
};

const SendEvlReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVL_LIST_TTHC:
      return {
        ...state,
        listEvlTTHC: action.payload.data,
      };
    case GET_EVL_LIST_TTHC_FAILD:
      return {
        ...state,
        listEvlTTHC: [],
      };
    case SEND_EVALUATION:
      return {
        ...state,
        isLoadingSendEvlClient: true,
      };
    case SEND_EVL_SUCCESS:
      return {
        ...state,
        isLoadingSendEvlClient: false,
      };
    case SEND_EVL_FAIL:
      return {
        ...state,
        isLoadingSendEvlClient: false,
      };
    case GET_LIST_EVL:
      return {
        ...state,
        listEvl: action.payload.data,
        listEvlPaging: action.payload.paging,
      };
    case GET_EVL_DETAIL:
      return {
        ...state,
        evlDetail: action.payload.data,
      };
    case GET_EVL_HCC_DROPDOWN:
      return {
        ...state,
        hccDropdown: action.payload.data,
      };
    case GET_EVL_CONFIG:
      return {
        ...state,
        evlConfig: action.payload.data,
      };
    default:
      return state;
  }
};

export default SendEvlReducer;
