import {BiAlignLeft} from 'react-icons/bi';

const {
  GET_CATEGORY_LIST,
  DELETE_CATEGORY,
  GET_CATEGORY_DETAIL,
  CREATE_NEW_CATEGORY,
  GET_TREE_CATEGORY,
  LOAD_TREE_CATEGORY,
  LOAD_TREE_CATEGORY_SUCCESS,
  LOAD_TREE_CATEGORY_FAIL,
  GET_CATEGORY_PARENT,
  GET_LIST_IMAGE,
} = require('shared/constants/ActionTypes');

const initialState = {
  categoryList: [],
  selectedCategory: null,
  paging: null,
  message: null,
  treeCategories: {
    data: [],
  },
  isLoadTreeCategories: false,
  listCategoryParent: [],
  listImage: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_CATEGORY:
      return {
        ...state,
        categoryList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_CATEGORY: {
      return {
        ...state,
        categoryList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_CATEGORY_DETAIL:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case GET_TREE_CATEGORY:
      return {
        ...state,
        treeCategories: {
          ...action.payload,
          data: transformDataCategory(action.payload.data),
        },
      };
    case GET_LIST_IMAGE:
      return {
        ...state,
        listImage: action.payload.data,
      };
    case LOAD_TREE_CATEGORY:
      return {
        ...state,
        // isLoadTreeCategories: false,
      };

    case LOAD_TREE_CATEGORY_SUCCESS:
      return {
        ...state,
        // isLoadTreeCategories: true,
      };

    case LOAD_TREE_CATEGORY_FAIL:
      return {
        ...state,
      };
    case GET_CATEGORY_PARENT:
      return {
        ...state,
        listCategoryParent: action.payload.data,
      };
    default:
      return state;
  }
};

export default categoryReducer;

const transformDataCategory = (data, uri = '', isTwoLevel = false) => {
  return data.map((res) => {
    const type = !isTwoLevel
      ? 'group'
      : Array.isArray(res.danhMucCons) && res.danhMucCons.length > 0
      ? 'collapse'
      : 'item';

    return {
      id: res.id,
      title: res.tenDanhMuc,
      messageId: 'sidebar',
      type: type,
      url:
        res.link === '/'
          ? res.link
          : `${uri === '' ? uri : '/' + uri}/${
              Array.isArray(res.danhMucCons) && res.danhMucCons.length === 0
                ? res.link + ''
                : res.link + ''
            }`,
      children:
        Array.isArray(res.danhMucCons) && res.danhMucCons.length > 0
          ? transformDataCategory(res.danhMucCons, res.link, true)
          : null,
      icon: type === 'item' ? null : null,
      originSlug: res.link,
      source: res.nguonGetData,
      congAnId: res.congAnId,
    };
  });
};
