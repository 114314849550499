import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import React from 'react';
import Tab from '@mui/material/Tab';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {Fragment} from 'react';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import {useSelector} from 'react-redux';
import {Fonts} from 'shared/constants/AppEnums';
import Link from 'next/link';
import ImageListSkeleton from '@common/core/AppSkeleton/ImageListSkeleton';
import Image from '@common/core/LazyLoadImage';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextMaxLine from '@common/core/TextMaxLine';

const style = {
  width: '100%',
  // maxWidth: 360,
  bgcolor: 'background.paper',
};

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function LatestNews() {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const {latestArticles, mostViewArticles, isLoadingLatest, isLoadingMostView} =
    useSelector(({articleApp}) => articleApp);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        width: '100%',
        borderTop: 6,
        borderColor: (theme) => theme.palette.secondary.mauExtension,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='secondary'
        textColor='secondary'
        variant='fullWidth'
        // style={{minHeight: '28px'}}
      >
        <Tab
          label={
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>XEM NHIỀU NHẤT</div>
            </div>
          }
          {...a11yProps(1)}
          style={{
            padding: 0,
            lineHeight: 1,
          }}
        />
        <Tab
          label={
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>TIN MỚI NHẤT</div>
              {/* <div style={{marginLeft: '8px', background: 'red'}}>
                  <Image
                    src='/assets/images/gifs/newGif.gif'
                    width='28px'
                    height='20px'
                  />
                </div> */}
            </div>
          }
          {...a11yProps(0)}
          style={{
            padding: 0,
            lineHeight: 1,
          }}
        />
      </Tabs>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {isLoadingLatest ? (
          <ImageListSkeleton screen={isMobile ? 'mobile' : 'desktop'} />
        ) : (
          <List sx={style}>
            {latestArticles && latestArticles.length > 0
              ? latestArticles.map((item, index) => (
                  <Fragment key={index}>
                    <Link href={`/bai-viet/${item?.slug}`}>
                      <a style={{textDecoration: 'none'}}>
                        <ListItem
                          style={{padding: 2, backgroundColor: 'unset'}}
                        >
                          <Card
                            sx={{
                              display: 'flex',
                              backgroundColor: 'unset',
                              boxShadow: 'unset',
                              width: '100%',
                              cursor: 'pointer',
                              p: 2,
                            }}
                          >
                            <Box width={'30%'}>
                              <Image
                                style={{
                                  borderRadius: '4px',
                                }}
                                ratio={'6/4'}
                                src={item?.filePath}
                                alt=''
                              />
                            </Box>
                            <Box width={'70%'}>
                              <ListItemText
                                style={{
                                  margin: 0,
                                  marginLeft: '12px',
                                }}
                              >
                                <Box
                                  className='content-links'
                                  sx={{cursor: 'pointer'}}
                                >
                                  <TextMaxLine
                                    line={4}
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: Fonts.MEDIUM,
                                      ':hover': {
                                        color: (theme) =>
                                          theme.palette.secondary.mauExtension,
                                      },
                                    }}
                                  >
                                    {item?.tieuDe}
                                  </TextMaxLine>
                                </Box>
                              </ListItemText>
                            </Box>
                          </Card>
                        </ListItem>
                      </a>
                    </Link>

                    {index === latestArticles.length - 1 ? null : <Divider />}
                  </Fragment>
                ))
              : null}
          </List>
        )}
      </TabPanel>
      <TabPanel value={value} index={0} dir={theme.direction}>
        {isLoadingMostView ? (
          <ImageListSkeleton />
        ) : (
          <List sx={style}>
            {mostViewArticles && mostViewArticles.length > 0
              ? mostViewArticles.map((item, index) => (
                  <Fragment key={index}>
                    <Link href={`/bai-viet/${item?.slug}`}>
                      <a style={{textDecoration: 'none'}}>
                        <ListItem
                          style={{padding: 2, backgroundColor: 'unset'}}
                        >
                          <Card
                            sx={{
                              display: 'flex',
                              backgroundColor: 'unset',
                              boxShadow: 'unset',
                              width: '100%',
                              cursor: 'pointer',
                            }}
                          >
                            <Box
                              sx={{
                                width: '30%',
                              }}
                            >
                              <Image
                                style={{
                                  borderRadius: '4px',
                                }}
                                src={item?.filePath}
                                ratio={'6/4'}
                                alt=''
                              />
                            </Box>
                            <Box
                              sx={{
                                width: '70%',
                              }}
                            >
                              <ListItemText
                                style={{
                                  margin: 0,
                                  marginLeft: '12px',
                                }}
                              >
                                <Box
                                  className='content-links'
                                  sx={{cursor: 'pointer'}}
                                >
                                  <TextMaxLine
                                    line={4}
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: Fonts.MEDIUM,
                                      ':hover': {
                                        color: (theme) =>
                                          theme.palette.secondary.mauExtension,
                                      },
                                    }}
                                  >
                                    {item?.tieuDe}
                                  </TextMaxLine>
                                </Box>
                              </ListItemText>
                            </Box>
                          </Card>
                        </ListItem>
                      </a>
                    </Link>

                    {index === mostViewArticles.length - 1 ? null : (
                      <Divider
                        style={{marginTop: '4px', marginBottom: '4px'}}
                      />
                    )}
                  </Fragment>
                ))
              : null}
          </List>
        )}
      </TabPanel>
    </Box>
  );
}
