import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Plyr from 'plyr-react';

function AppVideoPlayer({url}) {
  const smWidth = useMediaQuery('(min-width:600px)');
  const ref = useRef();

  const plyrProps = {
    source: {
      type: 'video',
      sources: [
        {
          src: url,
          type: 'video/mp4',
        },
      ],
    },
    options: {
      i18n: {
        restart: 'Xem lại',
        rewind: 'Lùi {seektime} giây',
        play: 'Xem',
        pause: 'Dừng',
        fastForward: 'Tiến {seektime} giây',
        seek: 'Tua',
        seekLabel: '{currentTime} / {duration}',
        played: 'Đã chạy',
        buffered: 'Đã load',
        currentTime: 'Thời gian hiện tại',
        duration: 'Thời lượng',
        volume: 'Âm lượng',
        mute: 'Tắt âm lượng',
        unmute: 'Mở âm lượng',
        enableCaptions: 'Mở phụ đề',
        disableCaptions: 'Tắt phụ đề',
        download: 'Tải xuống',
        enterFullscreen: 'Mở toàn màn hình',
        exitFullscreen: 'Thoát toàn màn hình',
        frameTitle: 'Video {title}',
        captions: 'Phụ đề',
        settings: 'Cài đặt',
        menuBack: 'Trở về menu',
        speed: 'Tốc độ',
        normal: 'Bình thường',
        quality: 'Chất lượng video',
        loop: 'Lặp',
      },
      controls: [
        'play-large',
        'play',
        'progress',
        smWidth && 'current-time',
        smWidth && 'volume',
        smWidth && 'settings',
        'mute',
        'pip',
        'airplay',
        'fullscreen',
      ],
      storage: {enabled: true, key: 'video-cong-an-tinh'},
    },
  };
  return (
    <Box sx={{mx: {lg: 3, xs: 2}}}>
      <Plyr
        {...plyrProps}
        mediaGroup
        ref={ref}
        style={{
          width: '100%',
          '--plyr-color-main': '#C7262E',
          '--plyr-audio-controls-background': 'rgba(255, 255, 255, 0.95)',
        }}
      />
    </Box>
  );
}

AppVideoPlayer.propTypes = {
  url: PropTypes.string,
};

export default React.memo(AppVideoPlayer);
