const {
  GET_CRITERIA_LIST,
  DELETE_CRITERIA,
  GET_CRITERIA_DETAIL,
  CREATE_NEW_CRITERIA,
  UPDATE_CRITERIA_DETAIL
} = require('shared/constants/ActionTypes');

const initialState = {
  criteriaList: [],
  selectedCriteria: null,
  paging: null,
  message: null,
};

const CriteriaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CRITERIA_LIST:
      return {
        ...state,
        criteriaList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_CRITERIA:
      return {
        ...state,
        criteriaList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_CRITERIA_DETAIL:
      return {
        ...state,
        criteriaList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_CRITERIA: {
      return {
        ...state,
        criteriaList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_CRITERIA_DETAIL:
      return {
        ...state,
        selectedCriteria: action.payload,
      };
    default:
      return state;
  }
};

export default CriteriaReducer;
