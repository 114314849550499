import jwtAxios from '@common/services/auth/jwt-auth';
import {
  GET_LIST_ARTICLE_BY_CAT_CLIENT,
  GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS,
  GET_LIST_ARTICLE_BY_CAT_CLIENT_SUCCESS,
} from 'shared/constants/ActionTypes';
import {
  URL_CATEGORY_CHILDRENT_DETAIL,
  URL_LIST_TTHC_NGUON_2,
  URL_LIST_TTHC_NGUON_3,
  URL_LIST_TTHC_NGUON_4,
  URL_LIST_TTHC_NGUON_5,
  URL_LIST_TTHC_NGUON_6,
  URL_LIST_TTHC_NGUON_7,
} from 'shared/constants/api-url/category';
import {API_CAT, API_CAT_TTHC} from 'shared/constants/UrlApis';

export const onGetArticleByCategory = (
  slug,
  source,
  congAnId,
  router,
  pageIndex,
) => {
  jwtAxios.defaults.baseURL =
    !source || source === 1 || source === 6 ? `${API_CAT}` : `${API_CAT_TTHC}`;
  const url =
    source === 2
      ? `${URL_LIST_TTHC_NGUON_2}?NguonData=${source}`
      : source === 3
      ? `${URL_LIST_TTHC_NGUON_3}?NguonData=${source}`
      : source === 4
      ? `${URL_LIST_TTHC_NGUON_4}?NguonData=${source}&CongAnId=${congAnId}`
      : source === 5
      ? `${URL_LIST_TTHC_NGUON_5}?NguonData=${source}&CongAnId=${congAnId}`
      : source === 6
      ? `${URL_LIST_TTHC_NGUON_6}?NguonData=${source}`
      : source === 7
      ? `${URL_LIST_TTHC_NGUON_7}`
      : pageIndex
      ? `${URL_CATEGORY_CHILDRENT_DETAIL}?PageIndex=${pageIndex}`
      : URL_CATEGORY_CHILDRENT_DETAIL;
  return (dispatch) => {
    dispatch({type: GET_LIST_ARTICLE_BY_CAT_CLIENT});
    jwtAxios
      .get(url, {
        params: {
          link: slug,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_LIST_ARTICLE_BY_CAT_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          if (slug === '/') {
            dispatch({
              type: GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS,
            });
          } else {
            dispatch({
              type: GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS,
            });
            router.push('/404');
          }
        } else {
          dispatch({
            type: GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS,
        });
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};
