import {useState, useEffect} from 'react';
import {useRouter} from 'next/router';

const EXCEPTIONS = [
  '/dang-ky',
  '/dang-nhap',
  '/xac-nhan-quen-mat-khau',
  '/quen-mat-khau',
  '/xac-nhan-dang-ky',
  '/xac-nhan-doi-mat-khau',
  '/kiem-tra-email',
];

const useRouteUrlHistory = () => {
  const [previousRoute, setPreviousRouter] = useState('');
  const router = useRouter();

  const handleHistoryChange = (url) => {
    if (!EXCEPTIONS.includes(url)) {
      const originPathNoParams = router.asPath.split('?')[0];
      if (!!originPathNoParams) {
        setPreviousRouter(originPathNoParams);
      } else {
        setPreviousRouter(router.asPath);
      }
    }
  };

  useEffect(() => {
    handleHistoryChange(router.asPath);
  }, [router.asPath]);

  return {previousRoute};
};

export default useRouteUrlHistory;
