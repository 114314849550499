import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import TabsNewsManyViews from './componentNewsManyViews';
// import ComponentListImage from './componentListImage';
// import ComponentHeader from './componentHeader';
import SloganPolice from './SloganPolice';
import Activity from './Activity';
import LatestNews from './LatestNews';
import QuestionFields from '../../../../../modules/clients/Question/Field';
import MostViewQuestion from '../../../../../modules/clients/Question/MostViewQuestion';
import BannerRightBar from './BannerRightBar';
import AppBannerRight from '../AppBanner/AppBannerRight';
import AppCounter from './AppCounter';
import StickyBox from '@common/core/AppStickyBox';
import {useSelector} from 'react-redux';
import {ElementSection} from 'shared/constants/AppEnums';
import CardPAKN from './CardPAKN';

const AppRightBar = () => {
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  const {questionMostView} = useSelector(({questionApp}) => questionApp);

  return (
    <Grid xs={12} md={3}>
      <StickyBox offsetBottom={30} offsetTop={50}>
        {window.location.pathname.split('/')[1] === 'hoi-dap' && (
          <QuestionFields />
        )}
        {window.location.pathname.split('/')[1] === 'hoi-dap' &&
          questionMostView &&
          questionMostView.length > 0 && <MostViewQuestion />}

        {Array.isArray(layoutClient) &&
          layoutClient.length > 0 &&
          layoutClient.filter(
            (x) => x.sectionName === ElementSection.ACTIVITY && x.isDisplay,
          ).length > 0 && <Activity />}

        {window.location.pathname.split('/')[1] !== 'hoi-dap' &&
          Array.isArray(layoutClient) &&
          layoutClient.length > 0 &&
          layoutClient.filter(
            (x) => x.sectionName === ElementSection.SLOGAN && x.isDisplay,
          ).length > 0 && <SloganPolice />}
        {Array.isArray(layoutClient) &&
          layoutClient.length > 0 &&
          layoutClient.filter(
            (x) => x.sectionName === ElementSection.FEEDBACK && x.isDisplay,
          ).length > 0 && <CardPAKN />}
        {window.location.pathname.split('/')[1] !== 'hoi-dap' &&
          Array.isArray(layoutClient) &&
          layoutClient.length > 0 &&
          layoutClient.filter(
            (x) => x.sectionName === ElementSection.POPULATE && x.isDisplay,
          ).length > 0 && <LatestNews />}
        {Array.isArray(layoutClient) &&
          layoutClient.length > 0 &&
          layoutClient.filter(
            (x) => x.sectionName === ElementSection.EMBED && x.isDisplay,
          ).length > 0 && <BannerRightBar />}
        {Array.isArray(layoutClient) &&
          layoutClient.length > 0 &&
          layoutClient.filter(
            (x) => x.sectionName === ElementSection.HOTLINE && x.isDisplay,
          ).length > 0 && <AppBannerRight />}

        {Array.isArray(layoutClient) &&
          layoutClient.length > 0 &&
          layoutClient.filter(
            (x) => x.sectionName === ElementSection.STATISTICAL && x.isDisplay,
          ).length > 0 && <AppCounter />}
      </StickyBox>
    </Grid>
  );
};

AppRightBar.propTypes = {};
export default AppRightBar;
