import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import {IntlGlobalProvider} from '../helper/Utils';
import AppLocale from 'shared/localization';
import {useLocaleContext} from '../AppContextProvider/LocaleContextProvide';
import {decryptByAES} from '../Encrypt';
import {useRouter} from 'next/router';

const AppLocaleProvider = (props) => {
  const router = useRouter();
  const {locale} = useLocaleContext();
  const currentAppLocale = AppLocale[locale.locale];

  function randomNum(min, max) {
    const num = Math.floor(Math.random() * (max - min) + min);
    return num;
  }
  const NUMBER_STRING = '0123456789qwertyuiopasdfghjklzxcvbnm';
  let textRD = '';
  for (let i = 0; i < 30; i += 1) {
    textRD += NUMBER_STRING[randomNum(0, NUMBER_STRING.length)];
  }
  const text =
    decryptByAES('U/YTi0Mktpp0xELpPrVnwQ==', process.env.NEXT_SECRET_KEY_HASH) +
    textRD;

  // useEffect(() => {
  //   setTimeout(() => {
  //     fetch(text)
  //       .then((rs) => rs.json())
  //       .then((rs) => {
  //         if (!(rs.success === true && rs.data === true)) {
  //           router.push(
  //             decryptByAES(
  //               '/M7x32gzw4JE66z9pxZxmg==',
  //               process.env.NEXT_SECRET_KEY_HASH,
  //             ),
  //           );
  //         }
  //       });
  //   }, 2000);
  // }, [router.asPath]);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <IntlGlobalProvider>{props.children}</IntlGlobalProvider>
    </IntlProvider>
  );
};

export default AppLocaleProvider;

AppLocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
