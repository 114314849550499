import React from 'react';
import PropTypes from 'prop-types';
import AppCard from '@common/core/AppCard';

import dynamic from 'next/dynamic';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Fonts} from 'shared/constants/AppEnums';
import {useSelector} from 'react-redux';
import Iconify from '@common/core/Iconify';
import {abbreviateNumber} from '@common/utility/FormatNumber';
import {useState} from 'react';
import {useEffect} from 'react';

function AppCounter(props) {
  const [Odometer, setOdometer] = useState(() => () => 0);

  useEffect(() => {
    setOdometer(dynamic(() => import('react-odometerjs')));
  }, []);

  const analyticsApp = useSelector(({analyticsApp}) => analyticsApp);
  return (
    <AppCard
      contentStyle={{px: 1, height: 1}}
      sx={{
        my: 2,
        borderTop: 6,
        borderColor: (theme) => theme.palette.secondary.mauExtension,
      }}
    >
      <Box sx={{fontSize: 20, textAlign: 'center', py: 1}}>
        <Odometer value={analyticsApp.all} format='(.ddd),dd' />
      </Box>
      <Grid container rowSpacing={5}>
        <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
          <Iconify icon={'ic:round-today'} width={25} height={25} />
          <Box sx={{mx: 1}} />
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            Hôm nay
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}>
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            {abbreviateNumber(analyticsApp.today)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
          <Iconify icon={'ci:calendar-week'} width={25} height={25} />
          <Box sx={{mx: 1}} />
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            Trong tháng
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}>
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            {abbreviateNumber(analyticsApp.month)}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
          <Iconify icon={'ic:sharp-calendar-month'} width={25} height={25} />
          <Box sx={{mx: 1}} />
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            Tháng trước
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}>
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            {abbreviateNumber(analyticsApp.lastMonth)}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{display: 'flex', alignItems: 'center'}}>
          <Iconify icon={'bx:bar-chart-square'} width={25} height={25} />
          <Box sx={{mx: 1}} />
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            Tổng lượng truy cập
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{textAlign: 'right'}}>
          <Typography
            sx={{
              color: (theme) => theme.palette.grey['900'],
              fontWeight: Fonts.MEDIUM,
              fontSize: 14,
            }}
          >
            {abbreviateNumber(analyticsApp.all)}
          </Typography>
        </Grid>
      </Grid>
    </AppCard>
  );
}

AppCounter.propTypes = {};

export default AppCounter;
