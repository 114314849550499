import {GET_EVL_QR} from 'shared/constants/ActionTypes';

const initialState = {
  dataQr: null,
};

const EvlQRReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVL_QR:
      return {
        ...state,
        dataQr: action.payload.data,
      };
    default:
      return state;
  }
};

export default EvlQRReducer;
