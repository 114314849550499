const {
  GET_UNIT_LIST,
  DELETE_UNIT,
  GET_UNIT_DETAIL,
  CREATE_NEW_UNIT,
  UPDATE_UNIT_DETAIL,
  GET_UNIT_LIST_DROP,
  GET_UNIT_CAP_HC,
} = require('shared/constants/ActionTypes');

const initialState = {
  unitList: [],
  unitDrop: [],
  selectedContact: null,
  paging: null,
  message: null,
  unitListClient: [],
  listCapHC: [],
  isLoadingClient: null,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNIT_LIST:
      return {
        ...state,
        unitList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_UNIT_LIST_DROP:
      return {
        ...state,
        unitDrop: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_UNIT:
      return {
        ...state,
        unitList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_UNIT_DETAIL:
      return {
        ...state,
        unitList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_UNIT: {
      return {
        ...state,
        unittList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_UNIT_DETAIL:
      return {
        ...state,
        selectedUnit: action.payload,
      };
    case GET_UNIT_CAP_HC:
      return {
        ...state,
        listCapHC: action.payload.data,
      };
    default:
      return state;
  }
};

export default contactReducer;
