const {
  GET_DEVICE_LIST,
  DELETE_DEVICE,
  GET_DEVICE_DETAIL,
  CREATE_NEW_DEVICE,
  UPDATE_DEVICE_DETAIL
} = require('shared/constants/ActionTypes');

const initialState = {
  deviceList: [],
  selectedDevice: null,
  paging: null,
  message: null,
};

const DeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_LIST:
      return {
        ...state,
        deviceList: action.payload.data,
        paging: action.payload.paging,
      };
    case CREATE_NEW_DEVICE:
      return {
        ...state,
        deviceList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_DEVICE_DETAIL:
      return {
        ...state,
        deviceList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_DEVICE: {
      return {
        ...state,
        deviceList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_DEVICE_DETAIL:
      return {
        ...state,
        selectedDevice: action.payload,
      };
    default:
      return state;
  }
};

export default DeviceReducer;
