import jwtAxios from '@common/services/auth/jwt-auth';
import {appIntl} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_LINKS_CLIENT,
  GET_LINKS_CLIENT_FAIL,
  GET_LINKS_CLIENT_SUCCESS,
} from 'shared/constants/ActionTypes';
import {
  URL_LIST_LINKS_PAGE_CATEGORY,
  URL_COUNT_CLICK,
} from 'shared/constants/api-url/category';
import {API_CAT} from 'shared/constants/UrlApis';

export const onGetLinkClientList = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_LINKS_CLIENT});
    jwtAxios
      .get(URL_LIST_LINKS_PAGE_CATEGORY, {
        params: {},
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_LINKS_CLIENT_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_LINKS_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_LINKS_CLIENT_FAIL, payload: error.message});
      });
  };
};

export const onCountClick = (id) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_COUNT_CLICK, {
        id: id,
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};
