import {decryptByAES} from '@common/utility/Encrypt';
import {
  CREATE_NEW_USER,
  DELETE_USER,
  GET_ROLE_LIST_BY_USER,
  GET_USER_DETAIL,
  GET_USER_LIST,
  UPDATE_USER_DETAIL,
} from 'shared/constants/ActionTypes';

const initialState = {
  userList: [],
  roleUserList: [],
  selectedUser: null,
  paging: null,
  message: null,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        userList: action.payload.data.map((rs) => {
          return {
            ...rs,
            email: rs.email
              ? decryptByAES(rs.email, process.env.NEXT_SECRET_KEY_HASH)
              : null,
            dienThoai: rs.dienThoai
              ? decryptByAES(rs.dienThoai, process.env.NEXT_SECRET_KEY_HASH)
              : null,
          };
        }),
        paging: action.payload.paging,
      };
    case CREATE_NEW_USER:
      return {
        ...state,
        userList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_USER_DETAIL:
      return {
        ...state,
        userList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_USER:
      return {
        ...state,
        userList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_USER_DETAIL:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case GET_ROLE_LIST_BY_USER:
      console.log(action.payload);
      return {
        ...state,
        roleUserList: action.payload.data,
      };
    default:
      return state;
  }
};

export default UserReducer;
