import {
  CREATE_NEW_POLICE,
  DELETE_POLICE,
  GET_POLICE_DETAIL,
  GET_POLICE_LIST,
  GET_LIST_LEVEL,
  GET_LIST_PROCEDURE,
  UPDATE_POLICE_DETAIL,
} from 'shared/constants/ActionTypes';

const initialState = {
  policeList: [],
  selectedPolice: null,
  paging: null,
  message: null,
  listAdministrativeLevel: [],
  listAdministrativeProcedure: [],
};

const PoliceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLICE_LIST:
      return {
        ...state,
        policeList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_LIST_LEVEL:
      return {
        ...state,
        listAdministrativeLevel: action.payload.data,
      };
    case GET_LIST_PROCEDURE:
      return {
        ...state,
        listAdministrativeProcedure: action.payload.data,
      };
    case CREATE_NEW_POLICE:
      return {
        ...state,
        policeList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_POLICE_DETAIL:
      return {
        ...state,
        policeList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_POLICE:
      return {
        ...state,
        policeList: action.payload.data,
        paging: action.payload.paging,
      };
    case GET_POLICE_DETAIL:
      return {
        ...state,
        selectedPolice: action.payload.data,
      };

    default:
      return state;
  }
};

export default PoliceReducer;
