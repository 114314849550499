import Box from '@mui/material/Box';
import React, {useEffect} from 'react';
import {useTheme} from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {useSelector} from 'react-redux';
import {Fonts} from 'shared/constants/AppEnums';
import Link from 'next/link';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Iconify from '@common/core/Iconify';
import {parseParams} from '@common/utility/helper/Utils';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {alpha} from '@mui/material/styles';

export default function QuestionFields() {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const param = window.location.search;
    setValue(parseInt(parseParams(param).linhVucId));
  }, [location]);

  const {listQuestionField} = useSelector(({questionApp}) => questionApp);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        width: '100%',
        borderTop: 6,
        mb: 2,
        borderColor: (theme) => theme.palette.secondary.mauExtension,
        borderRadius: 1,
        '& .MuiAccordion-root': {
          borderColor: theme.palette.divider,
          '& .MuiAccordionSummary-root': {
            bgcolor: alpha(theme.palette.primary.lighter, 0.35),
            flexDirection: 'row',
            backgroundColor: 'unset',
            minHeight: '30px',
          },
          '& .MuiAccordionDetails-root': {
            border: 'none',
          },
          '& .Mui-expanded': {
            color: theme.palette.primary.main,
          },
        },
      }}
    >
      <Accordion defaultExpanded sx={{border: 'none'}}>
        <AccordionSummary
          aria-controls='panel2d-content'
          id='panel2d-header'
          sx={{borderBottom: '1px solid #C7262E', backgroundColor: 'unset'}}
        >
          <Box
            component='span'
            sx={{
              // mb: 1,
              display: 'flex',
              alignItems: 'center',
              fontSize: 14,
              // p: 2,
              // borderBottom: 2,
              borderColor: (theme) => theme.palette.secondary.mauExtension,
            }}
          >
            <Box
              sx={{
                fontWeight: Fonts.BOLD,
                color: (theme) => theme.palette.secondary.mauExtension,
              }}
            >
              LĨNH VỰC
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {listQuestionField.map((listField, index) => {
            return (
              <Grid key={index}>
                <Link
                  sx={{
                    all: 'unset',
                    cursor: 'pointer',
                  }}
                  href={`/hoi-dap?linhVucId=${listField.value}`}
                >
                  <a style={{all: 'unset'}}>
                    <Grid container>
                      <ListItem
                        key={index}
                        className='item-hover'
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          cursor: 'pointer',
                          py: 0,
                        }}
                      >
                        <ListItemText
                          sx={{
                            flex: '1 1 0',
                            padding: 0,
                          }}
                          secondary={
                            <Box
                              component='span'
                              sx={{
                                wordWrap: 'break-word',
                                color: (theme) =>
                                  value === listField.value
                                    ? theme.palette.secondary.mauExtension
                                    : theme.palette.text.primary.main,
                                overflow: 'hidden',
                                fontWeight:
                                  value === listField.value
                                    ? Fonts.BOLD
                                    : 'unset',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                cursor: 'pointer',
                                '&:hover': {
                                  color: (theme) =>
                                    theme.palette.secondary.mauExtension,
                                },
                                fontSize: 14,
                                padding: 0,
                              }}
                            >
                              <Iconify
                                icon={'bxs:right-arrow'}
                                sx={{
                                  height: 10,
                                  width: 10,
                                  color: (theme) =>
                                    theme.palette.secondary.mauExtension,
                                }}
                              />
                              &nbsp;
                              {listField.display}
                            </Box>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </a>
                </Link>
              </Grid>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
