import {FETCH_ERROR, FETCH_ERROR_FROM_BE} from 'shared/constants/ActionTypes';
import {isFunction} from 'lodash';

export const formErrorBE = (error, dispatch, onToggle) => {
  if (!error) return;
  if (error.data && error.data.statusCode === 422 && error.data.message) {
    let errorMessage = getErrorMessage(error.data);
    dispatch({type: FETCH_ERROR_FROM_BE, payload: removeEmpty(errorMessage)});
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.statusCode === 400 &&
    error.response.data.message
  ) {
    try {
      JSON.parse(error.response.data.message);
      let errorMessage = getErrorMessage(error.response.data);
      dispatch({type: FETCH_ERROR_FROM_BE, payload: removeEmpty(errorMessage)});
    } catch (e) {}
  } else if (error.statusCode === 422 && error.message) {
    let errorMessage = getErrorMessage(error);

    dispatch({type: FETCH_ERROR_FROM_BE, payload: removeEmpty(errorMessage)});
  }
  if (isFunction(onToggle)) onToggle();
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};
const isObject = (obj) =>
  Object.prototype.toString.call(obj) === '[object Object]';

const lowerCaseObjectKeys = (obj) =>
  Object.fromEntries(Object.entries(obj).map(objectKeyMapper));

const objectKeyMapper = ([key, val]) => [
  capitalizeFirstLetter(key),
  isObject(val) ? lowerCaseObjectKeys(val) : val,
];

const removeEmpty = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

const flatten = (arr) => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten,
    );
  }, []);
};

const getErrorMessage = (res) => {
  //convert json string

  const arrMesage = JSON.parse(res.message);

  //thay thế chữ cái đầu tiên về chữ thường

  arrMesage.map((item) => {
    item.key = item.key.replace(
      item.key.charAt(0),
      item.key.charAt(0).toLocaleLowerCase(),
    );
    return item;
  });

  // gán lại key = message ví dụ maDonVi: Mã đơn vị không được bỏ trống

  const newListErr = arrMesage.reduce(
    (acc, x) => [
      ...acc,
      {
        [x.key]: x.value,
      },
    ],
    [],
  );

  // convert nhiều object thành 1 object cho array => {maDonVi: không được bỏ trống, tenDonVi: không được bỏ trống}

  const newObject = Object.assign({}, ...newListErr);

  //lấy key ví dụ {maDonVi: không được bỏ trống, tenDonVi: không được bỏ trống} => ['maDonVi', 'tenDonVi']

  const entityError = Object.keys(newObject);

  return newObject;
};

export const convertFormJsonSubmittableToReactForm = (
  fields,
  validateState,
) => {
  const formatted = fields
    .filter((field) => {
      const {fieldType} = field;
      const validFields = [
        'title',
        'email',
        'dropdown_list',
        'divider',
        'short_answer',
        'date',
        'number',
        'multiple_response',
        'name',
        'text_only',
        'single_checkbox',
        'single_response',
        'long_answer',
        'table',
      ];
      if (validFields.includes(fieldType)) return true;
      return validFields.includes(fieldType);
    })
    .map((field) => {
      const {fieldType} = field;
      switch (fieldType) {
        case 'name':
          return formatName(field);
        case 'title':
          return formatTitle(field);
        case 'email':
          return formatEmail(field);
        case 'dropdown_list':
          return formatDropdown(field);
        case 'divider':
          return formatDivider(field);
        case 'short_answer':
          return formatSortAnswer(field);
        case 'date':
          return formatDate(field);
        case 'number':
          return formatNumber(field);
        case 'multiple_response':
          return formatMultipleResponse(field);
        case 'text_only':
          return formatTextOnly(field, validateState);
        case 'single_checkbox':
          return formatSingleCheckbox(field, validateState);
        case 'single_response':
          return formatDropdown(field);
        case 'long_answer':
          return formatLongAnswer(field, validateState);
        case 'table':
          return formatTable(field, validateState);
        // case "file_upload":
        //   return formatDropdown(field);
        default:
          break;
      }
    });
  return formatted;
};
function formatTitle(field) {
  return {
    // canHaveAlternateForm: true,
    // canHaveAnswer: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'TextInput',
    field_name: `text_input_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    required: field.isRequired,
    text: 'Text Input',
  };
}
function formatName(field) {
  return {
    // canHaveAlternateForm: true,
    // canHaveAnswer: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'TextInput',
    field_name: `text_input_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    required: field.isRequired,
    text: 'Text Input',
  };
}
function formatEmail(field) {
  return {
    // canHaveAlternateForm: true,
    // canHaveAnswer: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'TextInput',
    field_name: `text_input_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    required: field.isRequired,
    text: 'Text Input',
  };
}
function formatSortAnswer(field) {
  return {
    // canHaveAlternateForm: true,
    // canHaveAnswer: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'TextInput',
    field_name: `text_input_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    required: field.isRequired,
    text: 'Text Input',
  };
}
function formatDropdown(field) {
  const options = field.options
    ? field.options.map((option) => ({
        key: `dropdown_option_${option.formOptionId}`,
        text: option.label,
        value: option.label,
      }))
    : [];
  return {
    // canHaveAlternateForm: true,
    // canHaveAnswer: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'Dropdown',
    field_name: `dropdown_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    options: options,
    required: field.isRequired,
    text: 'Dropdown',
  };
}
function formatDivider(field) {
  return {
    // bold: false,
    // canHaveAlternateForm: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'LineBreak',
    id: field.formFieldId,
    // italic: false,
    // required: false,
    // static: true,
    text: 'Line Break',
  };
}
function formatDate(field) {
  return {
    // canHaveAlternateForm: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    dateFormat: 'MM/dd/yyyy',
    defaultToday: false,
    element: 'DatePicker',
    field_name: `date_picker_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    readOnly: false,
    required: field.isRequired,
    showTimeSelect: false,
    showTimeSelectOnly: false,
    text: 'Date',
    timeFormat: 'hh:mm aa',
  };
}
function formatNumber(field) {
  return {
    // canHaveAlternateForm: true,
    // canHaveAnswer: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'NumberInput',
    field_name: `number_input_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    required: field.isRequired,
    text: 'Number Input',
  };
}
function formatMultipleResponse(field) {
  const options = field.options
    ? field.options.map((option) => ({
        key: `dropdown_option_${option.formOptionId}`,
        text: option.label,
        value: option.label,
      }))
    : [];
  return {
    // canHaveAlternateForm: true,
    // canHaveAnswer: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    // dirty: false,
    element: 'Checkboxes',
    field_name: `checkboxes_${field.formFieldId}`,
    id: field.formFieldId,
    label: field.label,
    options,
    required: field.isRequired,
    text: 'Checkboxes',
  };
}
function formatTextOnly(field, validateState) {
  const errors =
    validateState && validateState[`text_only_${field.formFieldId}`]
      ? validateState[`text_only_${field.formFieldId}`].replaceAll(
          `text_only_${field.formFieldId}`,
          field.label,
        )
      : null;
  return {
    bold: false,
    // canHaveAlternateForm: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    custom: true,
    custom_options: [],
    element: 'CustomElement',
    field_name: `text_only_${field.formFieldId}`,
    id: field.formFieldId,
    italic: false,
    key: 'TextOnly',
    label: field.label,
    required: field.isRequired,
    static: true,
    text: 'Text Only',
    textBlock: field.textBlock ? JSON.parse(field.textBlock) : null,
    errors,
  };
}
function formatSingleCheckbox(field, validateState) {
  const errors =
    validateState && validateState[`single_checkbox_${field.formFieldId}`]
      ? validateState[`single_checkbox_${field.formFieldId}`].replaceAll(
          `single_checkbox_${field.formFieldId}`,
          field.label,
        )
      : null;
  return {
    bold: false,
    // canHaveAlternateForm: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'CustomElement',
    field_name: `single_checkbox_${field.formFieldId}`,
    id: field.formFieldId,
    key: 'SingleCheckbox',
    label: field.label,
    required: field.isRequired,
    text: 'Single checkbox',
    bare: true,
    errors,
  };
}
function formatLongAnswer(field, validateState) {
  const errors =
    validateState && validateState[`long_answer_${field.formFieldId}`]
      ? validateState[`long_answer_${field.formFieldId}`].replaceAll(
          `long_answer_${field.formFieldId}`,
          field.label,
        )
      : null;
  return {
    bold: false,
    // canHaveAlternateForm: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'CustomElement',
    field_name: `long_answer_${field.formFieldId}`,
    id: field.formFieldId,
    key: 'LongAnswer',
    label: field.label,
    required: field.isRequired,
    text: 'LongAnswer',
    bare: false,
    errors,
  };
}
function formatTable(field, validateState) {
  const errors =
    validateState && validateState[`table_${field.formFieldId}`]
      ? validateState[`table_${field.formFieldId}`].replaceAll(
          `table_${field.formFieldId}`,
          field.label,
        )
      : null;
  return {
    bold: false,
    // canHaveAlternateForm: true,
    // canHaveDisplayHorizontal: true,
    // canHaveOptionCorrect: true,
    // canHaveOptionValue: true,
    // canHavePageBreakBefore: true,
    // canPopulateFromApi: true,
    element: 'CustomElement',
    field_name: `table_${field.formFieldId}`,
    id: field.formFieldId,
    key: 'Table',
    label: field.label,
    required: field.isRequired,
    text: 'Table',
    bare: false,
    additionalInstructions: field.additionalInstructions
      ? JSON.parse(field.additionalInstructions)
      : null,
    errors,
  };
}
