import React from 'react';

import {useSelector} from 'react-redux';
import AppMessageView from '@common/core/AppMessageView';
import AppLoader from '@common/core/AppLoader';
import {useEffect} from 'react';

const AppInfoView = () => {
  const {error, loading, message} = useSelector(({common}) => common);

  const showMessage = () => {
    return <AppMessageView variant='success' message={message.toString()} />;
  };

  const showError = () => {
    return <AppMessageView variant='error' message={error.toString()} />;
  };

  return (
    <>
      {loading ? <AppLoader /> : null}

      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default AppInfoView;
