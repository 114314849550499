import React from 'react';
import PropTypes from 'prop-types';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import moment from 'moment';
import {useThemeContext} from '../AppContextProvider/ThemeContextProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {responsiveFontSizes} from '@mui/material/styles';
import ComponentsOverrides from './overrides';
import CustomShadows from './theme/shadows';
import {useMemo} from 'react';
import Typography from './theme/typography';
import {viVN} from '@mui/material/locale';
import locale from 'date-fns/locale/vi';
import GlobalStyles from '@mui/material/GlobalStyles';

const AppThemeProvider = (props) => {
  const {theme} = useThemeContext();
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);
  const themeTypography = useMemo(() => Typography(theme), [theme]);
  const themeOptions = useMemo(
    () => ({
      ...theme,
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [theme],
  );

  const themes = createTheme(themeOptions, viVN);

  themes.components = {
    ...theme.components,
    ...ComponentsOverrides(themes),
  };

  moment.updateLocale('en', {
    weekdaysShort: [['CN'], ['T2'], ['T3'], ['T4'], ['T5'], ['T6'], ['T7']],
    months:
      'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split(
        '_',
      ),
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM [năm] YYYY',
      LLL: 'D MMMM [năm] YYYY HH:mm',
      LLLL: 'dddd, D MMMM [năm] YYYY HH:mm',
      l: 'DD/M/YYYY',
      ll: 'D MMM YYYY',
      lll: 'D MMM YYYY HH:mm',
      llll: 'ddd, D MMM YYYY HH:mm',
    },
  });

  Array.prototype.charAt = function (index) {
    return this[index];
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={responsiveFontSizes(themes)}>
        <GlobalStyles
          styles={`
                        *::-webkit-scrollbar {
                            width: 10px;
                        }
                        *::-webkit-scrollbar-track {
                            background-color: transparent !important;
                        }
                        *::-webkit-scrollbar-thumb {
                            border: 2px solid transparent;
                            background-clip: padding-box;
                            border-radius: 9999px;
                            background-color: gray;
                        }
                  `}
        />
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          // adapterLocale={locale}
          localeText={{
            cancelButtonLabel: 'Hủy',
            okButtonLabel: 'OK',
          }}
        >
          {props.children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
