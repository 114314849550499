const {GET_REFLECTION_FIELD_LIST} = require('shared/constants/ActionTypes');

const initialState = {
  reflectionFieldList: [],
  paging: null,
  message: null,
};

const reflectionFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFLECTION_FIELD_LIST:
      return {
        ...state,
        reflectionFieldList: action.payload.data,
        paging: action.payload.paging,
      };
    default:
      return state;
  }
};

export default reflectionFieldReducer;
