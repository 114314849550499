const {
  GET_CONFIG_REFLECT_LIST,
  UPDATE_CONFIG_DETAIL,
} = require('shared/constants/ActionTypes');

const initialState = {
  configList: [],
  selectedConfig: null,
  paging: null,
  message: null,
};

const configReflectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_REFLECT_LIST:
      return {
        ...state,
        configList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_CONFIG_DETAIL:
      return {
        ...state,
        configList: action.payload.data,
        paging: action.payload.paging,
      };

    default:
      return state;
  }
};

export default configReflectReducer;
