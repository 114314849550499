import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

let SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export const abbreviateNumber = (number) => {
  let tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier == 0) return number;

  let suffix = SI_SYMBOL[tier];
  let scale = Math.pow(10, tier * 3);

  let scaled = number / scale;

  return scaled.toFixed(1) + suffix;
};
