import PropTypes from 'prop-types';
import {LazyLoadImage} from 'react-lazy-load-image-component';
// @mui
import Box from '@mui/material/Box';
import {createSvgIcon} from '@mui/material/utils';

const BrokenImageIcon = createSvgIcon(
  <path d='M21 5v6.59l-2.29-2.3c-.39-.39-1.03-.39-1.42 0L14 12.59 10.71 9.3a.9959.9959 0 0 0-1.41 0L6 12.59 3 9.58V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2zm-3 6.42 3 3.01V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-6.58l2.29 2.29c.39.39 1.02.39 1.41 0l3.3-3.3 3.29 3.29c.39.39 1.02.39 1.41 0l3.3-3.28z' />,
  'BrokenImageIcon',
);

// ----------------------------------------------------------------------

Image.propTypes = {
  disabledEffect: PropTypes.bool,
  effect: PropTypes.string,
  ratio: PropTypes.oneOf([
    '4/3',
    '3/4',
    '6/4',
    '4/6',
    '16/9',
    '9/16',
    '21/9',
    '9/21',
    '1/1',
    '32/9',
    '5/4',
  ]),
  sx: PropTypes.object,
  imgError: PropTypes.string,
};

export default function Image({
  ratio,
  disabledEffect = false,
  effect = 'blur',
  sx,
  imgError = '/assets/images/no-image.png',
  ...other
}) {
  const onError = (e) => {
    e.preventDefault();
    e.target.src = imgError;
  };
  if (ratio) {
    return (
      <Box
        component='span'
        sx={{
          width: 1,
          lineHeight: 0,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          pt: getRatio(ratio),
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            lineHeight: 0,
            position: 'absolute',
            backgroundSize: 'cover !important',
          },
          ...sx,
        }}
      >
        <Box
          component={LazyLoadImage}
          wrapperClassName='wrapper'
          effect={disabledEffect ? undefined : effect}
          placeholderSrc='/assets/placeholder.svg'
          sx={{width: 1, height: 1, objectFit: 'cover'}}
          onError={onError}
          {...other}
        />
      </Box>
    );
  }

  return (
    <Box
      component='span'
      sx={{
        lineHeight: 1,
        display: 'block',
        overflow: 'hidden',
        '& .wrapper': {width: 1, height: 1, backgroundSize: 'cover !important'},
        ...sx,
      }}
    >
      <Box
        component={LazyLoadImage}
        wrapperClassName='wrapper'
        effect={disabledEffect ? undefined : effect}
        placeholderSrc='/assets/placeholder.svg'
        sx={{width: 1, height: 1, objectFit: 'cover'}}
        onError={onError}
        {...other}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

function getRatio(ratio = '1/1') {
  return {
    '4/3': 'calc(100% / 4 * 3)',
    '3/4': 'calc(100% / 3 * 4)',
    '6/4': 'calc(100% / 6 * 4)',
    '4/6': 'calc(100% / 4 * 6)',
    '16/9': 'calc(100% / 16 * 9)',
    '9/16': 'calc(100% / 9 * 16)',
    '21/9': 'calc(100% / 21 * 9)',
    '9/21': 'calc(100% / 9 * 21)',
    '1/1': '100%',
    '32/9': 'calc(100% / 32 * 9)',
    '5/4': 'calc(100% / 5 * 4)',
  }[ratio];
}
