import React from 'react';
import {AppSuspense} from '../../index';
import AppFooter from '../AppLayout/components/AppFooter';
import AppErrorBoundary from '../AppErrorBoundary';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import AppContentViewWrapper from './AppContentViewWrapper';
import {useLayoutContext} from '@common/utility/AppContextProvider/LayoutContextProvider';
import {NavStyle} from 'shared/constants/AppEnums';
import Grid from '@mui/material/Grid';
import AppRightBar from '../AppLayout/HorDefault/AppRightBar';
import {useSelector} from 'react-redux';
import AppBannerDown from '../AppLayout/HorDefault/AppBanner/AppBannerDown';
import {useRouter} from 'next/router';
import AppPoster from '../AppLayout/HorDefault/AppBanner/AppPoster';
import {ElementSection} from 'shared/constants/AppEnums';

// const TransitionWrapper = ({children}) => {
//   const {rtAnim} = useContext(AppContext);
//   const {pathname} = useRouter();
//   if (rtAnim === RouteTransition.NONE) {
//     return <>{children}</>;
//   }
//   return (
//     <TransitionGroup appear enter exit>
//       <CSSTransition
//         key={pathname}
//         timeout={{enter: 300, exit: 300}}
//         classNames={rtAnim}
//       >
//         {children}
//       </CSSTransition>
//     </TransitionGroup>
//   );
// };
//
// TransitionWrapper.propTypes = {
//   children: PropTypes.node.isRequired,
// };
const AppContentView = ({children, sxStyle}) => {
  const {navStyle} = useLayoutContext();
  const {pathname} = useRouter();
  const isShowSidebarRight = useSelector(
    ({common}) => common.isShowSidebarRight,
  );
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  return (
    <AppContentViewWrapper className='app-content-view'>
      {/* {navStyle === NavStyle.H_DEFAULT ? null : (
        <AppBreadcrumbs
          rootLabel={messages['breadcrumb.home']}
          transformLabel={(title) =>
            title.charAt(0).toUpperCase() + title.slice(1)
          }
        />
      )} */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          '*:not(img, :has(img))': {
            filter:
              Array.isArray(layoutClient) &&
              layoutClient.length > 0 &&
              layoutClient.filter(
                (x) =>
                  x.sectionName === ElementSection.GRAYBACKGROUND &&
                  x.isDisplay,
              ).length > 0
                ? 'grayscale(100%)'
                : 'none',
          },
          p: {xs: 0.5, md: 1, xl: navStyle === NavStyle.H_DEFAULT ? 1 : 4},
          ...sxStyle,
        }}
        className='app-content'
      >
        <AppSuspense>
          <AppErrorBoundary>
            {navStyle === NavStyle.H_DEFAULT ? (
              <Box className='main-container'>
                <Grid
                  container
                  className='grid-container'
                  sx={{
                    maxWidth: {lg: 1140},
                    mx: 'auto',
                  }}
                >
                  <Grid
                    xs={12}
                    md={isShowSidebarRight ? 9 : 12}
                    px={{lg: 2, xs: 0, md: 1}}
                  >
                    {pathname === '/' && <AppPoster index={0} />}
                    {children}
                    {pathname === '/' && <AppBannerDown />}
                  </Grid>
                  {isShowSidebarRight && <AppRightBar />}
                </Grid>
              </Box>
            ) : (
              children
            )}
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  );
};

export default AppContentView;

AppContentView.propTypes = {
  sxStyle: PropTypes.object,
  children: PropTypes.node,
};
