const {
  GET_ARTICLE_LIST,
  DELETE_ARTICLE,
  GET_ARTICLE_DETAIL,
  CREATE_NEW_ARTICLE,
  UPDATE_CONFIG_ARTICLE,
  ARTICLE_GET_LIST_PARENT,
  ARTICLE_GET_CATEGORY,
  ARTICLE_GET_TREE_CATEGORY,
  ARTICLE_PUBLISHER,
  GET_DETAILS_ARTICLE_CLIENT,
  GET_DETAILS_ARTICLE_CLIENT_SUCCESS,
  GET_DETAILS_ARTICLE_CLIENT_FAIL,
  GET_LIST_ARTICLE_BY_CAT_CLIENT,
  GET_LIST_ARTICLE_BY_CAT_CLIENT_SUCCESS,
  GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS,
  GET_LATEST_ARTICLE_CLIENT,
  GET_LATEST_ARTICLE_CLIENT_SUCCESS,
  GET_LATEST_ARTICLE_CLIENT_FAIL,
  GET_MOST_VIEW_ARTICLE_CLIENT,
  GET_MOST_VIEW_ARTICLE_CLIENT_SUCCESS,
  GET_MOST_VIEW_ARTICLE_CLIENT_FAIL,
  GET_ARTICLES_CLIENT,
  GET_ARTICLES_CLIENT_SUCCESS,
  GET_ARTICLES_CLIENT_FAIL,
  GET_FEATURED_ARTICLE_CLIENT,
  GET_FEATURED_ARTICLE_CLIENT_SUCCESS,
  GET_FEATURED_ARTICLE_CLIENT_FAIL,
  DELETE_DATA_ARTICLE,
  GET_VIDEO_ARTICLE_CLIENT,
  GET_VIDEO_ARTICLE_CLIENT_SUCCESS,
  GET_VIDEO_ARTICLE_CLIENT_FAIL,
  GET_COUNT_APPROVAL,
  GETALL_VIDEO_ARTICLE_CLIENT,
  GETALL_VIDEO_ARTICLE_CLIENT_SUCCESS,
  GETALL_VIDEO_ARTICLE_CLIENT_FAIL,
  GET_DETAILS_ARTICLE_VIDEO_CLIENT,
  GET_DETAILS_ARTICLE_VIDEO_CLIENT_SUCCESS,
  GET_DETAILS_ARTICLE_VIDEO_CLIENT_FAIL,
  GET_DETAIL_APPROVAL,
} = require('shared/constants/ActionTypes');

const initialState = {
  articleList: [],
  selectedArticle: null,
  paging: null,
  message: null,
  listCategory: [],
  listArticleParent: [],
  listArticlePublisher: [],
  listTreeCategory: [],

  detailsArticle: null,
  isLoadingDetails: false,

  listArticleByCat: null,
  isLoadingArticleByCat: false,
  pagingArtsByCat: null,

  latestArticles: null,
  isLoadingLatest: false,

  mostViewArticles: null,
  isLoadingMostView: false,

  artsHomePage: null,
  isLoadingArtsHomePage: false,

  featuredArticles: null,
  isLoadingFeaturedArticles: false,

  listArtVideo: null,

  listArtVideoAll: null,
  isLoadingArtVideo: false,
  pagingVideo: null,
  isLoadingArtVideoAll: false,

  countArticleApproval: 0,
  articleApprovals: [],
  detailsArtApproval: {},

  detailsArticleVideo: null,
  isLoadingDetailsVideo: false,
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE_LIST:
      return {
        ...state,
        articleList: action.payload.data,
        paging: action.payload.paging,
        selectedArticle: null,
      };
    case CREATE_NEW_ARTICLE:
      return {
        ...state,
        articleList: action.payload.data,
        paging: action.payload.paging,
      };
    case UPDATE_CONFIG_ARTICLE:
      return {
        ...state,
        articleList: action.payload.data,
        paging: action.payload.paging,
      };
    case DELETE_ARTICLE: {
      return {
        ...state,
        articleList: action.payload.data,
        paging: action.payload.paging,
      };
    }
    case GET_ARTICLE_DETAIL:
      return {
        ...state,
        selectedArticle: action.payload.data,
      };
    case DELETE_DATA_ARTICLE:
      return {
        ...state,
        selectedArticle: null,
      };
    case ARTICLE_GET_LIST_PARENT:
      return {
        ...state,
        listArticleParent: action.payload.data,
      };
    case ARTICLE_PUBLISHER:
      return {
        ...state,
        listArticlePublisher: action.payload.data,
      };
    case ARTICLE_GET_CATEGORY:
      return {
        ...state,
        listCategory: action.payload.data,
      };
    case ARTICLE_GET_TREE_CATEGORY:
      return {
        ...state,
        listTreeCategory: action.payload.data,
      };
    case GET_DETAILS_ARTICLE_CLIENT:
      return {
        ...state,
        detailsArticle: null,
        isLoadingDetails: true,
      };
    case GET_DETAILS_ARTICLE_CLIENT_SUCCESS:
      return {
        ...state,
        detailsArticle: action.payload.data,
        isLoadingDetails: false,
      };
    case GET_DETAILS_ARTICLE_CLIENT_FAIL:
      return {
        ...state,
        detailsArticle: null,
        isLoadingDetails: false,
      };

    case GET_LIST_ARTICLE_BY_CAT_CLIENT:
      return {
        ...state,
        // listArticleByCat: null,
        // isLoadingArticleByCat: true,
      };
    case GET_LIST_ARTICLE_BY_CAT_CLIENT_SUCCESS:
      return {
        ...state,
        listArticleByCat: action.payload.data,
        isLoadingArticleByCat: false,
      };
    case GET_LIST_ARTICLE_BY_CAT_CLIENT_FAILS:
      return {
        ...state,
        // listArticleByCat: null,
        // isLoadingArticleByCat: false,
      };
    case GET_LATEST_ARTICLE_CLIENT:
      return {
        ...state,
        // isLoadingLatest: true,
        // latestArticles: [],
      };
    case GET_LATEST_ARTICLE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingLatest: false,
        latestArticles: action.payload.data,
      };
    case GET_LATEST_ARTICLE_CLIENT_FAIL:
      return {
        ...state,
        isLoadingLatest: false,
        // latestArticles: [],
      };

    case GET_MOST_VIEW_ARTICLE_CLIENT:
      return {
        ...state,
        // isLoadingMostView: true,
        // mostViewArticles: [],
      };
    case GET_MOST_VIEW_ARTICLE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingMostView: false,
        mostViewArticles: action.payload.data,
      };
    case GET_MOST_VIEW_ARTICLE_CLIENT_FAIL:
      return {
        ...state,
        isLoadingMostView: false,
        mostViewArticles: [],
      };

    case GET_ARTICLES_CLIENT:
      return {
        ...state,
        // artsHomePage: null,
        // isLoadingArtsHomePage: true,
      };
    case GET_ARTICLES_CLIENT_SUCCESS:
      return {
        ...state,
        artsHomePage: action.payload.data,
        isLoadingArtsHomePage: false,
      };
    case GET_ARTICLES_CLIENT_FAIL:
      return {
        ...state,
        // artsHomePage: null,
        isLoadingArtsHomePage: false,
      };
    case GET_FEATURED_ARTICLE_CLIENT:
      return {
        ...state,
        // featuredArticles: null,
        // isLoadingFeaturedArticles: true,
      };

    case GET_FEATURED_ARTICLE_CLIENT_SUCCESS:
      return {
        ...state,
        featuredArticles: action.payload.data,
        isLoadingFeaturedArticles: false,
      };
    case GET_FEATURED_ARTICLE_CLIENT_FAIL:
      return {
        ...state,
        // featuredArticles: null,
        // isLoadingFeaturedArticles: false,
      };
    case GET_VIDEO_ARTICLE_CLIENT:
      return {
        ...state,
        // listArtVideo: null,
        // isLoadingArtVideo: true,
      };

    case GET_VIDEO_ARTICLE_CLIENT_SUCCESS:
      return {
        ...state,
        listArtVideo: action.payload.data,
        isLoadingArtVideo: false,
      };
    case GET_VIDEO_ARTICLE_CLIENT_FAIL:
      return {
        ...state,
        // listArtVideo: null,
        // isLoadingArtVideo: false,
      };
    case GET_COUNT_APPROVAL:
      return {
        ...state,
        countArticleApproval: action.payload.data.soLuong,
        articleApprovals: action.payload.data.tinBais,
      };
    case GETALL_VIDEO_ARTICLE_CLIENT:
      return {
        ...state,
        isLoadingArtVideoAll: true,
      };
    case GETALL_VIDEO_ARTICLE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingArtVideoAll: false,
        listArtVideoAll: action.payload.data.tinBaiVideos,
        pagingVideo: action.payload.data.paging,
      };

    case GETALL_VIDEO_ARTICLE_CLIENT_FAIL:
      return {
        ...state,
        isLoadingArtVideoAll: false,
      };
    case GET_DETAILS_ARTICLE_VIDEO_CLIENT:
      return {
        ...state,
        detailsArticleVideo: null,
        isLoadingDetailsVideo: true,
      };
    case GET_DETAILS_ARTICLE_VIDEO_CLIENT_SUCCESS:
      return {
        ...state,
        detailsArticleVideo: action.payload.data,
        isLoadingDetailsVideo: false,
      };
    case GET_DETAILS_ARTICLE_VIDEO_CLIENT_FAIL:
      return {
        ...state,
        detailsArticleVideo: null,
        isLoadingDetailsVideo: false,
      };
    case GET_DETAIL_APPROVAL:
      return {
        ...state,
        detailsArtApproval: action.payload.data,
      };
    default:
      return state;
  }
};

export default articleReducer;
